
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';


const Removed = ({ title, close, data, success }) => {
    console.log(data)
    return (
        <>
            <DialogTitle>
                {title}
                <IconButton
                    aria-label="close"
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#989ba1',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>

                <Alert severity="warning">


                    <Typography variant="body2" gutterBottom>
                        Estas seguro de eliminar:
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {data ?
                            data.name
                                ? 'Nombre: ' + data.name
                                : 'folio: ' + data.id
                            : null
                        }
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                        Una vez eliminado no se podrá recuperar
                    </Typography>

                </Alert>






            </DialogContent>
            <DialogActions>
                <Button
                    onClick={close}
                >
                    NO
                </Button>
                <Button
                    onClick={() => success(data.id)}
                    variant="contained"
                >
                    SI
                </Button>
            </DialogActions>
        </>
    )
}

export default Removed