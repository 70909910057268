import { useState } from "react"
import { helpHttp } from "../Helpers/helpHttp"
import { useAuth } from '../Hooks/useAuth'

export const useForm = (initialForm, validateForm = null) => {
	const { token } = useAuth()
	const [form, setForm] = useState(initialForm)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [response, setResponse] = useState({})
	const endPoint = ` ${process.env.REACT_APP_URI_API}users`
	const handleChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value })
	}
	const handleBlur = (e) => {
		if (validateForm !== null) {
			handleChange(e)
			setErrors(validateForm(form))
		}
	}

	const handleSubmit = (e, id = 0, type = null) => {
		if (type === 'ingreso') {
			const endPointUpdate = ` ${process.env.REACT_APP_URI_API}historico?idMedida=${form.idMedida}&cantidad=${form.cantidad}&costounitario=${form.costounitario}&idProveedor=${form.idProveedor}&idproducto=${form.idproducto
				}`
			e.preventDefault()
			setLoading(true)
			helpHttp().post(endPointUpdate, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: 'Bearer ' + token
				}
			}).then((res) => {
				setLoading(false)
				setResponse(res)
			})
		}

		if (type === 'decremento') {
			const endPointUpdate = `${process.env.REACT_APP_URI_API}historico?idMedida=${form.idMedida}&cantidad=${form.cantidad*-1}&costounitario=${form.costounitario}&idProveedor=${form.idProveedor}&idproducto=${form.idproducto}`;
			e.preventDefault();
			setLoading(true)
			helpHttp().post(endPointUpdate, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: 'Bearer ' + token
				}
			}).then((res) => {
				setLoading(false)
				setResponse(res)
			})
		}

		if (type === 'edit') {
			const endPointUpdate = ` ${process.env.REACT_APP_URI_API}users/${id}`

			e.preventDefault()
			setLoading(true)
			helpHttp().put(endPointUpdate, {
				body: form,
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: 'Bearer ' + token
				}
			}).then((res) => {
				setLoading(false)
				setResponse(res)
			})
		}

		if (type === 'delete') {
			const endPointDelete = ` ${process.env.REACT_APP_URI_API}users/${id}`

			e.preventDefault()
			setLoading(true)
			helpHttp().del(endPointDelete, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: 'Bearer ' + token
				}
			}).then((res) => {
				setLoading(false)
				setResponse(res)
			})
		}

		if (type === 'add') {
			e.preventDefault()
			setErrors(validateForm(form))
			setLoading(true)
			form.idRole = parseInt(form.idRole)
			helpHttp().post(endPoint, {
				body: form,
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: 'Bearer ' + token
				}
			}).then((res) => {
				setLoading(false)
				setResponse(res)
			})
		}

		if( type === 'DownLayer' ){
			e.preventDefault();
			setLoading( true );
			fetch( `${process.env.REACT_APP_URI_API }template`, {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'text/csv',
				'Authorization': 'Bearer ' + token
			}
			} )
			.then( ( response ) => response.blob() )
			.then( ( blob ) => {
				const fileurl = window.URL.createObjectURL( new Blob( [ blob ] ) );
				const link = document.createElement( 'a' );
				link.href = fileurl;
				link.setAttribute('download', 'productos.csv' );
				link.click();
			} )
			.catch((err) => err);
			setLoading( false );
		}
	}

	return {
		form,
		errors,
		loading,
		response,
		handleChange,
		handleBlur,
		handleSubmit

	}



}