
import { useState } from 'react'

import { Navigate } from 'react-router-dom';


//MUI
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';


//Hooks
import { useForm } from './../../../Hooks/useForm'


//Component
import Loading from '../../../Components/Loading'


const AddUser = ({ close, success, dataform }) => {

	const initialForm = {
		'name': '',
		'email': '',
		'password': '',
		'idRole': '',
		'idArea': '',
	}

	const validationsForm = (form) => {
		const errors = {}
		const regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
		const regexSizeText = /^.{8,10}$/;

		console.log( '********************' );
		console.log( form );
		console.log( '********************' );


		if (!form.name.trim()) {
			errors.name = 'El campo es requerido'
		}

		if (!form.email.trim()) {
			errors.email = 'El campo es requerido'
		} else if (!regexEmail.test(form.email.trim())) {
			errors.email = 'Ingresa un formato válido ejemplo: nombre@dominio.com'
		}

		if (!form.password.trim()) {
			errors.password = 'El campo es requerido'
		} else if (!regexSizeText.test(form.password.trim())) {
			errors.password = 'Debe contener de 8 a 10 caracteres'
		}

		if (!form.idRole) {
			errors.idRole = 'El campo es requerido'
		}

		// if (!form.idArea) {
		// 	errors.idArea = 'El campo es requerido'
		// }

		return errors
	}

	const {
		form,
		errors,
		loading,
		response,
		handleChange,
		handleBlur,
		handleSubmit } = useForm(initialForm, validationsForm)

		const [responseState, setResponseState] = useState(response)

		const isEmptyObject = (obj) => {
			for (var property in obj) {
				if (obj.hasOwnProperty(property)) {
					return false;
				}
			}

			return true;
		}

		const closeSuccess = () => {
			setTimeout(() => {
				setResponseState('')
				success()
			}, 3000);
		}

		const msg = () => {
			if (responseState) {
				if (response.status === 422) {
					return <Alert severity="error">
					{response.body.errors.email}
					</Alert>
				} else if (response.status === 201 || response.status === 200) {
					closeSuccess()
					return <Alert severity="success">
					El usuario se creo corectamente {form.email}
					</Alert>
				} else if (response.err) {
					return <Alert severity="error">
					Algo ocurrió, intentalo mas tarde --
					{response.status} {response.statusText} {response.status === 401 ? <Navigate to="/login" replace={true} /> : null}
					</Alert>
				}
			}

		}



		return (
		<>



		{
			loading
			? <Loading />
			: <>
			<DialogContent dividers>

			{

				msg()
			}

			<Box
			component="form"
			sx={{
				'& .MuiTextField-root': { m: 0 },
			}}
			>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>

				<FormControl fullWidth sx={{ mt: 2 }}>
					<TextField

					error={errors.name ? true : false}
					value={form.name}
					name='name'
					type="text"
					label="Nombre de usuario"
					onBlur={handleBlur}
					onChange={handleChange}
					helperText={errors.name ? errors.name : 'Ingresa un usuario'}

					/>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 2 }}>
					<TextField
					error={errors.email ? true : false}
					value={form.email}
					name='email'
					type="email"
					label="Email"
					onBlur={handleBlur}
					onChange={(e) => handleChange(e)}
					helperText={errors.email ? errors.email : 'Ejemplo: nombre@dominio.com'}

					/>
				</FormControl>

				<FormControl fullWidth sx={{ mt: 2 }}>
					<TextField
						error={errors.idArea ? true : false}
						id="outlined-select-currency"
						select
						label="Área"
						name='idArea'
						value={form.idArea}
						onBlur={handleBlur}
						onChange={handleChange}
						helperText={errors.idArea ? errors.idArea : 'Selecciona un Área'}
					>
						{
							dataform.areas.map( row => (
								<MenuItem value={row.id} >
									{row.des}
								</MenuItem>
							) )
						}
					</TextField>
				</FormControl>

				</Grid>
				<Grid item xs={12} md={6}>

				<FormControl fullWidth sx={{ mt: 2 }}>
					<TextField
					error={errors.password ? true : false}
					value={form.password}
					name='password'
					type="password"
					label="Contraseña"
					onBlur={handleBlur}
					onChange={(e) => handleChange(e)}
					helperText={errors.password ? errors.password : 'Debe contener entre 8 y 10 caracteres'}

					/>
				</FormControl>

				<FormControl fullWidth sx={{ mt: 2 }}>
					<TextField
						error={errors.idRole ? true : false}
						id="outlined-select-currency"
						select
						label="Rol"
						name='idRole'
						value={form.idRole}
						onBlur={handleBlur}
						onChange={handleChange}
						helperText={errors.idRole ? errors.idRole : 'Selecciona un Rol'}
					>
						{
							dataform.roles.map( row => (
								<MenuItem value={row.id} >
									{row.des}
								</MenuItem>
							) )
						}
					</TextField>
				</FormControl>


				</Grid>


			</Grid>
			</Box>
			</DialogContent>
			<DialogActions>

			<Button

			disabled={isEmptyObject(errors) ? response.status === 201 || response.status === 200 ? true : false : true}
																// onClick={handleSubmit}
			onClick={(e) => handleSubmit(e, 0, 'add')}

			>
			GUARDAR USUARIO
			</Button>
			</DialogActions>
			</>


		}



		</>
		)
	}

	export default AddUser