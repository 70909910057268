
import { useState, useEffect } from 'react'


//MUI
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { DataGrid, esES, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

//Helpert
import { helpHttp } from '../Helpers/helpHttp'

//Hooks
import { useAuth } from '../Hooks/useAuth'

//Component
import Loading from '../Components/Loading'


const DetailHistorico = ({ id, deleteToken }) => {

    const endPoint = process.env.REACT_APP_URI_API
    const { token } = useAuth()

    const [detail, setDetail] = useState()
    const [msg, setMsg] = useState()
    const [isLoad, setIsLoad] = useState(false)
    const [pageSize, setPageSize] = useState(10);

    const [sortModel, setSortModel] = useState([
        { field: "fecha", sort: "desc" }
    ]);


    useEffect(() => {
        // set a clean up flag
        let isSubscribed = true;
        if (isSubscribed) {
            const getDetail = () => {
                setIsLoad(true)
                helpHttp().get(`${endPoint}historico?idpro[]=${id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: 'Bearer ' + token
                    }
                }).then((res) => {

                    console.log(res)

                    if (res.body) {
                        if (res.body.status === 'Token is Invalid' || res.body.status === 'Token is Expired') {
                            deleteToken()
                            console.log('expiro el token')
                        } else if (Array.isArray(res.body.historico)) {
                            console.log('es un arreglo')
                            setDetail(res.body.historico)

                            setIsLoad(false)
                        } else {
                            console.log('es un mensaje')
                            setIsLoad(false)
                            setMsg(res.body.msg)
                        }
                    } else {
                        console.log('algo paso intenta mas tarde')
                        setIsLoad(false)
                        setMsg('Algo paso, intenta mas tarde')
                    }

                })
            }

            getDetail()
        }
        // cancel subscription to useEffect
        return () => (isSubscribed = false)

    }, [endPoint, token, id, deleteToken])


    const columns = [
        // { field: 'id', headerName: 'Sku', width: 40 },

        {
            field: 'fecha',
            headerName: 'Fecha',
            width: 180,
            editable: true,
        },
        {
            field: 'producto',
            headerName: 'Producto',
            flex: 1,
            editable: true,

        },
        {
            field: 'proveedor',
            headerName: 'Proveedor',
            width: 230,
            editable: true,
        },
        {
            field: 'cantidad',
            headerName: 'Cnt ingresada',
            width: 110,
            editable: true,
        },
        {
            field: 'costounitario',
            headerName: 'C/U',
            width: 80,
            editable: true,
            renderCell: (params) => {
                return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(params.value);
            },
            type: "number"

        },
        {
            field: 'medida',
            headerName: 'Medida',
            width: 110,
            editable: true,

        },
        {
            field: 'estado',
            headerName: 'Tipo',
            width: 110,
            editable: true,
            renderCell: (params) => {
            	console.log( params.value );
                return params.value == 1 ? 'Entrada' : 'Salida';
            },
        },



    ];

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // const sortModel = [{ field: "fecha", sort: "desc" }];

    return (
        <Grid
            item
            xs={12}
            sx={{ marginLeft: '0px' }}
        >

            <Typography className='titleColor' variant="h4" gutterBottom component="div">
                Detalle Histórico
            </Typography>
            <Typography className='textColor' variant="subtitle1" gutterBottom component="div">
                Visualiza los detalles que se han realizado

            </Typography>

            {

                isLoad
                    ? <Loading />
                    : msg ?

                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            <strong> {msg ? msg : null} — </strong>
                        </Alert>
                        : detail
                            ?
                            <Paper elevation={1}>
                                <div className='wraperTable' >
                                    <DataGrid
                                        autoHeight={true}
                                        disableColumnMenu
                                        rows={detail}
                                        columns={columns}
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        sortModel={sortModel}
                                        onSortModelChange={(model) => setSortModel(model)}
                                        hideFooterSelectedRowCount
                                        disableSelectionOnClick
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        components={{
                                            Toolbar: CustomToolbar,
                                        }}
                                    />
                                </div>
                            </Paper>
                            : 'null'

            }





        </Grid>
    )
}


export default DetailHistorico