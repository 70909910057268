import { useState } from 'react';
import { Navigate } from 'react-router-dom';


//@mui material
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { indigo } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';


import { useAuth } from '../../Hooks/useAuth';


// import image from '../../images/image2.svg'
import image from '../../images/logo.jpeg'

import './styles.css'


const d = new Date();
let year = d.getFullYear();

// const ColorButton = styled(Button)(({ theme }) => ({
//     color: theme.palette.getContrastText(indigo[500]),
//     backgroundColor: indigo[500],
//     '&:hover': {
//         backgroundColor: indigo[700],
//     },
// }));
// console.log( process.env.REACT_APP_URI_API );

const CssTextField = styled(TextField)({
		'& label.Mui-focused': {
				color: indigo[500],
		},
		'& .MuiInput-underline:after': {
				borderBottomColor: indigo[500],
		},
		'& .MuiOutlinedInput-root': {
				color: indigo[700],

				'&:hover fieldset': {
						borderColor: indigo[500],
				},
				'&.Mui-focused fieldset': {
						borderColor: indigo[500],
				},
		},
});


function Login() {

		const [values, setValues] = useState({
				user: '',
				password: '',
				weight: '',
				weightRange: '',
				showPassword: false,
		});
		const { token, setToken, userRol } = useAuth()
		const [isLoading, setIsLoading] = useState(false)
		const [error, setError] = useState(false)


		const handleChange = (prop) => (event) => {
				setValues({ ...values, [prop]: event.target.value });
		};

		const handleClickShowPassword = () => {
				setValues({
						...values,
						showPassword: !values.showPassword,
				});
		};

		const handleMouseDownPassword = (event) => {
				event.preventDefault();
		};

		const handleLogin = () => {


				if (values.user === '' || values.password === '') {
						console.log('datos vacio')
				} else {
						const dataLogin = {
								email: values.user,
								password: values.password
						}

						setIsLoading(true)

						const uriApi = process.env.REACT_APP_URI_API

						fetch(`${uriApi}login`, {
								method: 'post',
								headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
								body: JSON.stringify(dataLogin)
						})
								.then(response => {
										if (!response.ok) throw Error(response.status);

										return response.json();
								})
								.then(response => {
										setToken(response.access_token, response.name, response.role)
										setIsLoading(false)

								})
								.catch(error => {


										setError('Intenta nuevamente')
										setIsLoading(false)


								});
				}
		}

		return (


				!token ?
						<Grid container spacing={0}>
								<Grid item xs={12} md={6}>
										<Box
												component="form"
												sx={{
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
														justifyContent: 'center',
														height: '100vh',
												}}
												noValidate
												autoComplete="off"
										>

												<Box
														sx={{ p: 1, width: '75%', maxWidth: '600px', height: 'auto', }}
												>
														<Box
																sx={{ mb: 2 }}
														>
																<Typography className='app-textLogin' variant="h4" gutterBottom component="div">
																		Sistema de Administración Totall Publicidad.
																</Typography>
														</Box>

														<Box
																sx={{ mb: 6 }}
														>
																<Typography className='app-subTextLogin' variant="subtitle1" gutterBottom component="div">
																		Ingresa al sistema con tus datos de acceso.
																</Typography>
														</Box>

														<div>
																<CssTextField
																		label="Usuario"
																		id="user"
																		sx={{ mb: 2, width: '100%' }}
																		value={values.user}
																		onChange={handleChange('user')}
																/>

														</div>

														<div>
																<CssTextField
																		label="Password"
																		id="pass"
																		sx={{ mb: 2, width: '100%' }}
																		value={values.password}
																		type={values.showPassword ? 'text' : 'password'}
																		onChange={handleChange('password')}
																		InputProps={{
																				endAdornment:
																						<InputAdornment position="end">
																								<IconButton
																										aria-label="toggle password visibility"
																										onClick={handleClickShowPassword}
																										onMouseDown={handleMouseDownPassword}
																										edge="end"
																								>
																										{values.showPassword ? <VisibilityOff /> : <Visibility />}
																								</IconButton>
																						</InputAdornment>
																		}}

																/>
														</div>
														<Box
																sx={{ mt: 2, }}
																className='wrap-btn-app'
														>
																{
																		error
																				?
																				<Alert sx={{ mb: 2, }} severity="error">{error}</Alert>
																				: null

																}
																<LoadingButton
																		variant="contained"
																		fullWidth={true}
																		onClick={handleLogin}
																		disabled={values.user === '' ? true : values.password === '' ? true : false}
																		loading={isLoading}
																		className='button-app'
																>
																		Ingresar
																</LoadingButton>

														</Box>
														<Box
																sx={{ mt: 6 }}
														>
																<Typography className='app-subTextLogin' variant="caption" gutterBottom component="div">
																		TotallPublicidad Copyright &copy; {year} Developed by VerticalStudio.
																</Typography>
														</Box>
												</Box>
										</Box>
								</Grid>
								<Grid item xs={12} md={6}>

										<div className="section-loginBack"
												sx={{ display: { xs: 'none', md: 'block' } }}>
												<img alt="totallpublicidad" src={image} />
										</div>
								</Grid>

						</Grid>
						: userRol() === '2' ? <Navigate to='/venta-semanal' /> : <Navigate to='/dashboard' />
						// userRol() === '2' ? <Navigate to='/venta-proyecto' /> : <Navigate to='/dashboard' />


		);
}

export default Login;
