import { useState } from 'react';


//MUI
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DataGrid, esES, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonIcon from '@mui/icons-material/Person';


//Icon
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';


//Hooks
import { useGetUser } from './../Hooks/useGetUser'

//Component
import Loading from './../Components/Loading'
import Modal from './../Components/Modal'

const ListUser = ({ token, deleteToken }) => {

    const uriApi = process.env.REACT_APP_URI_API
    const [upload, setUpload] = useState(0)
    const [pageSize, setPageSize] = useState(10);
    const [openModal, setOpenModal] = useState(false)
    const { data, error, loading } = useGetUser(uriApi, token, upload)
    const [dataModal, setDataModal] = useState(null)
    const [actionModal, setActionModal] = useState(null)


    const dataSuccess = (v) => {
        setUpload(upload + 1)
        setOpenModal(false)
    }

    const handleOpenModal = (v, a) => {


        const getAccion = (a) => {
            return action[a]()
        }

        const newUser = () => {
            setActionModal('addUser')
            setOpenModal(true)
        }

        const deleteUser = () => {

            setDataModal(v)
            setActionModal('delete')
            setOpenModal(!openModal)

        }

        const editUser = () => {

            setDataModal(v)
            setActionModal('edit')
            setOpenModal(!openModal)

        }

        const action = {
            'delete': deleteUser,
            'addUser': newUser,
            'edit': editUser
        }


        getAccion(a)


    }

    const closeModal = () => {

        setOpenModal(false)
        setActionModal(null)
    }

    const logout = () => {

        setTimeout(() => {
            deleteToken()
        }, 500);
    }


    const getChipProps = (params) => {
        if (params.value === "Encargado") {
            return {
                icon: <PersonIcon style={{ fill: 'rgb(76, 175, 80)' }} />,
                label: "Operativo",
                style: {
                    borderColor: 'rgb(76, 175, 80)'
                }
            };
        } else if (params.value === "Admin") {
            return {
                icon: <PersonIcon style={{ fill: 'rgb(76, 175, 80)' }} />,
                label: 'Administrador',
                style: {
                    borderColor: 'rgb(76, 175, 80)'
                }
            };
        }


    }

    const columns = [
        // { field: 'id', headerName: 'Id', width: 90 },

        {
            field: 'name',
            headerName: 'Nombre',
            flex: 1,
            align: 'left',
            headerAlign: 'center',
            headerClassName: 'title-app-theme-header',
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            align: 'left',
            headerAlign: 'center',
            headerClassName: 'title-app-theme-header',

        },
        {
            field: 'role',
            headerName: 'Rol',
            width: 130,
            align: 'left',
            headerAlign: 'center',
            headerClassName: 'title-app-theme-header',
            renderCell: (params) => {
                return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
            }
        },
        {
            field: 'area',
            headerName: 'Area',
            width: 130,
            align: 'left',
            headerAlign: 'center',
            headerClassName: 'title-app-theme-header',
        },
        {
            field: 'acciones',
            headerName: 'Acciones',
            headerClassName: 'title-app-theme-header',
            sortable: false,
            align: 'right',
            headerAlign: 'center',
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Editar usuario" placement="top" arrow>
                            <EditIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
                                handleOpenModal(cellValues, 'edit')
                            }} />
                        </Tooltip>
                        <Tooltip title="Eliminar usuario" placement="top" arrow>
                            <DeleteIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
                                handleOpenModal(cellValues, 'delete')
                            }} />
                        </Tooltip>
                    </>

                )
            }
        }



    ];

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    // const sortModel = [{ field: "id", sort: "desc" }, { field: "email", sort: "desc" },];

    const ColorButton = styled(Button)(() => ({
        color: '#303f9f',
        border: '1px solid #303f9f',

        '&:hover': {
            backgroundColor: '#303f9f',
            color: '#fff',
            border: '1px solid #303f9f'
        },
    }));

    return (
        <>
            <Grid
                item
                xs={12}
                sx={{ marginLeft: '0px' }}
            >
                {

                    loading
                        ? <Loading />

                        : error !== null
                            ?
                            error.err
                                ?
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {error.statusText} — <strong>{error.status}</strong>
                                        {error.status === 401 ? logout() : null}
                                    </Alert>

                                </Stack>
                                :
                                !data
                                    ? null

                                    : <>
                                        <Modal open={openModal} close={closeModal} type='user' action={actionModal} success={dataSuccess} data={dataModal} dataform={data}/>



                                        <Typography className='titleColor' variant="h4" gutterBottom component="div">
                                            Usuarios
                                        </Typography>
                                        <Typography className='textColor' variant="subtitle1" gutterBottom component="div">
                                            Lista de usuarios
                                        </Typography>

                                        <div className='topBotton'>
                                            <ColorButton
                                                startIcon={<AddIcon />}
                                                variant="outlined"
                                                onClick={() => handleOpenModal('', 'addUser')}
                                            >
                                                Agregar Usuario
                                            </ColorButton>
                                        </div>
                                        <Paper elevation={1}>
                                            <div className='wraperTable' >
                                                <DataGrid
                                                    autoHeight={true}
                                                    disableColumnMenu
                                                    rows={data.usuarios}
                                                    columns={columns}
                                                    pageSize={pageSize}
                                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                                    // sortModel={sortModel}
                                                    hideFooterSelectedRowCount
                                                    disableSelectionOnClick
                                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                    components={{
                                                        Toolbar: CustomToolbar,
                                                    }}
                                                />
                                            </div>
                                        </Paper>
                                    </>

                            : ''





                }




            </Grid>
        </>

    )
}


export default ListUser