import * as React from 'react';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

//MUI
import Grid from '@mui/material/Grid';
import MuiAlert from '@mui/material/Alert';

//Component
import SideBar from '../../Components/SideBar';
import TopBar from '../../Components/TopBar'
import ListUser from '../../Components/ListUser'


//Hooks
import { useAuth } from '../../Hooks/useAuth'

import '../../styles.scss'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Users() {
    const { token, deleteToken, userRol } = useAuth()
    const [isToken, setIsToken] = useState(token)


    const handleLogout = () => {
        deleteToken()
        setIsToken('')
    }

    return (
        userRol() === '2'
            ? <Alert severity="error">No tienes permiso para esta secciòn</Alert>
            :
            isToken ?
                <>
                    <TopBar logout={handleLogout} />
                    <div className='container'>
                        <aside>
                            <SideBar logout={handleLogout} />
                        </aside>
                        <Grid
                            container
                            spacing={2}
                            sx={{ p: 4 }}
                        >

                            <ListUser token={token} deleteToken={handleLogout} />

                        </Grid>

                    </div>


                </>

                : <Navigate to='/login' />


    )

}

export default Users