

import { useState, useEffect } from 'react';

//Material
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { indigo } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';



import { useAuth } from '../../Hooks/useAuth';

//component
import Loading from '../Loading';



const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: indigo[500],
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: indigo[500],
	},
	'& .MuiOutlinedInput-root': {
		color: indigo[700],

		'&:hover fieldset': {
			borderColor: indigo[500],
		},
		'&.Mui-focused fieldset': {
			borderColor: indigo[500],
		},
	},
});



const AddProduct = ({ close, title, data, success }) => {
	// console.log(data.skus)

	useEffect(() => {
		// set a clean up flag
		let isSubscribed = true;
		if (isSubscribed) {
			const dataChecked = []
			data.areas.map((v, i) => {
				// console.log(i)
				if (v.des === '') {

					dataChecked.push({ id: v.id, name: `${v.id}`, checked: false })
				} else {
					dataChecked.push({ id: v.id, name: v.des, checked: false })
				}
				setChecked(dataChecked)
				return v
			})
		}
		// cancel subscription to useEffect
		return () => (isSubscribed = false)
	}, [data.areas]);


	const { token } = useAuth()

	const [nameProduct, setNameProduct] = useState('')
	const [typeP, setTypeP] = useState('')
	const [catP, setCatP] = useState('')
	const [medidas, setMedidas] = useState('')
	const [cant, setCant] = useState('')
	const [costounitario, setPrecio] = useState('')
	// const [idProveedor, setProveedor] = useState('')
	const [cantidadMinima, setMin] = useState('')
	const [sku, setSku] = useState('')
	const [state, setState] = useState([]);
	const [checked, setChecked] = useState([]);

	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState(false)
	const [titleMessage, setTitleMessage] = useState('')

	const [errorInputSku, setErrorInputSku] = useState(false)
	const [msgInputSku, setMsgInputSku] = useState()

	const [isServiceType, setIsServiceType] = useState(false)




	const objProduct = {
		'producto': nameProduct,
		'idTipo': typeP,
		'idCategoria': catP,
		'idMedida': medidas,
		'cantidad': cant,
		costounitario,
		'idProveedor': '10',
		sku,
		'areas': state,
		'cantidadMinima': cantidadMinima
	}


	const changeInput = (e) => {
		console.log(e)
		if (e === 3) {

			setIsServiceType(true)
			setTypeP(e)
		} else {
			setIsServiceType(false)
			setTypeP(e)
		}
	}

	const handleChange = (event) => {

		//console.log(event.target.checked)
		//console.log(event.target.name)

		var found = checked.findIndex(e => e.name === event.target.name)
		//console.log(found)

		if (found !== -1) {

			checked.map((v, i) => {
				if (i === found) {
					// console.log('cambiar el cheched en este index: ' + i)
					let temp_state = [...checked]
					let temp_element = { ...temp_state[found] }
					temp_element.checked = event.target.checked ? true : false
					temp_state[found] = temp_element
					setChecked(temp_state)

					if (event.target.checked) {
						let stateTemp = [...state]
						stateTemp.push(v.id)
						setState(stateTemp)
						// state.push(v.id)
					} else {
						let stateTemp = [...state]
						stateTemp.splice(state.indexOf(v.id), 1);
						setState(stateTemp)
					}

				}
				return v
			})
		}


	};

	const handleSaveProduct = () => {

		const uriApi = process.env.REACT_APP_URI_API
		setLoading(true)

		fetch(`${uriApi}producto`, {
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + token },
			body: JSON.stringify(objProduct)
		})
			.then((response) => response.json())
			.then((json) => {


				if (json.message === "Producto creado") {
					setMessage(true)
					success(true)

					setTitleMessage(json.message)
				}
				setLoading(false)
			});



	}

	const handleComparationSku = (sku) => {


		const search = data.skus.find((v, i) => {

			// console.log(v.des)
			if (v.des === sku) {
				setErrorInputSku(true)
				setMsgInputSku('Ya existe este SKU, ingresa uno nuevo')
				return true
			} else {
				setErrorInputSku(false)
				setMsgInputSku('')
				setSku(sku)
				return false
			}

		})

		if (search === undefined) {

			setSku(sku)
		}


	}

	return (
		<>
			<DialogTitle>
				{title}
				<IconButton
					aria-label="close"
					onClick={close}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: '#989ba1',
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				{
					loading ?

						<Loading />
						:
						message
							?
							<Alert severity="success">
								<AlertTitle>Success</AlertTitle>
								{titleMessage}
							</Alert>
							:

							<Box
								component="form"
								sx={{
									'& .MuiTextField-root': { m: 0 },
								}}
								noValidate
								autoComplete="off"

							>

								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>

										<CssTextField
											// error={allInputs.name !== '' ? false : true}
											// helperText={allInputs.name !== '' ? '' : 'Es requerido este campo'}
											id="outlined-error"
											label="Nombre"
											name='name'
											onChange={e => setNameProduct(e.target.value)}
											// onChange={onChangeI}
											// onBlur={onChangeI}


											fullWidth

										/>

										{
											isServiceType
												? ''
												:
												<>
													<FormControl fullWidth sx={{ mt: 2 }}>
														<InputLabel >Categoría</InputLabel>
														<Select
															value={catP}
															label="Categoría"
															onChange={e => setCatP(e.target.value)}

														>
															{
																data.categorias.map(v => (
																	<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>

																))
															}
														</Select>
													</FormControl>
													<FormControl fullWidth sx={{ mt: 2 }}>
														<CssTextField

															type="number"
															inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
															id="outlined-error"
															label="Cantidad"
															onChange={e => setCant(e.target.value)}


														/>
													</FormControl>
													<FormControl fullWidth sx={{ mt: 2 }}>
														<CssTextField

															type="number"
															inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
															id="outlined-error"
															label="Cantidad Mínima"
															onChange={e => setMin(e.target.value)}

														/>
													</FormControl>
													{/* <FormControl fullWidth sx={{ mt: 2 }}>
														<InputLabel >Proveedor</InputLabel>
														<Select
															value={idProveedor}
															label="Proveedor"
															onChange={e => setProveedor(e.target.value)}
														>
															{
																data.proveedores.map(v => (
																	<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>

																))
															}
														</Select>
													</FormControl> */}
													<FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
														<FormLabel component="legend">Áreas</FormLabel>
														<FormGroup>
															{
																checked.map((v, i) => (
																	<FormControlLabel
																		key={i}
																		control={
																			<Checkbox checked={v.checked} onChange={handleChange} name={v.name} />
																		}
																		label={v.name}
																	/>
																))
															}

														</FormGroup>

													</FormControl>

												</>

										}

									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth  >
											<InputLabel id="demo-simple-select-label">Tipo</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={typeP}
												label="Tipo"
												onChange={e => changeInput(e.target.value)}
											>
												{
													data.tipos.map(v => (
														<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>

													))
												}
											</Select>
										</FormControl>
										{
											isServiceType
												? <FormControl fullWidth sx={{ mt: 2 }}>
													<CssTextField
														error={errorInputSku ? true : false}
														id="outlined-error"
														label="Sku"
														helperText={msgInputSku}
														// onChange={e => setSku(e.target.value)}
														onChange={e => handleComparationSku(e.target.value)}

													/>
												</FormControl>
												:
												<>
													<FormControl
														fullWidth
														sx={{ mt: 2 }}

													>
														<InputLabel id="demo-simple-select-label">Medidas</InputLabel>
														<Select

															labelId="demo-simple-select-label"
															id="demo-simple-select"
															value={medidas}
															label="Medidas"
															name='medidas'
															onChange={e => setMedidas(e.target.value)}


														>
															{
																data.unidadesMedidas.map(v => (
																	<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>

																))
															}
														</Select>

													</FormControl>
													<FormControl fullWidth sx={{ mt: 2 }}>
														<CssTextField

															type="number"
															inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
															id="outlined-error"
															label="Costo Unitario"
															onChange={e => setPrecio(e.target.value)}
															InputProps={{
																startAdornment: <InputAdornment position="start">$</InputAdornment>,
															}}

														/>
													</FormControl>
													<FormControl fullWidth sx={{ mt: 2 }}>
														<CssTextField
															error={errorInputSku ? true : false}
															id="outlined-error"
															label="Sku"
															helperText={msgInputSku}
															// onChange={e => setSku(e.target.value)}
															onChange={e => handleComparationSku(e.target.value)}

														/>
													</FormControl>
												</>
										}

									</Grid>

								</Grid>

							</Box>

				}
			</DialogContent>
			{
				loading
					?
					null
					:
					message
						?
						null
						:
						<DialogActions>

							{isServiceType ?
								<Button
									disabled={
										nameProduct === '' || sku === '' || errorInputSku
											? true
											: false}
									onClick={handleSaveProduct}
								>
									Crear producto
								</Button>
								: <Button
									disabled={nameProduct === '' ?
										true : medidas === ''
											? true : costounitario === ''
												? true : cant === ''
													? true : errorInputSku
														? true : catP === ''

															? true : sku === ''
																? true : false
									}
									onClick={handleSaveProduct}
								>
									Crear producto
								</Button>
							}

						</DialogActions>

			}




		</>
	)
}

export default AddProduct