import { useState } from "react"

export const useAuth = (props) => {


    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        // const userToken = JSON.parse(tokenString);
        // console.log(tokenString)
        return tokenString
    }
    const getUserName = () => {
        const tokenString = localStorage.getItem('user');
        // const userToken = JSON.parse(tokenString);
        // console.log(tokenString)
        return tokenString
    }
    const getUserRol = () => {
        const tokenString = localStorage.getItem('userRol');
        // const userToken = JSON.parse(tokenString);
        // console.log(tokenString)
        return tokenString
    }



    const [token, setToken] = useState(getToken());
    // const [userName, setUserName] = useState(getUserName());
    // const [userRol, setUserRol] = useState(getUserRol());



    const saveToken = (userToken, user, rol) => {
        // console.log(rol)
        localStorage.setItem("token", userToken)
        localStorage.setItem("user", user)
        localStorage.setItem("userRol", rol)
        setToken(userToken)
        // setUserName(user)
        // setUserRol(rol)

    }

    const removeToken = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('userRol');
        setToken(null)

    }




    return { token, setToken: saveToken, deleteToken: removeToken, userName: getUserName, userRol: getUserRol }

}