import { useEffect, useState } from 'react';
//MUI
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { indigo } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
//hooks
import { useAuth } from '../../Hooks/useAuth'
//Components
import Loading from '../../Components/Loading'

const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: indigo[500],
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: indigo[500],
	},
	'& .MuiOutlinedInput-root': {
		color: indigo[700],

		'&:hover fieldset': {
			borderColor: indigo[500],
		},
		'&.Mui-focused fieldset': {
			borderColor: indigo[500],
		},
	},
});

const EditProduct = ({ title, close, data, success, categories }) => {


	const uriApi = process.env.REACT_APP_URI_API
	const { token } = useAuth()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState()

		// const [cat, setCat] = useState()
	const [nameProduct, setNameProduct] = useState('')
	const [catP, setCatP] = useState(1)
	const [cant, setCant] = useState('')
	const [idProveedor, setProveedor] = useState('')
	const [typeP, setTypeP] = useState('')
	const [medidas, setMedidas] = useState('')
	const [costounitario, setPrecio] = useState('')
	const [sku, setSku] = useState()
	const [state, setState] = useState([]);
	const [checked, setChecked] = useState([]);
	const [cantidadMinima, setMin] = useState('')

	const [errorInputSku, setErrorInputSku] = useState(false)
	const [msgInputSku, setMsgInputSku] = useState()


	useEffect(() => {
				// set a clean up flag
		let isSubscribed = true;
		if (isSubscribed) {
			if (data) {
				setLoading(true);
				// console.log( data );
				// console.log( categories );

				const getProduct = () => {
					fetch(`${uriApi}producto/${data.id ? data.id : 1}`, {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + token

						}
					})
					.then(response => response.json())
					.then((response) => {


						setNameProduct(response[0].producto)
						setSku(response[0].sku)
						setCant(response[0].cantidad)
						setMin(response[0].cantidadMinima)
														// const cat = response[0].categoria

						const searchCat = categories.categorias.find((v, i) => {
							if (v.des === response[0].categoria) {
																		// setCatP(searchCat.id)
								return true
							} else {
								return false
							}
						})

						if (searchCat) {
							setCatP(searchCat.id)
						}


						const searchP = categories.proveedores.find((v, i) => {
							if (v.des === response[0].proveedor) {

								return true


							} else {
								return false
							}
						})

						if (searchP) {
							setProveedor(searchP.id)
						}

						const searchAreasArray = []
						const arrayId = []


						response[0].areas.map((v, i) => {

							categories.areas.find((value, i) => {
								if (value.des === v.des) {
									searchAreasArray.push({ id: value.id, des: value.des, checked: true })
									arrayId.push(value.id)
									// return true
								} else {
									searchAreasArray.push({ id: value.id, des: value.des, checked: false })
									// return true
								}
							})

							return v
						})

						// console.log( searchAreasArray );

						// const g = categories.areas.filter((ele) => !response[0].areas.find(({ id }) => ele.id === id))
						// console.log( g );

						// const newG = [...g]
						// newG.map((v, i) => v.checked = false)
						// console.log( newG );
						// const child = searchAreasArray.concat(newG)
						const child = searchAreasArray;
						// console.log( child );
						setChecked( child )
						setState( arrayId )

						setTypeP( response[0].idTipo )
						setMedidas( response[0].idMedida )
						setPrecio( response[0].costounitario )
					} )
					.catch((err) => {
						setError(err)
						console.log(error)
					})
					.finally(() => setLoading(false));
				}

				getProduct()
			}
		}


				// cancel subscription to useEffect
		return () => (isSubscribed = false)
	}, [categories, uriApi, token, data, error]);


	const handleComparationSku = (sku) => {

		const search = categories.skus.find((v, i) => {
			if (v.des === sku) {
				setErrorInputSku(true)
				setMsgInputSku('Ya existe este SKU, ingresa uno nuevo')
				return true
			} else {
				setErrorInputSku(false)
				setMsgInputSku('')
				setSku(sku)
				return false
			}

		})

	}

	const handleChange = (event) => {

		var found = checked.findIndex(e => e.des === event.target.name)

		if (found !== -1) {

			checked.map((v, i) => {
				if (i === found) {

					let temp_state = [...checked]
					let temp_element = { ...temp_state[found] }
					temp_element.checked = event.target.checked ? true : false
					temp_state[found] = temp_element
					setChecked(temp_state)

					if (event.target.checked) {
						let stateTemp = [...state]
						stateTemp.push(v.id)
						setState(stateTemp)
												// state.push(v.id)
					} else {
						let stateTemp = [...state]
						stateTemp.splice(state.indexOf(v.id), 1);
						setState(stateTemp)
					}

				}
				return v
			})
		}


	};


	const objProduct = {
		'producto': nameProduct,
		'idTipo': typeP,
		'idCategoria': catP,
		'idMedida': medidas,
		'cantidad': cant,
		costounitario,
		idProveedor,
		sku,
		'areas': state
	}

	const handleSaveProduct = () => {


		const uriApi = process.env.REACT_APP_URI_API
		setLoading(true)

		fetch(`${uriApi}producto/${data.id}`, {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token

			},
			body: JSON.stringify(objProduct)
		})
		.then((response) => response.json())
		.then((json) => {
			setLoading(false)

			success(true)

		});
	}

	return (

		<>
		<DialogTitle>
		{title}

		<IconButton
		aria-label="close"
		onClick={close}
		sx={{
			position: 'absolute',
			right: 8,
			top: 8,
			color: '#989ba1',
		}}
		>
		<CloseIcon />
		</IconButton>
		</DialogTitle>
		<DialogContent dividers>



		{
			loading
			? <Loading />
			:
			<Box
			component="form"
			sx={{
				'& .MuiTextField-root': { m: 0 },
			}}
			noValidate
			autoComplete="off"

			>
			<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
			<CssTextField

			id="outlined-error"
			label="Nombre"
			name='name'
			onChange={e => setNameProduct(e.target.value)}
			value={nameProduct}
			fullWidth

			/>
			{
				data
				? data.tipo === 'Servicio'
				? null
				:
				<>
				<FormControl fullWidth sx={{ mt: 2 }}>
				<InputLabel >Categoría</InputLabel>
				<Select
				value={catP}
				label="Categoría"
				onChange={e => setCatP(e.target.value)}
				>
				{
					categories ?
					categories.categorias.map(v => (
						<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>

						))
					: null
				}
				</Select>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 2 }}>
				<CssTextField
				value={cant}
				type="number"
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
				id="outlined-error"
				label="Cantidad"
				onChange={e => setCant(e.target.value)}
				disabled={true}

				/>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 2 }}>
				<CssTextField
				value={cantidadMinima}
				type="number"
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
				id="outlined-error"
				label="Cantidad Mínima"
				onChange={e => setMin(e.target.value)}


				/>
				</FormControl>
				<FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
				<FormLabel component="legend">Áreas</FormLabel>
				<FormGroup>
				{
					checked ?
						checked.map((v, i) => (
							<FormControlLabel key={i} control={ <Checkbox checked={v.checked} onChange={handleChange} name={v.des} /> } label={v.des} />
						) )
					: null
				}

				</FormGroup>

				</FormControl>
				</>
				: null
			}



																		{/* <FormControl fullWidth sx={{ mt: 2 }}>
																				<InputLabel >Proveedor</InputLabel>
																				<Select
																						value={idProveedor}
																						label="Proveedor"
																						onChange={e => setProveedor(e.target.value)}
																				>
																						{
																								categories.proveedores.map(v => (
																										<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>

																								))
																						}
																				</Select>
																		</FormControl> */}



			</Grid>

			<Grid item xs={12} md={6}>

			{
				data
				? data.tipo === 'Servicio'
				? <FormControl fullWidth  >
				<InputLabel id="demo-simple-select-label">Tipo</InputLabel>
				<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={typeP}
				label="Tipo"
				onChange={e => setTypeP(e.target.value)}

				>
				{
					categories.tipos.map(v => (
						<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>

						))
				}
				</Select>
				</FormControl>
				:
				<>
				<FormControl fullWidth  >
				<InputLabel id="demo-simple-select-label">Tipo</InputLabel>
				<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={typeP}
				label="Tipo"
				onChange={e => setTypeP(e.target.value)}

				>
				{
					categories.tipos.map(v => (
						<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>

						))
				}
				</Select>
				</FormControl>
				<FormControl
				fullWidth
				sx={{ mt: 2 }}

				>
				<InputLabel id="demo-simple-select-label">Medidas</InputLabel>
				<Select

				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={medidas}
				label="Medidas"
				name='medidas'
				onChange={e => setMedidas(e.target.value)}


				>
				{
					categories.unidadesMedidas.map(v => (
						<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>

						))
				}
				</Select>

				</FormControl>
				<FormControl fullWidth sx={{ mt: 2 }}>
				<CssTextField
				value={costounitario}
				type="number"
				inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
				id="outlined-error"
				label="Costo Unitario"
				onChange={e => setPrecio(e.target.value)}

				/>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 2 }}>
				<CssTextField
				value={sku}
				error={errorInputSku ? true : false}
				id="outlined-error"
				label="Sku"
				helperText={msgInputSku}
																														// onChange={e => setSku(e.target.value)}
				onChange={e => handleComparationSku(e.target.value)}

				/>
				</FormControl>
				</>
				: null
			}






			</Grid>

			</Grid>


			</Box>



		}


		</DialogContent>
		<DialogActions>

		<Button
		onClick={handleSaveProduct}

		>
		GUARDAR CAMBIOS
		</Button>
		</DialogActions>
		</>
		)
}


export default EditProduct