import Avatar from '@mui/material/Avatar';
import Dashboard from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import Paper from '@mui/material/Paper';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import logo from '../../src/logo_t.png'

import { NavLink } from 'react-router-dom'
import { useAuth } from '../Hooks/useAuth';

let modules = {
	1: [
		{
			'component': <Dashboard/>,
			'to': '/dashboard',
			'lbl': 'Inicio',
		},
		{
			'component': <DescriptionIcon/>,
			'to': '/productos',
			'lbl': 'Inventario',
		},
		{
			'component': <ReceiptIcon/>,
			'to': '/venta-proyecto',
			'lbl': 'Venta Proyecto',
		},
		{
			'component': <AttachMoneyIcon/>,
			'to': '/venta-semanal',
			'lbl': 'Consumo Semanal',
		},
		{
			'component': <PersonIcon/>,
			'to': '/usuarios',
			'lbl': 'Usuarios',
		},
	],
	2: [
		{
			'component': <DescriptionIcon/>,
			'to': '/productos',
			'lbl': 'Inventario',
		},
		// {
		// 	'component': <ReceiptIcon/>,
		// 	'to': '/venta-proyecto',
		// 	'lbl': 'Venta Proyecto',
		// },
		{
			'component': <AttachMoneyIcon/>,
			'to': '/venta-semanal',
			'lbl': 'Consumo Semanal',
		},
	]
}

function SideBar({ logout }) {
	const { userName, userRol } = useAuth()
	// console.log(userRol())
	const letterName = (value) => {
		//console.log(value)
		if (value) {
			// const fullName = value.split(' ');
			// const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
			// return initials.toUpperCase();
			const fullName = value.trim();
			const initials = fullName.charAt(0);
			return initials.toUpperCase();
		}
		return 'sin datos'
	}

	return (
		<>
			<div className='containerSidebar'>
				<header className='header'>
					<div className="logo">
						<img src={logo} alt="totallPublicidad" />
					</div>
				</header>
				<div className="menu">
					{
						modules[ userRol() ].map( row => (
							<div className="itemMenu">
								<NavLink className='itemLink' to={ row.to }>
									{ row.component }
									<p>{ row.lbl }</p>
								</NavLink>
							</div>
						) )
					}
				</div>
				<div className='footerSideBar'>
					<Paper elevation={2} className='boxUserSide'>
						<Avatar className='imgAvatar' sx={{ width: 56, height: 56 }}>{letterName(userName())}</Avatar>
						<p className='avatarName'>{userName()}</p>
						<div className='logoutBox' onClick={logout} >
							<LogoutIcon sx={{ fontSize: 20 }} />
							<p >Salir</p>
						</div>
					</Paper>
				</div>
			</div>
		</>
	)
}

export default SideBar