
import { useState } from 'react'
import { Navigate } from 'react-router-dom';


//mui
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';



//Icon
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


//Hooks
import { useForm } from '../../Hooks/useForm'

//Component
import Loading from '../../Components/Loading'



const IngresoInventario = ({ title, close, data, idProduct, medidas, success }) => {
	console.log(idProduct)

	const initialForm = {
		'idproducto': idProduct ? idProduct.id : '',
		'cantidad': '',
		'costounitario': '',
		'idProveedor': idProduct ? idProduct.idProveedor : '',
		'idMedida': idProduct ? idProduct.idMedida : ''
	}

	const validationsForm = (form) => {
		const errors = {}


		if (!form.cantidad.trim()) {
			errors.cantidad = 'El campo es requerido'
		}

		if (!form.costounitario.trim()) {
			errors.costounitario = 'El campo es requerido'
		}

		// if (!form.idProveedor) {
		//     errors.idProveedor = 'El campo es requerido'
		// }
		// if (!form.medida) {
		//     errors.medida = 'El campo es requerido'
		// }



		return errors
	}

	const {
		form,
		errors,
		loading,
		response,
		handleChange,
		handleBlur,
		handleSubmit } = useForm(initialForm, validationsForm)

	const [responseState, setResponseState] = useState(response)


	const closeSuccess = () => {
		setTimeout(() => {
			setResponseState('')
			success()
		}, 3000);
	}

	const msg = () => {

		if (responseState) {
			if (response.status === 422) {
				return <Alert severity="error">
					{response.body.errors.email}
				</Alert>
			} else if (response.status === 201 || response.status === 200) {
				closeSuccess()
				return <Alert severity="success">
					Se ingreso correctamente el inventario {idProduct ? idProduct.name : null}
				</Alert>
			} else if (response.err) {
				return <Alert severity="error">
					Algo ocurrió, intentalo mas tarde --
					{response.status} {response.statusText} {response.status === 401 ? <Navigate to="/login" replace={true} /> : null}
				</Alert>
			}
		}

	}


	return (
		<>
			{
				loading
					? <Loading />
					: <>
						<DialogTitle>
							{title}
							<IconButton
								aria-label="close"
								onClick={close}
								sx={{
									position: 'absolute',
									right: 8,
									top: 8,
									color: '#989ba1',
								}}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<Box sx={{ mt: 2, mb: 2 }}>
								{
									msg()
								}
							</Box>

							<Alert sx={{ mb: 2 }} severity="info"> Ingresa inventario para el item -- {idProduct ? idProduct.name : null} -- sku: {idProduct ? idProduct.sku : null} -- Precio Histórico:  {idProduct ? idProduct.costounitario : null}</Alert>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<FormControl fullWidth sx={{ mt: 2 }}>
										<TextField

											error={errors.cantidad ? true : false}
											value={form.cantidad}
											name='cantidad'
											type="number"
											inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
											label="Cantidad"
											onBlur={handleBlur}
											onChange={handleChange}
											helperText={errors.cantidad ? errors.cantidad : 'Ingresa una cantidad'}

										/>
									</FormControl>
									<FormControl fullWidth sx={{ mt: 2 }}>
										<TextField

											error={errors.costounitario ? true : false}
											value={form.costounitario}
											name='costounitario'
											type="number"
											inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
											label="Costo Unitario"
											onBlur={handleBlur}
											onChange={handleChange}
											helperText={errors.costounitario ? errors.costounitario : 'Ingresa una cantidad'}
											InputProps={{
												startAdornment: <InputAdornment position="start">$</InputAdornment>,
											}}

										/>
									</FormControl>

								</Grid>
								<Grid item xs={12} md={6}>
									<FormControl fullWidth sx={{ mt: 2 }}>
										<TextField
											disabled={true}
											error={errors.idProveedor ? true : false}
											id="outlined-select-currency"
											select
											label="Proveedor"
											name='idProveedor'
											value={form.idProveedor}
											onBlur={handleBlur}
											onChange={handleChange}
											helperText={errors.idProveedor ? errors.idProveedor : 'Selecciona un proveedor'}
										>
											{
												data.map(v => (
													<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>
												))
											}

										</TextField>
									</FormControl>
									<FormControl fullWidth sx={{ mt: 2 }}>
										<TextField
											disabled={true}
											error={errors.idMedida ? true : false}
											id="outlined-select-currency"
											select
											label="Medida"
											name='idMedida'
											value={form.idMedida}
											onBlur={handleBlur}
											onChange={handleChange}
											helperText={errors.idMedida ? errors.idMedida : 'Selecciona una unidad de medida'}
										>
											{
												medidas.map(v => (
													<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>

												))
											}

										</TextField>
									</FormControl>

								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>

							<Button
								disabled={
									form.cantidad !== ''
										? form.costounitario !== ''
											? false
											: true
										: true

								}
								onClick={(e) => handleSubmit(e, idProduct ? idProduct.id : '', 'ingreso')}

							>
								GUARDAR
							</Button>

						</DialogActions>
					</>



			}

		</>

	)
}

export default IngresoInventario