import * as React from 'react';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

//MUI
import Grid from '@mui/material/Grid';

//Component
import SideBar from '../../Components/SideBar';
import TopBar from '../../Components/TopBar'
import ListSalesProyect from '../../Components/ListSalesProyect';

//Hooks
import { useAuth } from '../../Hooks/useAuth'
import '../../styles.scss'

// const Alert = React.forwardRef(function Alert(props, ref) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

function ProyectSection() {
		const { token, deleteToken } = useAuth()
		const [isToken, setIsToken] = useState(token)


		const handleLogout = () => {
				deleteToken()
				setIsToken('')
		}

		return (
			isToken ?
				<>
					<TopBar logout={handleLogout} />
					<div className='container'>
						<aside>
							<SideBar logout={handleLogout} />
						</aside>
						<Grid container spacing={2} sx={{ p: 4 }}>
							<ListSalesProyect token={token} deleteToken={handleLogout} isweeklysale={1} />
						</Grid>
					</div>
				</>
			: <Navigate to='/login' />
		)
}

export default ProyectSection