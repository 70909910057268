




export const helpHttp = () => {



    const customFetch = (endpoint, options) => {
        const defaultHeader = {
            accept: "application/json"
        };

        const controller = new AbortController();
        options.signal = controller.signal;

        options.method = options.method || "GET";
        options.headers = options.headers
            ? { ...defaultHeader, ...options.headers }
            : defaultHeader;
        console.log( options.headers );
        options.body = JSON.stringify(options.body) || false;
        if (!options.body) delete options.body;

        //console.log(options);
        setTimeout(() => controller.abort(), 5000);
        console.log( options );


        return fetch(endpoint, options)
            .then((res) => {
                if (res.ok || res.status === 422) {
                    // return { data: res.json() }
                    return res.json().then(data => ({ body: data, status: res.status, err: false }))
                } else {
                    // return res.json().then(data => ({ body: data, status: res.status || "00", statusText: res.statusText || "Ocurrió un error", err: true }))
                    // return Promise.reject({
                    //     err: true,
                    //     status: res.status || "00",
                    //     statusText: res.statusText || "Ocurrió un error",
                    // })

                    const abort = {
                        err: true,
                        status: res.status || "00",
                        statusText: res.statusText || "Ocurrió un error",
                    }
                    return abort
                }
            }
                // res.ok || res.status === 422
                //     ? res.json()
                //     : Promise.reject({
                //         err: true,
                //         status: res.status || "00",
                //         statusText: res.statusText || "Ocurrió un error",
                //     })
            )
            .catch((err) => err);
    };

    const get = (url, options = {}) => customFetch(url, options);

    const post = (url, options = {}) => {
        options.method = "POST";
        return customFetch(url, options);
    };

    const put = (url, options = {}) => {
        options.method = "PUT";
        return customFetch(url, options);
    };

    const del = (url, options = {}) => {
        options.method = "DELETE";
        return customFetch(url, options);
    };

    return {
        get,
        post,
        put,
        del,
    };
};