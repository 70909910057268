

import { Navigate } from 'react-router-dom';
import { useAuth } from '../Hooks/useAuth';

const PrivateRoute = ({ children, redirectTo }) => {
    const { token } = useAuth();

    return token ? children : <Navigate to={redirectTo} />
}


export default PrivateRoute