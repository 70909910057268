import { useState, useEffect } from "react"

export const useGetProducts = ( url, token, isweeklysale ) => {

	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		// set a clean up flag
		let isSubscribed = true;

		if (isSubscribed) {
			const getData = async (url) => {
				try {
					let res = await fetch( isweeklysale ? `${url}producto?tipo=1` : `${url}producto` , {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + token
						}
					})

					if (!res.ok) {
						const e = {
							err: true,
							status: res.status,
							statusText: !res.statusText ? 'Ocurrio un error' : res.statusText
						}

						throw e
					}

					let data = await res.json()

					if (data.status) {
						console.log('token invalid')
						setData(data)
						setError(true)

					} else {
						setLoading(false)
						console.log(data)
						setData(data)
						setError(false)
					}




				} catch (err) {
					setLoading(true)
					setError(err)

				}
			}

			getData(url)
		}

		// cancel subscription to useEffect
		return () => (isSubscribed = false)


	}, [url, token]);


	return { data, error, loading };



}