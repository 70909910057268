
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';


const Loading = () => {


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    height: '50vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <CircularProgress
                    sx={{
                        color: '#303f9f'
                    }}
                />
            </Box>
        </>
    )
}


export default Loading