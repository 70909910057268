import * as React from 'react';

import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

//Material UI
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ButtonGroup from '@mui/material/ButtonGroup';
// import Box from '@mui/material/Box';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';

// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';

import { Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
// import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import {
	DataGrid, esES, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, GridToolbar

} from '@mui/x-data-grid';

// import {
//     DataGrid, esES, GridToolbarContainer, GridToolbarExport, GridToolbar, GridToolbarQuickFilter,
//     GridLinkOperator
// } from '@mui/x-data-grid';

// import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { red, green, indigo, orange } from '@mui/material/colors';

//icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import InventoryIcon from '@mui/icons-material/Inventory'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SearchIcon from '@mui/icons-material/Search';
import TimelineIcon from '@mui/icons-material/Timeline';
import StoreIcon from '@mui/icons-material/Store';

//Hooks
import { useAuth } from '../../Hooks/useAuth'


//Components
import Loading from '../../Components/Loading';
import Modal from '../../Components/Modal'
import SideBar from '../../Components/SideBar';
import TopBar from '../../Components/TopBar';
import Switchcom from '../../Components/Switch';


import '../../styles.scss'


const ColorButton = styled(Button)(() => ({
	color: '#303f9f',
	border: '1px solid #303f9f',

	'&:hover': {
		backgroundColor: '#303f9f',
		color: '#fff',
		border: '1px solid #303f9f'
	},
}));

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const getChipProps = (params) => {
	if (params.value === "red") {
		return {
			icon: <InfoIcon style={{ fill: red[500] }} />,
			label: "No",
			style: {
				borderColor: red[500]
			}
		};
	} else if (params.value === 0) {
		return {
			icon: <StoreIcon style={{ fill: indigo[900] }} />,
			label: "Servicio",
			style: {
				borderColor: indigo[900]
			}
		};

	} else if (params.value === "orange") {
		return {
			icon: <InfoIcon style={{ fill: orange[500] }} />,
			label: "Solicitar",
			style: {
				borderColor: orange[900]
			}
		};

	} else {
		return {
			icon: <CheckCircleOutlineRoundedIcon style={{ fill: green[500] }} />,
			label: "Si",
			style: {
				borderColor: green[500]
			}
		};
	}
}
let filtersopt = [];



function Products() {
	const uriApi = process.env.REACT_APP_URI_API
	const { token, deleteToken, userRol } = useAuth()
	const [isToken, setIsToken] = useState(token)
	const [openModal, setOpenModal] = useState(false)
	const [openModalRemove, setOpenModalAddRemove] = useState(false)
	const [openModalEdit, setOpenModalEdit] = useState(false)
	const [ openModalDecremento, setOpenModalDecremento ] = useState(false)
	const [ openModalMassive, setOpenModalMassive ] = useState(false)
	const [openModalIngreso, setOpenModalIngreso] = useState(false)
	const [isLoad, setIsLoad] = useState(0)
	const [addDataRow, setAddDataRow] = useState([])
	const [pageSize, setPageSize] = useState(10);
	const [openConfirmation, setOpenConfirmation] = useState(false)
	const [openConfirmationError, setOpenConfirmationError] = useState(false)
	// const [barsearch, setBarsearch] = useState('')
	// const { data, loading, error } = useGetProducts(`${uriApi}producto`, token)

	const [data, setData] = useState([]);
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const [itemRemove, setItemRemove] = useState()
	const [itemEdit, setItemEdit] = useState()
	const [itemIngreso, setItemIngreso] = useState()

	const [sortModel, setSortModel] = useState([
		{ field: "name", sort: "desc" }
	]);

	const [ filt, setFilt ] = useState( [] );
	const [ areas, setAreas ] = useState( [] );

	let navigate = useNavigate();
	let userType = userRol()



	useEffect(() => {
		// set a clean up flag
		let isSubscribed = true;
		if (isSubscribed) {
			// if (userType !== '2') {
				if (token) {
					setLoading(true);


					getProducts( `${uriApi}producto` )

				}
			// }
		}


		// cancel subscription to useEffect
		return () => (isSubscribed = false)


	}, [isLoad, token, uriApi, userType]);

	const getProducts = ( url ) => {
		fetch( url, {
			method: 'GET',

			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token
			}
		})
			.then(response => response.json())
			.then((response) => {

				if (response.status === 'Token is Invalid' || response.status === 'Token is Expired') {
					setIsToken()
					console.log('expiro el token')
				}
				setData(response)
				const productos = []

				let colorP = ''

				response.productos.map((v, i) => {

					if (v.idTipo === 3 || v.idTipo === 0) {
						productos.push({
							'id': v.id,
							'name': v.producto,
							'costounitario': 'n/a',
							'proveedor': 0,
							'tipo': "Servicio",
							'cantidad': 'n/a',
							'medida': 'servicio',
							'color': 0,
							'sku': v.sku,
							'area': v.areas.des,
							'idProveedor': v.idProveedor,
							'idMedida': v.idMedida
						})
					} else {
						// const medida = response.unidadesMedidas.find(value => value.id === v.idMedida)

						if (v.cantidad < 0) {
							colorP = 'red'
						} else if (v.cantidad <= v.cantidadMinima) {
							colorP = 'orange'

						} else {
							colorP = ''
						}
						productos.push({
							'id': v.id,
							'name': v.producto,
							'costounitario': v.costounitario,
							'proveedor': v.proveedor,
							'tipo': v.tipo,
							'cantidad': parseInt(v.cantidad),
							'medida': v.unidadmedida,
							'color': colorP,
							'sku': v.sku,
							'area': v.areas.length > 0 ? v.areas[0].des : 'null',
							'idProveedor': v.idProveedor,
							'idMedida': v.idMedida
						})

					}
					return v
				})

				setAddDataRow(productos)
				setAreas( response.areas );

			})
			.catch(setError)
			.finally(() => setLoading(false));


	}

	let columns = [];

	columns.push( {
		field: 'sku',
		headerName: 'Sku',
		width: 90,
		headerAlign: 'center',
		align: 'left',
		headerClassName: 'title-app-theme-header',
	} );

	columns.push( {
		field: 'area',
		headerName: 'Area',
		width: 100,
		headerAlign: 'center',
		align: 'left',
		headerClassName: 'title-app-theme-header',
	} );

	columns.push( {
		field: 'name',
		headerName: 'Nombre',
		flex: 2,
		minWidth: 250,
		headerAlign: 'center',
		align: 'left',
		headerClassName: 'title-app-theme-header',
	} );

	if( userType !== '2' ){
		columns.push( {
			field: 'costounitario',
			headerName: 'Costo Unitario',
			width: 140,
			type: "number",
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
			renderCell: (params) => {
				if (params.row.tipo === 'Servicio') {
					return '-'
				} else {
					return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(params.value)
				}
			},
		} );
	}

	columns.push( {
		field: 'tipo',
		headerName: 'Tipo',
		width: 110,
		headerAlign: 'center',
		align: 'left',
		headerClassName: 'title-app-theme-header',
	} );

	columns.push( {
		field: 'cantidad',
		headerName: 'Cantidad',
		width: 110,
		type: "number",
		headerAlign: 'center',
		align: 'center',
		headerClassName: 'title-app-theme-header',
	} );

	columns.push( {
		field: 'medida',
		headerName: 'Medida',
		width: 100,
		headerAlign: 'center',
		align: 'left',
		headerClassName: 'title-app-theme-header',
	} );

	columns.push( {
		field: 'color',
		headerName: 'Existencias',
		width: 110,
		headerAlign: 'center',
		align: 'left',
		headerClassName: 'title-app-theme-header',
		renderCell: (params) => {
			return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
		}
	} );

	if( userType !== '2' ){
		columns.push( {
			field: 'acciones',
			headerName: 'Acciones',
			sortable: false,
			width: 120,
			disableClickEventBubbling: true,
			align: 'right',
			headerAlign: 'center',
			headerClassName: 'title-app-theme-header',
			renderCell: (cellValues) => {
				return (
					<>
						{cellValues.row.tipo === 'Servicio'
							? null
							: <Tooltip title="Editar" placement="top" arrow>
								<EditIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
									handleModalEdit(cellValues)
								}} />
							</Tooltip>
						}
						<Tooltip title="Ingreso de Inventario" placement="top" arrow>
							<InventoryIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
								handleModalIngreso(cellValues)
							}} />
						</Tooltip>
						<Tooltip title="Disminuir inventario" placement="top" arrow>
							<RemoveCircleIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
								handleModalDecremento(cellValues)
							}} />
						</Tooltip>
						<Tooltip title="Histórico Inventario" placement="top" arrow>
							<TimelineIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
								handleGoHistorico(cellValues)
							}} />
						</Tooltip>
						<Tooltip title="Eliminar" placement="top" arrow>
							<DeleteIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
								handleModalRemove(cellValues)
							}} />
						</Tooltip>
					</>
				)
			}
		} );
	} else {
		columns.push( {
			field: 'acciones',
			headerName: 'Acciones',
			sortable: false,
			width: 120,
			disableClickEventBubbling: true,
			align: 'right',
			headerAlign: 'center',
			headerClassName: 'title-app-theme-header',
			renderCell: (cellValues) => {
				return (
					<>
						{/*{cellValues.row.tipo === 'Servicio'
							? null
							: <Tooltip title="Editar" placement="top" arrow>
								<EditIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
									handleModalEdit(cellValues)
								}} />
							</Tooltip>
						}*/}
					</>
				)
			}
		} );
	}

	const handleLogout = () => {
		deleteToken()
		setIsToken('')
	}

	const deleteItem = (id) => {
		fetch(`${uriApi}producto/${id}`, {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token
			}
		})
		.then(response => response.json())
		.then((response) => {
			if (response.msg === "Producto eliminado") {
				setOpenConfirmation(true)
				setIsLoad(isLoad + 1)
			}
		})
		.catch(setError)
		.finally(() => setLoading(false));
	}

	const handleModalProducts = () => {
		setOpenModal(!openModal)
	}

	const handleModalProductsMassive = () => {
		setOpenModalMassive( !openModalMassive );
	}

	const handleModalRemove = (params) => {
		setItemRemove(params.row)
		setOpenModalAddRemove(!openModalRemove)

	}
	const handleModalEdit = (params) => {
		setOpenModalEdit(!openModalEdit)
		setItemEdit(params.row)
	}

	const handleModalDecremento = (params) => {
		setItemIngreso( params.row );
		setOpenModalDecremento( !openModalDecremento );
	}

	const handleModalIngreso = (params) => {
		setItemIngreso(params.row)
		setOpenModalIngreso(!openModalIngreso)
	}

	const dataSuccessAddData = (v) => {
		if( v )
			setIsLoad(isLoad + 1);
	}

	const dataSuccessRemoved = (v) => {
		setOpenModalAddRemove(!openModalRemove)
		deleteItem(v)
	}

	const dataSuccessEdit = (v) => {
		setIsLoad(isLoad + 1)
		// setOpenModalIngreso(!openModalIngreso)
		setOpenModalEdit(!openModalEdit)
		// deleteItem(v)
	}

	const dataSuccessEditIngreso = (v) => {
		setIsLoad(isLoad + 1)
		setOpenModalIngreso(!openModalIngreso)
		//setOpenModalEdit(!openModalEdit)
		// deleteItem(v)
	}

	const dataSuccessDecremento = (v) => {
		setIsLoad( isLoad + 1 );
		setOpenModalDecremento( !openModalDecremento );
	}

	const dataSuccessProductsMassive = ( v ) => {
		setIsLoad( isLoad + 1 );
		setOpenModalMassive( !openModalMassive );
	}

	const handleGoHistorico = (v) => {
		const urlHistorico = `/productos/historico/${v.row.id}`
		navigate(urlHistorico)
		// < Navigate to = { urlHistorico } />
	}

	const FilterButton = ( row ) => {
		// console.log( params );
		// let row = params.row;
		if( typeof row !== 'undefined' ){
			let url = `${uriApi}producto`;
			if( 'id' in row ){
				// console.log( row.des );
				if( !filtersopt.includes( row.id ) )
					filtersopt.push( row.id );
				else
					filtersopt = filtersopt.filter( function( val ){
						return val !== row.id;
					} );
				// console.log( filtersopt.length );
				url += filtersopt.length > 0 ? '?area=' + encodeURIComponent( filtersopt ) : '';
			}else
				filtersopt = [];
			// setFilt( filtersopt );
			// console.log( filt );
			// console.log( '*********************' );
			// console.log( filtersopt );
			// console.log( '*********************' );
			// console.log( url );
			getProducts( url );
		}

	}
	// const sortModel = [{ field: "id", sort: "asc" }];

	// const handleChange = (event) => {
	// 	console.log( event );

	// };

	const CustomToolbar = () => {
		return (
			<GridToolbarContainer>
				<div className="app-search">
					<GridToolbarQuickFilter
						className="app-inputSearch"
						InputProps={{
							endAdornment:
								<InputAdornment position="end">
									<SearchIcon
										aria-label="toggle password visibility"
										edge="end"
										style={{ color: '#303f9f' }}
									/>
								</InputAdornment>
						}}
						variant="outlined"
						placeholder='Busca un producto'
						quickFilterParser={(searchInput) =>
							searchInput
								.split(',')
								.map((value) => value.trim())
								.filter((value) => value !== '')
						}
					/>
				</div>
				<GridToolbarExport />

				{/*<FormGroup>*/}
					{/*<FormControlLabel control={<Switch defaultChecked />} label="Label" />
					<FormControlLabel required control={<Switch />} label="Required" />
					<FormControlLabel disabled control={<Switch />} label="Disabled" />*/}
				{/*</FormGroup>*/}
				{ userRol() !== '2' ?
					<ToggleButtonGroup aria-label="text alignment">
						{
							areas.map( row => (
								// <FormControlLabel control={<Switch />} label={row.des} onClick={ () => FilterButton( row ) }/>
								<Switchcom row={row} execFun={FilterButton} filtersopt={filtersopt}/>
								// <FormControlLabel checked={ filtersopt[ row.id ] } label={row.des} onClick={ () => FilterButton( row ) }/>
							) )
						}
					</ToggleButtonGroup>
					: <></>
				}
				{ userRol() !== '2' ?
					<Button onClick={() => FilterButton( [] ) } >
						Todos
					</Button>
					: <></>
				}

			</GridToolbarContainer>
		);
	}

	const handleGoToReport = () => {
		navigate('/productos/reporte-historicos')
	}

	return (

		userRol() === '4'
			? <Alert severity="error">No tienes permiso para esta secciòn</Alert>
			:

			isToken ?

				<>
					<Snackbar
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						open={openConfirmation}
						onClose={() => setOpenConfirmation(false)}
						autoHideDuration={5000}
					>
						<Alert severity="success">Producto eliminado</Alert>
					</Snackbar>
					<Snackbar
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						open={openConfirmationError}
						onClose={() => setOpenConfirmationError(false)}
						autoHideDuration={5000}
					>
						<Alert severity="error">Algo salio mal, vuelve a intentarlo</Alert>
					</Snackbar>

					<Modal open={openModal} close={handleModalProducts} type='addProduct' data={data ? data : ''} success={dataSuccessAddData} />
					<Modal open={openModalRemove} close={handleModalRemove} type='remove' data={itemRemove} success={dataSuccessRemoved} />
					<Modal open={openModalEdit} close={handleModalEdit} type='edit' data={itemEdit} success={dataSuccessEdit} categories={data ? data : ''} />

					<Modal open={openModalDecremento} close={handleModalDecremento} type='decremento' data={data ? data.proveedores : ''} idProduct={itemIngreso} medidas={data ? data.unidadesMedidas : ''} success={dataSuccessDecremento} />

					<Modal open={openModalIngreso} close={handleModalIngreso} type='ingreso' data={data ? data.proveedores : ''} idProduct={itemIngreso} medidas={data ? data.unidadesMedidas : ''} success={dataSuccessEditIngreso} />

					<Modal open={openModalMassive} close={handleModalProductsMassive} type='addProductMassive' data={data ? data.proveedores : ''} idProduct={itemIngreso} medidas={data ? data.unidadesMedidas : ''} success={dataSuccessProductsMassive} />

					<TopBar logout={handleLogout} />
					<div className='container'>
						<aside>
							<SideBar logout={handleLogout} />
						</aside>
						<Grid
							container
							spacing={2}
							sx={{ p: 4 }}
						>
							<Grid
								item
								xs={12}
								sx={{ marginLeft: '0px' }}
							>
								<Typography className='titleColor' variant="h4" gutterBottom component="div">
									Inventario
								</Typography>
								<Typography className='textColor' variant="subtitle1" gutterBottom component="div">
									Aqui se enlista el inventario
								</Typography>

								<div className='topBotton'>

									<Box sx={{ '& > :not(style)': { m: 1 } }}>
										<Button
											startIcon={<AddIcon />}
											variant="outlined"
											onClick={handleModalProducts}
										>
											Producto
										</Button>
										<Button
											startIcon={<AddIcon />}
											variant="outlined"
											onClick={handleModalProductsMassive}
										>
											Carga masiva
										</Button>
									</Box>

										{/*<ButtonGroup variant="outlined" aria-label="outlined button group">
											<Box sx={{
												display: 'flex',
												'& > *': {
													m: 1,
												},}}
											>
												<Button
													startIcon={<AddIcon />}
													variant="outlined"
													onClick={handleModalProducts}
												>
													Producto
												</Button>
												&nbsp;&nbsp;&nbsp;
												<Button
													startIcon={<AddIcon />}
													variant="outlined"
													onClick={handleModalProductsMassive}
												>
													Producto Masivo
												</Button>
											</Box>
										</ButtonGroup>*/}
								</div>

								{
									userRol() !== '2' ?
										<div>
											<Button
												variant="text"
												onClick={handleGoToReport}
											>Reporte Entradas - Salidas</Button>
										</div>
										: null

								}

								{
									loading ?
										<Loading />
										:
										!error ?
											<>
												{/* <Grid item sx={{ backgroundColor: 'white', p: 2, mt: 4 }} >
													<div className="search">
														<TextField
															id="outlined-basic"
															variant="outlined"
															fullWidth
															placeholder='Busca un producto'
															value={barsearch}
															onChange={e => setBarsearch(e.target.value)}
															InputProps={{
																endAdornment:
																	<InputAdornment position="end">
																		<SearchIcon
																			aria-label="toggle password visibility"
																			edge="end"
																			style={{ color: '#303f9f' }}
																		/>

																	</InputAdornment>
															}}
														/>
													</div>
												</Grid> */}

												<Paper elevation={1}>
													<div className='wraperTable' >
														<DataGrid
															autoHeight={true}
															disableColumnMenu
															rows={addDataRow}
															columns={columns}
															pageSize={pageSize}
															onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
															rowsPerPageOptions={[10, 20, 50, 100]}
															// onRowClick={onClickRow}
															hideFooterSelectedRowCount
															disableSelectionOnClick
															localeText={esES.components.MuiDataGrid.defaultProps.localeText}
															sortModel={sortModel}
															onSortModelChange={(model) => setSortModel(model)}
															components={{
																Toolbar: CustomToolbar,
															}}
															// filterMode="server"
															// onFilterModelChange={onFilterChange}
															// loading={isLoading}
														// filterModel={{
														//     items: [
														//         { id: 5, columnField: 'name', operatorValue: 'contains', value: barsearch },
														//         { id: 1, columnField: 'sku', operatorValue: 'contains', value: barsearch }
														//     ],
														// }}
														/>



													</div>

												</Paper>
											</>
											:
											{ error }
								}
							</Grid>
						</Grid>

					</div>


				</>

				: <Navigate to='/login' />


	)

}

export default Products