import { useState, useEffect } from "react"
import { helpHttp } from "../Helpers/helpHttp";

export const useGetSalesP = ( url, token, upload, isweeklysale ) => {

	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const uriApi = process.env.REACT_APP_URI_API
	const endPoint = isweeklysale ? `${uriApi}ventas?tipo=5&isweeklysale=1&table=1` : `${uriApi}ventas?tipo=1&isweeklysale=0`;

	// useEffect(() => {
	//     const getDataUserList = async () => {
	//         setLoading(true)
	//         try {

	//             let res = await fetch(`${url}users`, {
	//                 method: 'GET',
	//                 headers: {
	//                     'Accept': 'application/json',
	//                     'Content-Type': 'application/json',
	//                     'Authorization': 'Bearer ' + token
	//                 }
	//             })

	//             if (!res.ok) {

	//                 const e = {
	//                     err: true,
	//                     status: res.status,
	//                     statusText: !res.statusText ? 'Ocurrio un error' : res.statusText
	//                 }

	//                 throw e

	//             }

	//             let data = await res.json()

	//             setData(data)
	//             setError({ err: false })
	//             setLoading(false)


	//         } catch (err) {
	//             setLoading(false)
	//             setError(err)
	//             console.log(err)
	//         }
	//     }

	//     getDataUserList()

	// }, [url, token, upload]);

	useEffect(() => {
		// set a clean up flag
		let isSubscribed = true;


		if (isSubscribed) {

			setLoading(true)
			// console.log( endPoint );
			helpHttp().get(endPoint, {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: 'Bearer ' + token
				}
			}).then((res) => {
				console.log(res);

				if (res.body.status) {
					console.log("abort" + res)
				}


				if (res.body.status === 'Token is Expired') {
					// console.log(res)
					setData(res)
					setError(true)
					setLoading(false)
				} else {
					// console.log(res)

					if (res.body.ventas) {
						res.body.ventas.map((v, i) =>
							v.consumo ? v.consumo = v.consumo.replace(',', '') : null
						)
						setData(res)
						setError(false)
						setLoading(false)
					}


				}


			})
		}

		// cancel subscription to useEffect
		return () => (isSubscribed = false)
	}, [url, token, endPoint, upload])


	return { data, error, loading, upload };



}