import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import AddProduct from './Form/AddProduct';
import Removed from './Form/Removed'
import EditProduct from './Form/EditProduct';
import ChangeStatus from './Form/ChangeStatus'
import UserForm from './Form/UserForm'
import IngresoInventario from './Form/IngresoInventario'
import DecrementarInventario from './Form/DecrementarInventario';
import AddProductMassive from './Form/AddProductMassive';
import ChangeWeek from './Form/ChangeWeek';

const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="up" ref={ref} {...props} />;
});

const Modal = ({ open, close, type, data, success, categories, action, idProduct, medidas, dataform }) => {
		// console.log(type)
		const actionModal = {
				'addUser': 'Agrega un nuevo usuario',
				'delete': 'Elimina un usuario',
				'edit': 'Edita el usuario',
		}

		const forms = {
				'addProduct': <AddProduct close={close} title='Agregar un producto' data={data} success={success}/>,
				'remove': <Removed close={close} title='¿Deseas Eliminar?' data={data} success={success} />,
				'edit': <EditProduct close={close} title='Editar producto' data={data} success={success} categories={categories} />,
				'changeStatus': <ChangeStatus close={close} title='Cambio de status' data={data} success={success} />,
				'user': <UserForm close={close} title={actionModal[action]} data={data} action={action} success={success} dataform={dataform}/>,
				'ingreso': <IngresoInventario close={close} title='Ingreso de Inventario' data={data} idProduct={idProduct} medidas={medidas} success={success
				} />,
				'decremento': <DecrementarInventario close={close} title='Ingreso de Inventario' data={data} idProduct={idProduct} medidas={medidas} success={success
				} />,
				'addProductMassive': <AddProductMassive close={close} title='Ingreso de Inventario Masivo' data={data} idProduct={idProduct} medidas={medidas} success={success
				} />,
				'changeWeek': <ChangeWeek close={close} title='Cambio de semana' data={data} success={success} />,
		}

		const getForms = (type) => {
				return forms[type]
		}


		return (
				<>
						<Dialog
								open={open}
								onClose={close}
								TransitionComponent={Transition}
								maxWidth='md'
								fullWidth
						>

								{getForms(type)}



						</Dialog>
				</>
		)
}


export default Modal