
//MUI
import CloseIcon from '@mui/icons-material/Close';

import DialogTitle from '@mui/material/DialogTitle';



//Icons
import IconButton from '@mui/material/IconButton';


//Component
import AddUser from './FormUser/AddUser'
import EditUser from './FormUser/EditUser';
import DeleteUser from './FormUser/DeleteUser';




const UserForm = ({ title, close, data, action, success, dataform }) => {

	const getFormUser = () => {
		const actionUser = {
			'addUser': <AddUser close={close} success={success} dataform={dataform}/>,
			'delete': <DeleteUser close={close} success={success} data={data} />,
			'edit': <EditUser close={close} success={success} data={data} dataform={dataform}/>
		}

		return actionUser[action]
	}
	return (
		<>
			<DialogTitle>
				{title}
				<IconButton
					aria-label="close"
					onClick={close}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: '#989ba1',
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			{getFormUser()}
		</>

	)

}

export default UserForm