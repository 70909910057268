import { useState } from 'react';

import { useNavigate } from 'react-router-dom'

//MUI
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { DataGrid, esES, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';

//Icon
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InfoIcon from '@mui/icons-material/Info';
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';

//Hooks
import { useAuth } from './../Hooks/useAuth'
import { useGetDataChartP } from '../Hooks/useGetDataChartP'

import Chart from 'react-apexcharts'

//Component
import Loading from './../Components/Loading'
import Modal from './../Components/Modal'
import { useGetSalesP } from '../Hooks/useGetSalesP';

const getChipProps = (params) => {
	if (params.value === "Abierto") {
		return {
			icon: <InfoIcon style={{ fill: "#9c27b0" }} />,
			label: params.value,
			style: {
				borderColor: "#9c27b0"
			}
		};
	} else if (params.value === "Pagado") {
		return {
			icon: <InfoIcon style={{ fill: "#2196f3" }} />,
			label: params.value,
			style: {
				borderColor: "#2196f3"
			}
		};
	}
	else if (params.value === "Entregado") {
		return {
			icon: <InfoIcon style={{ fill: "#4caf50" }} />,
			label: params.value,
			style: {
				borderColor: "#4caf50"
			}
		};
	} else if (params.value === "Entragado") {
		return {
			icon: <InfoIcon style={{ fill: "#4caf50" }} />,
			label: params.value,
			style: {
				borderColor: "#4caf50"
			}
		};
	} else if (params.value === "Cancelado") {
		return {
			icon: <InfoIcon style={{ fill: '#E74C3C' }} />,
			label: params.value,
			style: {
				borderColor: '#E74C3C'
			}
		};
	}
}

const formatNumberDecimal = ( number ) => {
	number = ( Math.round( number * 100 ) / 100 ).toFixed( 2 );
	return Number( number ).toLocaleString( 'en-US' );
}

const ListSalesProyect = ({ token, deleteToken, isweeklysale }) => {
	const navigate = useNavigate();
	const { userRol } = useAuth()
	const uriApi = process.env.REACT_APP_URI_API
	const [upload, setUpload] = useState(0)
	const [pageSize, setPageSize] = useState(10);
	const [openModalRemove, setOpenModalRemove] = useState(false)
	const { data, error, loading } = useGetSalesP(uriApi, token, upload, isweeklysale)
	const [openModalChangeStatus, setOpenModalChnageStatus] = useState(false)
	const [dataChangeStatus, setDataChangeStatus] = useState()
	const [ dataChangeWeek, setDataChangeWeek ] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [openConfirmation, setOpenConfirmation] = useState(false)
	const [errorD, setErrorD] = useState(false)
	const [itemRemove, setItemRemove] = useState()
	const [openModalWeek, setOpenModalWeek] = useState( false );

	const [sortModel, setSortModel] = useState([
		{ field: "fecha", sort: "desc" }
	]);

	const { data: dataChart, loadingd } = useGetDataChartP(uriApi, token, upload, isweeklysale)

	const logout = () => {
		// console.log('redirect')
		setTimeout(() => {
			deleteToken()
		}, 1000);
	}

	const hanleModalChangeStatus = (v) => {
		// console.log(v.row)
		setDataChangeStatus(v.row)
		setOpenModalChnageStatus(!openModalChangeStatus)
	}

	const hanleModalChangeWeek = ( v ) => {
		// console.log( v.row );
		setDataChangeWeek( v.row );
		setOpenModalWeek( !openModalWeek );
	}

	const hanleChangeSale = () => {
		setOpenModalChnageStatus(!openModalChangeStatus)
	}

	const hanleDeleteSale = (value) => {
		// console.log(value.row)
		if (value.row) {
			setItemRemove(value.row)
			setOpenModalRemove(!openModalRemove)
		} else {
			setOpenModalRemove(!openModalRemove)
		}
	}

	const dataSuccessRemoved = (v) => {
		// console.log(v)
		// setOpenModalAddRemove(!openModalRemove)
		// deleteItem(v)
		setIsLoading(true)
		setOpenModalRemove(false)
		fetch(`${uriApi}ventas/${v}`, {
			method: 'DELETE',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token

			}
		})
			.then(response => response.json())
			.then((response) => {
				setIsLoading(false)

				console.log(response.msg)
				setUpload(upload + 1)

				setOpenConfirmation(true)

			})
			.catch((err) => console.log(err))
			.finally(() => console.log('finally'));
	}


	const handlePdf = (value) => {

		setIsLoading(true)

		const showFile = (blob) => {

			var newBlob = new Blob([blob], { type: "application/pdf" })

			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}

			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement('a');
			link.href = data;
			link.download = `venta-${value.row.id}.pdf`;
			link.click();
			setTimeout(function () {
				// For Firefox it is necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 400);
		}

		fetch(`${uriApi}ventas/${value.row.id}?pdf=1&prod=1` + ( isweeklysale ? '&isweeklysale=1' : '' ), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer ' + token
			}
		}).then(r => r.blob())
			.then((blob) => {
				setIsLoading(false)
				showFile(blob)
			}).catch(error => console.log(error));

	}

	const dataSuccessChangeStatus = (v, id) => {
		// console.log(v)
		//console.log(id)
		setIsLoading(true)
		setOpenModalChnageStatus(false)
		fetch(`${uriApi}ventas/${id}`, {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token

			},
			body: JSON.stringify({ "status": v })
		})
			.then(response => response.json())
			.then((response) => {

				setIsLoading(false)
				console.log(response)

				setUpload(upload + 1)
				setOpenConfirmation(true)

			})
			.catch((err) => setErrorD(err))
			.finally(() => console.log('finally'));
	}

	const dataSuccessWeek = ( v, id, pass ) => {
		if( pass == 123456 ){
			console.log( v );
			console.log( id );
			console.log( pass );

			setIsLoading( true );
			setOpenModalWeek( false );
			fetch(`${uriApi}ventas/${id}`, {
				method: 'PUT',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token

				},
				body: JSON.stringify({ 'week': v })
			})
				.then(response => response.json())
				.then((response) => {

					setIsLoading(false)
					console.log(response)

					setUpload(upload + 1)
					setOpenConfirmation(true)

				})
				.catch((err) => setErrorD(err))
				.finally(() => console.log('finally'));

		}
	}

	const columns = [
		// { field: 'id', headerName: 'Id', width: 80 },
		{
			field: 'cliente',
			headerName: isweeklysale ? 'Semana' : 'Proyecto',
			flex: 1,
			minWidth: 220,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
		},
		{
			field: 'fecha',
			headerName: 'Fecha',
			width: 110,
			headerAlign: 'center',
			headerClassName: 'title-app-theme-header',
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			headerAlign: 'center',
			headerClassName: 'title-app-theme-header',
			renderCell: (params) => {
				return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
			}
		},
		{
			field: 'fechaentrega',
			headerName: 'Fecha entrega',
			width: 145,
			headerAlign: 'center',
			headerClassName: 'title-app-theme-header',
			// renderCell: (params) => {
			//     const value = params.value ? params.value : <Typography align="center" display="block"> aún no se entrega</Typography>;
			//     return <Typography align="center" display="block"> {value}</Typography>;
			// }
			renderCell: (params) => {
				const value = params.value ? params.value : <span> aún no se entrega</span>;
				return <span>{value}</span>;
			}
		},
		{
			field: 'consumo',
			headerName: 'Consumo',
			width: 115,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
			renderCell: (params) => {
				const v = params.value.replace(',', '');
				//const newV = parseInt(v)
				// return params.value.replace(',', '');
				return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(params.value);
			},
			type: "number",
		},
		{
			field: 'total',
			headerName: 'Total',
			width: 110,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
			renderCell: (params) => {
				return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(params.value);

			},
			type: "number",
		},
		{
			field: 'balance',
			headerName: 'Balance',
			width: 110,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
			renderCell: (params) => {
				return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(params.value);
			},
			type: "number",
		},
		{
			field: 'acciones',
			headerName: 'Acciones',
			width: 120,
			headerAlign: 'center',
			align: 'right',
			headerClassName: 'title-app-theme-header',
			sortable: false,
			disableClickEventBubbling: true,
			renderCell: (cellValues) => {
				return (
					<>
						{ isweeklysale ?
							<>
								<Tooltip title="Cambiar semana" placement="top" arrow>
									<CalendarTodayTwoToneIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
										hanleModalChangeWeek(cellValues)
									}} />
								</Tooltip>
								<Tooltip title="Editar" placement="top" arrow>
									<EditIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
										navigate( '/venta-semanal/create/', { state: { edit: true, id: cellValues.id } } )
										if( localStorage.getItem( 'vtapro' ) !== null ){
											// console.log( 'vtapro' );
											localStorage.removeItem( 'vtapro' );
										}
									}} />
								</Tooltip>
							</>
						: <>
								<Tooltip title="Editar proyecto" placement="top" arrow>
									<EditIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
										navigate('/venta-proyecto/create/', { state: { edit: true, id: cellValues.id } })
										if( localStorage.getItem( 'vtapro' ) !== null ){
											// console.log( 'vtapro' );
											localStorage.removeItem( 'vtapro' );
										}
									}} />
								</Tooltip>
						</>}

						<Tooltip title="Comanda de operación" placement="top" arrow>
							<AssignmentIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
								handlePdf(cellValues)
							}} />
						</Tooltip>
						<Tooltip title="Cambiar estatus" placement="top" arrow>
							<ChangeCircleIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
								hanleModalChangeStatus(cellValues)
							}} />
						</Tooltip>
						<Tooltip title="Eliminar proyecto" placement="top" arrow>
							<DeleteIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
								hanleDeleteSale(cellValues)
							}} />
						</Tooltip>

					</>

				)
			}
		}
	];

	const columnsUserO = [
		// { field: 'id', headerName: 'Id', width: 90 },

		{
			field: 'cliente',
			headerName: 'Cliente',
			flex: 1,
			minWidth: 220,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
		},
		{
			field: 'fecha',
			headerName: 'Fecha',
			width: 150,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
			renderCell: (params) => {
				return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
			}

		},
		{
			field: 'fechaentrega',
			headerName: 'Fecha entrega',
			flex: 1,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
			renderCell: (params) => {
				const value = params.value ? params.value : <Typography align="center" variant="body2" display="block"> aún no se entrega</Typography>;
				return <Typography align="center" variant="body2" display="block"> {value}</Typography>;
			}
		},
		{
			field: 'acciones',
			headerName: 'Acciones',
			sortable: false,
			headerAlign: 'center',
			align: 'right',
			headerClassName: 'title-app-theme-header',
			disableClickEventBubbling: true,
			renderCell: (cellValues) => {
				return (
					<>
						{ cellValues.row.idVentastatus == 1 ?
							<>
								{ isweeklysale ?
									<Tooltip title="Editar semana" placement="top" arrow>
										<EditIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
											navigate( '/venta-semanal/create/', { state: { edit: true, id: cellValues.id } })
										}} />
									</Tooltip>
								:
									<Tooltip title="Editar proyecto" placement="top" arrow>
										<EditIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
											navigate( '/venta-proyecto/create/', { state: { edit: true, id: cellValues.id } })
										}} />
									</Tooltip>
								}
							</>
						: '' }
						{/*{ cellValues.row.idVentastatus == 1 ?
							<Tooltip title="Editar proyecto" placement="top" arrow>
								<EditIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
									navigate('/venta-proyecto/create/', { state: { edit: true, id: cellValues.id } })
								}} />
							</Tooltip>
							: '' }*/}
						{/*<Tooltip title="Cambiar estatus" placement="top" arrow>
							<ChangeCircleIcon sx={{ fontSize: 20 }} className='iconActions' onClick={() => {
								hanleModalChangeStatus(cellValues)
							}} />
						</Tooltip>*/}
					</>
				)
			}
		}
	];

	const CustomToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarExport />
			</GridToolbarContainer>
		);
	}

	const ColorButton = styled(Button)(() => ({
		color: '#303f9f',
		border: '1px solid #303f9f',

		'&:hover': {
			backgroundColor: '#303f9f',
			color: '#fff',
			border: '1px solid #303f9f'
		},
	}));

	return (
		<>
			<Grid
				item
				xs={12}
				sx={{ marginLeft: '0px' }}
			>
				{
					isLoading
						? <Loading />
						:
						loading
							? <Loading />

							: error !== null
								?
								error
									?
									<Stack sx={{ width: '100%' }} spacing={2}>
										<Alert severity="error">
											<AlertTitle>Error</AlertTitle>
											{error.statusText} — <strong>{data.body.status}</strong>
											{data.body.status === 'Token is Expired' ? logout() : null}
											{error.status === 401 ? logout() : null}
										</Alert>

									</Stack>
									:
									!data
										? null

										: <>
											<Snackbar
												anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
												open={openConfirmation}
												onClose={() => setOpenConfirmation(false)}
												autoHideDuration={6000}

											>
												<Alert severity={errorD ? 'error' : 'success'}> {errorD ? 'Algo paso, intenta nuevamente ' : 'Se ejecuto correctamente'}</Alert>
											</Snackbar>
											<Modal open={openModalRemove} close={hanleDeleteSale} type='remove' data={itemRemove} success={dataSuccessRemoved} />
											<Modal open={openModalChangeStatus} close={hanleChangeSale} type='changeStatus' data={dataChangeStatus} success={dataSuccessChangeStatus} />

											<Modal open={openModalWeek} close={hanleModalChangeWeek} type='changeWeek' data={dataChangeWeek} success={dataSuccessWeek} />

											<Typography className='titleColor' variant="h4" gutterBottom component="div">
												{ isweeklysale == 1 ? "Consumo Semanal" : "Venta Proyectos" }
											</Typography>
											<Typography className='textColor' variant="subtitle1" gutterBottom component="div">
												{ isweeklysale == 1 ? "Listado de ventas a traves de semanas" : "Listado de ventas a traves de proyectos" }
											</Typography>

											{
												userRol() !== '1'
													? null
													:
													<>
														<div className='topBotton'>
															{ isweeklysale == 1 ?
																<>
																	<ColorButton
																		startIcon={<AddIcon />}
																		variant="outlined"
																		onClick={() => navigate('/venta-semanal/create')}
																	>
																		Crear semana
																	</ColorButton>
																</>
															: <>
																	<ColorButton
																		startIcon={<AddIcon />}
																		variant="outlined"
																		onClick={() => navigate('/venta-proyecto/create')}
																	>
																		Crear Proyecto
																	</ColorButton>
															</> }
														</div>

														{ isweeklysale == 1 ?
															<Grid container>
																<Grid item sx={{ mb: 4, p: 2 }} xs={12} md={12}>
																	{
																		loadingd ? <Loading />
																		: dataChart
																		? <Paper elevation={1}>
																				<Chart options={dataChart.options} series={dataChart.seriesC} type="bar" height={320} />
																			</Paper >
																		: null
																	}
																</Grid>
																{/*

																*/}
															</Grid>
														: <>
																<Grid container>
																	<Grid item sx={{ mb: 4, p: 2 }} xs={12} md={6}>
																		{
																			loadingd ? <Loading />
																			: dataChart
																			? <Paper elevation={1}>
																					<Chart options={dataChart.options} series={dataChart.seriesC} type="bar" height={320} />
																				</Paper >
																			: null
																		}
																	</Grid>
																	<Grid item sx={{ mb: 4, p: 2 }} xs={12} md={6}>
																		{
																			loadingd ? <Loading />
																			: dataChart
																			? <div class="titleBox">
																					<Typography className='titleColorTxt' variant="h4" gutterBottom component="div" align="center">
																						Valor total de inventarios :
																					</Typography >
																					<Typography className='titleColorTxt' variant="h1" gutterBottom component="div" align="center">
																						${formatNumberDecimal( dataChart.total )}
																					</Typography >
																				</div>
																			: null
																		}
																	</Grid>
																</Grid>
															</> }
													</>
											}

											<Paper elevation={1}>
												<div className='wraperTable' >
													<DataGrid
														autoHeight={true}
														disableColumnMenu
														rows={data.body ? data.body.ventas : null}
														columns={userRol() !== '1' ? columnsUserO : columns}
														pageSize={pageSize}
														onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
														rowsPerPageOptions={[10, 20, 50, 100]}
														sortModel={sortModel}
														onSortModelChange={(model) => setSortModel(model)}
														hideFooterSelectedRowCount
														disableSelectionOnClick
														localeText={esES.components.MuiDataGrid.defaultProps.localeText}
														components={{
															Toolbar: CustomToolbar,
														}}
													/>
												</div>
											</Paper>
										</>

								: ''

				}

			</Grid>
		</>

	)
}


export default ListSalesProyect