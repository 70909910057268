import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

//Material UI
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { indigo } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

//Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SendIcon from '@mui/icons-material/Send';

//Hooks
import { useAuth } from '../../Hooks/useAuth'
import { useGetProducts } from '../../Hooks/useGetProducts'
//  import { useMsgInventory } from '../../Hooks/useMsgInventory';

//Components
import SideBar from '../../Components/SideBar';
import Loading from '../../Components/Loading'
import TopBar from '../../Components/TopBar'
import Areas from '../../Components/Areas'
import MessageInventory from '../../Components/MessageInventory';

//Styles
import './styles.css'

const ColorButton = styled(Button)(() => ({
	color: '#303f9f',
	border: '1px solid #303f9f',

	'&:hover': {
		backgroundColor: '#303f9f',
		color: '#fff',
		border: '1px solid #303f9f'
	},
}));

const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: indigo[500],
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: indigo[500],
	},
	'& .MuiOutlinedInput-root': {
		color: indigo[700],

		'&:hover fieldset': {
			borderColor: indigo[500],
		},
		'&.Mui-focused fieldset': {
			borderColor: indigo[500],
		},
	},
});

const Sale = () => {

	const [productsSinInventario, setProductsSinInventario] = useState([])
	const [subProductsSinInventario, setSubProductsSinInventario] = useState([{ item: [{ item: '', subitem: '' }] }])

	const { token, deleteToken } = useAuth()
	const uriApi = process.env.REACT_APP_URI_API
	const { data, loading } = useGetProducts(uriApi, token)
	// console.log(data)

	if (data) {
		if (data.status) {
			if (data.status === 'Token is Expired' || data.status === 'Token is Invalid') {
				console.log('expiro')
				deleteToken()
			}
		}
	}

	const [autocomplete, setAutocomplete] = useState([{ name: '' }])
	const [totalPrice, setTotalPrice] = useState(0)
	const [cliente, setCliente] = useState('')
	const [areas, setAreas] = useState(null)
	const [factura, setFactura] = useState(false)
	const [saleLoading, setSaleLoading] = useState(false)



	const [dataForConsult, setDataForConsult] = useState(null)
	// const [deleteItemForCount, setDeleteItemForCount] = useState(null)


	const [itemsProducts, setItemProducts] = useState([
		{ uuid: uuidv4(), type: '', itemMateriales: [{ uuid: uuidv4(), itemProducto: '', cantidadP: '', medida: '', descripcion: '', cantidad: '', precio: '' }] }
	])



	const handleLogout = () => {
		deleteToken()
	}

	const handleAddItemProduct = () => {

		const newA = [...autocomplete]
		newA.push({ name: '' })
		setAutocomplete(newA)

		const itemTemp = [...itemsProducts]
		itemTemp.push({ uuid: uuidv4(), type: '', itemMateriales: [{ uuid: uuidv4(), itemProducto: '', cantidadP: '', medida: '', descripcion: '', cantidad: '', precio: '' }] })
		setItemProducts(itemTemp)
	}



	const handleDeleteItem = (i, v) => {
		console.log(i)
		console.log(v)
		const idItems = i

		if (v.itemMateriales.length > 1) {


			v.itemMateriales.forEach((e, i) => {
				console.log(e.id)
				const array_temp = [...itemsProducts]
				const id = array_temp[idItems].itemMateriales[i].id
				array_temp[idItems].itemMateriales[i].cantidadP = ''
				const cnt = array_temp[idItems].itemMateriales[i].cantidadP

				console.log(id)
				console.log(cnt)

				setTimeout(() => {
					setDataForConsult({ id, cnt })
				}, 100);
				// setItemProducts(array_temp)
				// setDataForConsult({ id, cnt })
			})

		} else {

			const array_temp = [...itemsProducts]
			const id = array_temp[i].itemMateriales[0].id
			const cnt = array_temp[i].itemMateriales[0].cantidadP = 0
			setItemProducts(array_temp)
			setDataForConsult({ id, cnt })
		}

		setTimeout(() => {
			const itemsRowTemp = [...itemsProducts]
			itemsRowTemp.splice(i, 1)
			setItemProducts(itemsRowTemp)

			const newA = [...autocomplete]
			newA.splice(i, 1)
			setAutocomplete(newA)

			const itemsRowTemp2 = [...subProductsSinInventario]
			itemsRowTemp2.splice(i, 1)
			setSubProductsSinInventario(itemsRowTemp2)

			let sum = 0
			itemsRowTemp.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {

					sum += (parseInt(elemento.cantidad) * parseInt(elemento.precio))

				})
			})
			// console.log(sum)
			setTotalPrice(sum)

			const array_temp = [...productsSinInventario]
			array_temp.splice(i, 1)
			setProductsSinInventario(array_temp)
		}, 200);

	}

	const handleChange = (i, e) => {
		const { name, value } = e.target


		// console.log(name)
		let newFormValues = [...itemsProducts]
		// newFormValues[i].itemMateriales[0][name] = value
		setItemProducts(newFormValues)

		if (name === 'precio') {
			newFormValues[i].itemMateriales[0][name] = value
			let sum = 0
			newFormValues.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {

					sum += ((elemento.cantidad) * (elemento.precio))

				})
			})
			// console.log(sum)
			setTotalPrice(sum)

		} else if (name === 'cantidad') {
			newFormValues[i].itemMateriales[0][name] = value
			let sum = 0
			newFormValues.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {

					sum += ((elemento.cantidad !== '' ? elemento.cantidad : 0) * (elemento.precio))

				})
			})
			// console.log(sum)
			setTotalPrice(sum)

		} else if (name === 'cantidadP') {
			newFormValues[i].itemMateriales[0][name] = value
			// console.log('consultar si hay cantidad suficiente en el index: ' + i + ' id: ' + newFormValues[i].itemMateriales[0].id)

			let indexToSearch = newFormValues[i].itemMateriales[0].id
			setDataForConsult({ id: indexToSearch, cnt: value })



			let arrayFind = data.productos.filter(e => e.id === indexToSearch)

			// const nameProductSearch = arrayFind[0].producto


			let sum = 0
			newFormValues.forEach(ele => {
				ele.itemMateriales.forEach((elemento, index) => {
					if (elemento.id === indexToSearch) {
						sum += elemento.cantidadP === '' ? 0 : parseInt(elemento.cantidadP)

						if (parseInt(sum) > parseInt(arrayFind[0].cantidad)) {



							const array_temp = [...productsSinInventario]
							const findIndex = array_temp.findIndex(e => e.id === elemento.id)


							if (findIndex !== -1) {

								array_temp[findIndex] = { id: arrayFind[0].id, 'name': arrayFind[0].producto, 'proveedor': arrayFind[0].proveedor, 'almacen': parseInt(arrayFind[0].cantidad), 'solicitar': (parseInt(sum) - parseInt(arrayFind[0].cantidad)) }

								setProductsSinInventario(array_temp)

							} else {

								array_temp.push({ id: arrayFind[0].id, 'name': arrayFind[0].producto, 'proveedor': arrayFind[0].proveedor, 'almacen': parseInt(arrayFind[0].cantidad), 'solicitar': (parseInt(sum) - parseInt(arrayFind[0].cantidad)) })

								setProductsSinInventario(array_temp)
							}

						} else {


							const array_temp = [...productsSinInventario]
							const findIndex = array_temp.findIndex(e => e.id === indexToSearch)

							if (findIndex !== -1) {
								console.log(findIndex)
								console.log(productsSinInventario.splice(findIndex, 1))

							}
						}
					}
				})
			})


		} else {
			newFormValues[i].itemMateriales[0][name] = value
		}


	}

	const handleChangeAutoComplete = (e, v, i) => {
		// console.log(e)
		// console.log(v)
		// console.log(i)
		// console.log(v !== null ? `${v.producto}  ${v.tipo} ` : '')

		if (v !== null) {
			if (v.tipo === 'Servicio') {
				const newA = [...itemsProducts]
				// newA[i].itemMateriales = [{ uuid: uuidv4(), itemProducto: '', cantidadP: '', medida: '', descripcion: '', cantidad: '', precio: '' }]
				newA[i].type = 1
				newA[i].name = v.producto

				setItemProducts(newA)
				// console.log(JSON.stringify(itemsProducts))

				const newA2 = [...autocomplete]
				newA2[i].name = v.producto
				setAutocomplete(newA2)
			} else {

				const newA1 = [...itemsProducts]
				newA1[i].itemMateriales = [{ id: parseInt(v.id), itemProducto: v.producto, cantidadP: '', medida: v.unidadmedida, descripcion: '', cantidad: '', precio: '' }]
				newA1[i].type = 2

				setItemProducts(newA1)

				const newA = [...autocomplete]
				newA[i].name = v.producto
				setAutocomplete(newA)
			}

		} else {

			const newA1 = [...itemsProducts]
			newA1[i].type = ''
			newA1[i].name = ''
			newA1[i].itemMateriales = [{ itemProducto: '', cantidadP: '', medida: '', descripcion: '', cantidad: '', precio: '' }]
			setItemProducts(newA1)
			console.log(itemsProducts)


			const newA = [...autocomplete]
			newA[i].name = ''
			setAutocomplete(newA)
		}

	}

	const handleChangeAutoCompleteSubProduct = (e, v, i, index) => {
		// console.log(e)
		// console.log(v)
		// console.log("item:" + i + " subItem:" + index)

		if (v !== null) {
			const newA = [...itemsProducts]
			newA[i].itemMateriales[index].id = v.id
			newA[i].itemMateriales[index].itemProducto = v.producto
			newA[i].itemMateriales[index].medida = v.unidadmedida
			setItemProducts(newA)

		} else {

			console.log("limpiaste input")

			const newA = [...itemsProducts]

			newA[i].itemMateriales[index].itemProducto = ''
			newA[i].itemMateriales[index].cantidadP = ''
			newA[i].itemMateriales[index].medida = ''
			newA[i].itemMateriales[index].descripcion = ''
			newA[i].itemMateriales[index].cantidad = ''
			newA[i].itemMateriales[index].precio = ''
			setItemProducts(newA)

			let sum = 0
			newA.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {

					if (elemento.precio) {
						sum += (parseInt(elemento.cantidad) * parseInt(elemento.precio))
						console.log(elemento.cantidad * elemento.precio)
						// console.log(sum)
						// setTotalPrice(totalPrice + sum)
					}

					if (elemento.cant) {
						sum += (parseInt(elemento.cantidad) * parseInt(elemento.precio))
						console.log(elemento.cantidad * elemento.precio)
						// console.log(sum)
						// setTotalPrice(totalPrice + sum)
					}

				})
			})
			console.log(sum)
			setTotalPrice(sum)

		}


	}

	const handleChangeSubProduct = (e, i, index) => {
		const { name, value } = e.target

		let newFormValues = [...itemsProducts]
		newFormValues[i].itemMateriales[index][name] = value
		setItemProducts(newFormValues)

		if (name === 'precio') {
			let sum = 0
			newFormValues.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {

					sum += ((elemento.cantidad) * (elemento.precio))

					// console.log(sum)
					// setTotalPrice(totalPrice + sum)
				})
			})

			setTotalPrice(sum)
		}

		if (name === 'cantidad') {
			let sum = 0
			newFormValues.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {

					sum += ((elemento.cantidad) * (elemento.precio))

				})
			})

			setTotalPrice(sum)
		}


		if (name === 'cantidadP') {
			newFormValues[i].itemMateriales[index][name] = value
			// / console.log('consultar si hay cantidad suficiente en el index: ' + index + ' id: ' + newFormValues[i].itemMateriales[index].id)
			let indexToSearch = newFormValues[i].itemMateriales[index].id
			// let arrayFind = data.productos.filter(e => e.id === indexToSearch)

			setDataForConsult({ id: indexToSearch, cnt: value })


			// let sum = 0
			// newFormValues.forEach(ele => {
			//     ele.itemMateriales.forEach((elemento, index) => {
			//         if (elemento.id === indexToSearch) {
			//             sum += elemento.cantidadP === '' ? 0 : parseInt(elemento.cantidadP)
			//             console.log('se encontro id')

			//             if (parseInt(sum) > parseInt(arrayFind[0].cantidad)) {

			//                 console.log('es mayor la cantidad a inventario')

			//                 const array_temp = [...productsSinInventario]
			//                 const findIndex = array_temp.findIndex(e => e.id === elemento.id)
			//                 console.log(findIndex)

			//                 if (findIndex !== -1) {
			//                     console.log('modificar elemento')
			//                     array_temp[findIndex] = { id: arrayFind[0].id, 'name': arrayFind[0].producto, 'proveedor': arrayFind[0].proveedor, 'almacen': parseInt(arrayFind[0].cantidad), 'solicitar': (parseInt(sum) - parseInt(arrayFind[0].cantidad)) }

			//                     setProductsSinInventario(array_temp)

			//                 } else {
			//                     console.log('agregar elemento')
			//                     array_temp.push({ id: arrayFind[0].id, 'name': arrayFind[0].producto, 'proveedor': arrayFind[0].proveedor, 'almacen': parseInt(arrayFind[0].cantidad), 'solicitar': (parseInt(sum) - parseInt(arrayFind[0].cantidad)) })

			//                     setProductsSinInventario(array_temp)
			//                 }

			//             } else {
			//                 console.log('no es mayor a inventario')
			//                 const array_temp = [...productsSinInventario]
			//                 const findIndex = array_temp.findIndex(e => e.id === elemento.id)
			//                 console.log(findIndex)

			//                 array_temp.splice(findIndex, 1)

			//                 setProductsSinInventario(array_temp)

			//             }
			//         }
			//     })
			// })
			// console.log(sum)


		}

	}

	const hanldeAddSubProduct = (index) => {
		// console.log(index)

		const row = [...itemsProducts]
		row[index].itemMateriales.push({ "uuid": uuidv4(), "itemProducto": "", "cantidadP": "", "medida": "", "descripcion": "", "cantidad": "", "precio": "" })
		setItemProducts(row)
	}

	const handleRemoveSubProduct = (i, index, v) => {

		const idItems = i

		const array_temp = [...itemsProducts]

		if (array_temp[i].itemMateriales[index].itemProducto === '') {

			array_temp[i].itemMateriales.splice(index, 1)
			// console.log('no hay productos')
			setItemProducts(array_temp)
		} else {

			const id = array_temp[idItems].itemMateriales[index].id
			array_temp[idItems].itemMateriales[index].cantidadP = ''
			const cnt = array_temp[idItems].itemMateriales[index].cantidadP

			console.log(id)
			console.log(cnt)

			setTimeout(() => {
				setDataForConsult({ id, cnt })
			}, 100);


			setTimeout(() => {
				// const itemsRowTemp = [...itemsProducts]
				// itemsRowTemp.splice(i, 1)
				// itemsRowTemp[i].itemMateriales.splice(index, 1)
				// setItemProducts(itemsRowTemp)

				const itemsRowTemp = [...itemsProducts]
				itemsRowTemp[i].itemMateriales.splice(index, 1)
				setItemProducts(itemsRowTemp)

				// const newA = [...autocomplete]
				// newA.splice(i, 1)
				// setAutocomplete(newA)

				// const itemsRowTemp2 = [...subProductsSinInventario]
				// itemsRowTemp2.splice(i, 1)
				// setSubProductsSinInventario(itemsRowTemp2)

				let sum = 0
				itemsRowTemp.forEach(ele => {
					ele.itemMateriales.forEach(elemento => {

						sum += (parseInt(elemento.cantidad) * parseInt(elemento.precio))

					})
				})
				// console.log(sum)
				setTotalPrice(sum)

				// const array_temp = [...productsSinInventario]
				// array_temp.splice(i, 1)
				// setProductsSinInventario(array_temp)

			}, 200);

		}





	}

	const respuest = () => {

		if (data !== null) {

			if (data.hasOwnProperty('status')) {
				console.log('hay status')
				deleteToken()

			} else {
				const itemsRows = itemsProducts.map((v, i) => (
					<Box key={v.uuid} sx={{ mb: 4, pb: 3, backgroundColor: 'white' }} className='boxItem'>
						<Grid item sx={{ p: 2 }}  >

							<Grid container spacing={1} >
								<Grid item xs={12} className='btnDelete' >
									<DeleteIcon
										onClick={() => handleDeleteItem(i, v)}
									/>
								</Grid>

								<Grid item xs={12} md={2.5}>


									<Autocomplete

										disablePortal

										id="combo-box-demo"
										// options={resultProduct}
										options={data.productos.filter(name => name.tipo !== 'Consumible')}
										value={autocomplete[i].itemProducto}
										onChange={(e, v) => handleChangeAutoComplete(e, v, i)}


										isOptionEqualToValue={(option, value) => {
											if (value.id) return option.id === value.id
											return true
										}}


										getOptionLabel={(option) => option.producto ? option.producto : autocomplete[i].name}

										renderOption={(props, option) => (
											<Box component="li" {...props} key={option.id}>
												{option.producto}
											</Box>
										)}
										renderInput={(params) =>
											<TextField
												key={i}
												{...params}

												label="Producto"
												variant="outlined"

											/>

										}

									/>

								</Grid>
								<Grid item xs={12} md={1.5}>
									{
										itemsProducts[i].type === 1

											? null
											: <CssTextField
												id="outlined-error"
												label="Cnt Producción"
												name='cantidadP'
												className='itemsInput'
												type="number"
												fullWidth
												value={itemsProducts[i].itemMateriales[0].cantidadP}
												onChange={e => handleChange(i, e)}
												disabled={itemsProducts[i].itemMateriales[0].itemProducto !== '' ? false : true}

											/>
									}

								</Grid>
								<Grid item xs={12} md={2}>
									{
										itemsProducts[i].type === 1

											? null
											: <CssTextField
												id="outlined-error"
												label="Medida"
												name='medida'
												className='itemsInput'
												fullWidth
												value={itemsProducts[i].itemMateriales[0].medida || ''}
												onChange={e => handleChange(i, e)}
												disabled={true}
											/>
									}
								</Grid>

								<Grid item xs={12} md={4}>
									{

										itemsProducts[i].type === 1
											? null
											:
											<CssTextField
												id="outlined-error"
												label="Descripción"
												name='descripcion'
												className='itemsInput'
												fullWidth
												value={itemsProducts[i].itemMateriales[0].descripcion}
												onChange={e => handleChange(i, e)}
												disabled={itemsProducts[i].itemMateriales[0].itemProducto !== '' ? false : true}
											/>
									}

								</Grid>
								<Grid item xs={12} md={1}>
									{
										itemsProducts[i].type === 1

											? null
											: <CssTextField
												id="outlined-error"
												label="Cnt"
												name='cantidad'
												className='itemsInput'
												type="number"
												inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
												fullWidth
												value={itemsProducts[i].itemMateriales[0].cantidad}
												onChange={e => handleChange(i, e)}
												disabled={itemsProducts[i].itemMateriales[0].itemProducto !== '' ? false : true}

											/>
									}

								</Grid>
								<Grid item xs={12} md={1} >
									{
										itemsProducts[i].type === 1
											? null
											:
											<>
												<CssTextField
													id="outlined-error"
													label="Precio"
													name='precio'
													className='itemsInput'
													type="number"
													fullWidth
													value={itemsProducts[i].itemMateriales[0].precio}
													onChange={e => handleChange(i, e)}
													disabled={itemsProducts[i].itemMateriales[0].itemProducto !== '' ? false : true}

												/>
											</>

									}

								</Grid>
							</Grid>

							{
								itemsProducts[i].type === 1
									?
									<>
										{
											itemsProducts[i].itemMateriales.map((value, index) => (

												// <p key={index}>hola</p>
												<Grid container className='textInputSale' spacing={1} key={index}>
													<Grid item xs={12} md={2.5} sx={{ mt: 2 }}>


														<Autocomplete
															disablePortal

															id="combo-box-demo"
															options={data ? data.productos.filter(name => name.tipo !== 'Consumible' && name.tipo !== 'Servicio') : ''}
															value={itemsProducts[i].itemMateriales[index].itemProducto}
															onChange={(e, v) => handleChangeAutoCompleteSubProduct(e, v, i, index)}


															isOptionEqualToValue={(option, value) => {
																if (value.id) return option.id === value.id
																return true
															}}

															getOptionLabel={(option) => option.producto ? option.producto : itemsProducts[i].itemMateriales[index].itemProducto.toString()}
															renderOption={(props, option) => (
																<Box component="li" {...props} key={option.id}>
																	{option.producto}
																</Box>
															)}
															renderInput={(params) =>
																<TextField
																	{...params}

																	label="Producto"
																	variant="outlined"

																/>}
														/>
													</Grid>
													<Grid item xs={12} md={2} sx={{ mt: 2 }}>
														<CssTextField
															id="outlined-error"
															label="Cnt Producción"
															name='cantidadP'
															className='itemsInput'
															type='number'
															fullWidth
															value={itemsProducts[i].itemMateriales[index].cantidadP}
															onChange={e => handleChangeSubProduct(e, i, index)}
															disabled={itemsProducts[i].itemMateriales[index].itemProducto !== '' ? false : true}
														/>
													</Grid>
													<Grid item xs={12} md={2} sx={{ mt: 2 }}>
														<CssTextField
															id="outlined-error"
															label="Medida"
															name='medida'
															className='itemsInput'
															fullWidth
															value={itemsProducts[i].itemMateriales[index].medida || ''}
															onChange={e => handleChangeSubProduct(e, i, index)}
															disabled={true}

														/>

													</Grid>
													<Grid item xs={12} md={3.5} sx={{ mt: 2 }}>

														<CssTextField
															id="outlined-error"
															label="Descripción"
															name='descripcion'
															className='itemsInput'
															fullWidth
															value={itemsProducts[i].itemMateriales[index].descripcion}
															onChange={e => handleChangeSubProduct(e, i, index)}
														/>


													</Grid>

													<Grid item xs={12} md={1} sx={{ mt: 2 }} >
														<CssTextField
															id="outlined-error"
															label="Cnt"
															name='cantidad'
															className='itemsInput'
															type='number'
															fullWidth
															value={itemsProducts[i].itemMateriales[index].cantidad}
															onChange={e => handleChangeSubProduct(e, i, index)}

														/>
													</Grid>

													<Grid item xs={12} md={1} sx={{ mt: 2 }} >
														<CssTextField
															id="outlined-error"
															label="Precio"
															name='precio'
															className='itemsInput'
															fullWidth
															value={itemsProducts[i].itemMateriales[index].precio}
															onChange={e => handleChangeSubProduct(e, i, index)}

														/>

													</Grid>
													<Grid item xs={12}  >
														<div className='deleRowSubProduct'>
															<span
																onClick={() => handleRemoveSubProduct(i, index, v)}
															>
																eliminar
															</span>
														</div>


													</Grid>
													<Divider />
												</Grid>

											))

										}

										< div className='bntAddSubProduct'>
											<div className='textAddSubProduct'>
												<span
													onClick={() => hanldeAddSubProduct(i)}
												>
													<AddCircleOutlineIcon /> Agregar producto
												</span>
											</div>

										</div>
									</>

									: null

							}

						</Grid>

					</Box >


				))

				return itemsRows
			}

		}


	}

	const handleSendData = () => {
		// setSaleLoading(true)

		const uriApi = process.env.REACT_APP_URI_API

		const dataForSendSale = {
			"prod": 1,
			"pdf": 1,
			"folioCreado": itemsProducts,
			cliente,
			areas,
			factura
		}

		console.log(dataForSendSale)

		if (cliente === '') {
			console.log('no hay cliente')
		} else {
			console.log(dataForSendSale.folioCreado)
		}

		const showFile = (blob) => {
			// It is necessary to create a new blob object with mime-type explicitly set
			// otherwise only Chrome works like it should
			var newBlob = new Blob([blob], { type: "application/pdf" })

			// IE doesn't allow using a blob object directly as link href
			// instead it is necessary to use msSaveOrOpenBlob
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}

			// For other browsers:
			// Create a link pointing to the ObjectURL containing the blob.
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement('a');
			link.href = data;
			link.download = "venta.pdf";
			link.click();
			setTimeout(function () {
				// For Firefox it is necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 100);
		}

		fetch(`${uriApi}ventas`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// 'Accept': 'application/json',
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify(dataForSendSale)
		}).then(r => r.blob())
			.then((blob) => {
				setSaleLoading(false)
				showFile(blob)
			}).catch(error => console.log(error));

	}


	const handleChangeArea = (v) => {
		setAreas(v)
	}



	return (


		token ?

			<>

				< TopBar logout={handleLogout} />
				<div className='container'>
					<aside>
						<SideBar logout={handleLogout} />
					</aside>
					<Grid
						container
						spacing={2}
						sx={{ p: 4 }}
					>
						<Grid
							item
							xs={12}
							sx={{ marginLeft: '0px' }}
						>
							{/* {JSON.stringify(itemsProducts.type)} */}
							{saleLoading ?
								<Loading />
								:
								<>

									<Typography className='titleColor' variant="h4" gutterBottom component="div">
										Venta Directa
									</Typography>
									<Typography className='textColor' variant="subtitle1" gutterBottom component="div">
										Aqui puedes realizar una venta directa
									</Typography>


									<Box sx={{ mt: 4, p: 2, backgroundColor: 'white' }} >
										<Typography className='textColorTotal' variant="h5" gutterBottom component="div">
											TOTAL: {isNaN(totalPrice) ? 0 : new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice)}
										</Typography>
									</Box>

									<Box sx={{ mt: 4, p: 2, backgroundColor: 'white' }} >
										<CssTextField
											id="outlined-error"
											label="Cliente"
											name='cliente'
											className='itemsInput'
											fullWidth
											value={cliente}
											onChange={e => setCliente(e.target.value)}
											autoComplete='off'
										/>
									</Box>

									{
										loading ? <Loading /> :


											<>
												<Box sx={{ mt: 4 }} >
													<div className='btnAdd'>
														<ColorButton
															startIcon={<AddIcon />}
															variant="outlined"
															onClick={handleAddItemProduct}
														>
															AGREGAR ITEM
														</ColorButton>
													</div>
												</Box>



												<MessageInventory
													data={data ? data.productos : null}
													itemsProducts={itemsProducts}
													dataForConsult={dataForConsult}

												/>


												<Box sx={{ mt: 4, }}>

													{respuest()}

												</Box>

												<Areas data={data ? data.areas : ''} change={handleChangeArea} />

												<Box sx={{ mt: 4, p: 2, backgroundColor: 'white', flexGrow: 1 }}>

													<FormGroup>
														<FormControlLabel control={<Checkbox checked={factura} onChange={() => setFactura(!factura)} />} label="Requiere factura" />

													</FormGroup>

												</Box>

												{
													itemsProducts[0]
														?
														<Button disabled={

															itemsProducts[0].type !== ''
																? cliente !== ''
																	? false
																	: true
																: true

														} sx={{ mt: 4 }} size="large" onClick={handleSendData} variant="contained" fullWidth={true} endIcon={<SendIcon />}>
															Generar venta
														</Button>
														: null
												}






											</>
									}
								</>


							}

						</Grid>
					</Grid>
				</div>

			</>

			: <Navigate to='/login' />

	)
}

export default Sale