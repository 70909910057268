
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//MUI
import Grid from '@mui/material/Grid';
import MuiAlert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, } from '@mui/x-date-pickers/DatePicker';
import EastIcon from '@mui/icons-material/East';
import { indigo } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import {
    DataGrid, esES, GridToolbarContainer, GridToolbarExport,

} from '@mui/x-data-grid';

import es from 'date-fns/locale/es'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';

//Component
import SideBar from '../../Components/SideBar';
import TopBar from '../../Components/TopBar'
import Loading from '../../Components/Loading';

//Hooks
import { useAuth } from '../../Hooks/useAuth'


//Helpers
import { helpHttp } from '../../Helpers/helpHttp';





const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: indigo[600],
    borderColor: indigo,
    // fontFamily: [
    //     '-apple-system',
    //     'BlinkMacSystemFont',
    //     '"Segoe UI"',
    //     'Roboto',
    //     '"Helvetica Neue"',
    //     'Arial',
    //     'sans-serif',
    //     '"Apple Color Emoji"',
    //     '"Segoe UI Emoji"',
    //     '"Segoe UI Symbol"',
    // ].join(','),
    '&:hover': {
        backgroundColor: indigo[900],
        borderColor: indigo,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});


const ReporteEntradasSalidas = () => {

    const { token, deleteToken, userRol } = useAuth()
    const [isToken, setIsToken] = useState(token)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [entradas, setEntradas] = useState()
    const [salidas, setSalidas] = useState()
    const [value, setValue] = useState('1');
    const [pageSize, setPageSize] = useState(10);


    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleLogout = () => {
        deleteToken()
        setIsToken('')
    }

    const handleCreateReport = () => {
        setIsLoading(true)
        console.log('crear reporte')
        const data = {
            "firstDate": moment(startDate).format('YYYY-MM-DD'),
            "endDate": moment(endDate).format('YYYY-MM-DD')
        }
        const uriApi = process.env.REACT_APP_URI_API
        const endPoint = `${uriApi}reporteinventario?firstDate=${data.firstDate}&endDate=${data.endDate}`

        console.log(data)

        helpHttp().get(endPoint, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {


            if (res.body.status === 'Token is Expired') {
                console.log(res)

                setIsToken(null)

            } else {
                console.log(res)
                setIsLoading(false)

                const dataEntradas = res.body.entradas
                const dataSalidas = res.body.salidas

                dataEntradas.map((v, i) => (dataEntradas[i].id = uuidv4()))
                dataSalidas.map((v, i) => (dataSalidas[i].id = uuidv4()))

                setEntradas(dataEntradas)
                setSalidas(dataSalidas)

            }
        })
    }

    const salir = () => {
        // setTimeout(, 5000)
        setTimeout(function () {
            navigate('/login')
        }, 5000);
    }

    const columnsEntrada = [
        {
            field: 'fecha', headerName: 'Fecha Ingreso', minWidth: 160, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',
            renderCell: (params) => {
                return moment(params.value).format('YYYY-MM-DD')
            },
        },
        {
            field: 'producto', headerName: 'Nombre Producto', flex: 2, minWidth: 250, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',
        },
        {
            field: 'cantidad', headerName: 'Cantidad', width: 100, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',

        },
        {
            field: 'medida', headerName: 'Unidad de medida', width: 180, type: "number", headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',
        },
        {
            field: 'costounitario', headerName: 'Precio Unitario', width: 180, type: "number", headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',
            renderCell: (params) => {
                return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(params.value)
            },
        },
        {
            field: 'totalpro', headerName: 'Total', width: 110, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header', type: "number",
            renderCell: (params) => {
                return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(params.value)
            },
        }
    ];

    const columnsSalidas = [
        {
            field: 'fecha', headerName: 'Fecha Salida', minWidth: 160, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',
            renderCell: (params) => {
                return moment(params.value).format('YYYY-MM-DD')
            },
        },
        {
            field: 'product', headerName: 'Nombre Producto', flex: 2, minWidth: 250, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',
        },
        {
            field: 'nombre', headerName: 'Servicio', flex: 2, minWidth: 160, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',
        },
        {
            field: 'cliente', headerName: 'Nombre del Proyecto', flex: 2, minWidth: 250, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',
        },
        {
            field: 'cantidad', headerName: 'Cantidad', width: 100, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',

        },
        {
            field: 'precio', headerName: 'Precio Unitario', width: 140, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header', type: "number",
            renderCell: (params) => {
                return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(params.value)
            },
        },
        {
            field: 'unidadmedida', headerName: 'Unidad de medida', width: 180, type: "number", headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header',
        },
        {
            field: 'total', headerName: 'Total', width: 110, headerAlign: 'center',
            align: 'left', headerClassName: 'title-app-theme-header', type: "number",
            renderCell: (params) => {
                return Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(params.value)
            },
        }
    ];

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (

        userRol() === '2'
            ? <Alert severity="error">No tienes permiso para esta secciòn</Alert>
            :
            isToken ?

                <>
                    <TopBar logout={handleLogout} />
                    <div className='container'>
                        <aside>
                            <SideBar logout={handleLogout} />
                        </aside>
                        <Grid
                            container
                            spacing={2}
                            sx={{ p: 4 }}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{ marginLeft: '0px' }}
                            >

                                <>
                                    <Typography className='titleColor' variant="h4" gutterBottom component="div">
                                        Reporte "Entradas - Salidas"
                                    </Typography>
                                    <Typography className='textColor' variant="subtitle1" gutterBottom component="div">
                                        En esta sección puedes realiar el reporte por fecha de las "Entradas/Salidas", selecciona la fecha inicial y la fecha final que deseas
                                    </Typography>

                                    <Paper className="wrappBoxSearch" elevation={1} sx={{ p: 4, mt: 4 }} >

                                        {isLoading ?
                                            <Loading />
                                            :
                                            <Box sx={{ maxWidth: '500px' }}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    className="wrapSearch"
                                                >

                                                    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label="Fecha Inicio"
                                                            value={startDate}
                                                            onChange={(newValue) => {
                                                                setStartDate(newValue);
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>

                                                    <EastIcon />

                                                    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label="Fecha Final"
                                                            value={endDate}
                                                            onChange={(newValue) => {
                                                                setEndDate(newValue);
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Box sx={{ mt: 2, mb: 2 }}>
                                                    <BootstrapButton disabled={startDate === null || endDate === null ? true : false} onClick={handleCreateReport} fullWidth={true} variant="contained" startIcon={<QueryStatsIcon />}>
                                                        CREAR REPORTE
                                                    </BootstrapButton>
                                                </Box>
                                            </Box>
                                        }

                                    </Paper>
                                    {

                                        entradas ?

                                            isLoading ?
                                                null
                                                : <Paper elevation={1} sx={{ p: 4, mt: 4 }}>
                                                    <TabContext value={value}>
                                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                                <Tab label="Entradas" value="1" />
                                                                <Tab label="Salidas" value="2" />
                                                            </TabList>
                                                        </Box>
                                                        <TabPanel value="1">
                                                            {entradas ?
                                                                <DataGrid
                                                                    getRowId={(row) => row.id}
                                                                    autoHeight={true}
                                                                    disableColumnMenu
                                                                    rows={entradas}
                                                                    columns={columnsEntrada}
                                                                    pageSize={pageSize}
                                                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                                                    // onRowClick={onClickRow}
                                                                    hideFooterSelectedRowCount
                                                                    disableSelectionOnClick
                                                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                                    // sortModel={sortModel}
                                                                    // onSortModelChange={(model) => setSortModel(model)}
                                                                    components={{
                                                                        Toolbar: CustomToolbar,
                                                                    }}
                                                                />
                                                                : null
                                                            }

                                                        </TabPanel>
                                                        <TabPanel value="2">
                                                            {salidas ?
                                                                <DataGrid
                                                                    getRowId={(row) => row.id}
                                                                    autoHeight={true}
                                                                    disableColumnMenu
                                                                    rows={salidas}
                                                                    columns={columnsSalidas}
                                                                    pageSize={pageSize}
                                                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                                                    // onRowClick={onClickRow}
                                                                    hideFooterSelectedRowCount
                                                                    disableSelectionOnClick
                                                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                                    // sortModel={sortModel}
                                                                    // onSortModelChange={(model) => setSortModel(model)}
                                                                    components={{
                                                                        Toolbar: CustomToolbar,
                                                                    }}
                                                                />
                                                                : null
                                                            }

                                                        </TabPanel>

                                                    </TabContext>
                                                </Paper>
                                            : null
                                    }
                                </>
                            </Grid>
                        </Grid>
                    </div>

                </>

                : <Alert severity="error">La sessión caducó, debes ingresar nuevamente {salir()}</Alert>

    )
}


export default ReporteEntradasSalidas