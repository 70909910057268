import { useState, useEffect } from "react"

export const useGetUser = (url, token, upload) => {

	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);


	useEffect(() => {

		// set a clean up flag
		let isSubscribed = true;
		if (isSubscribed) {
			const getDataUserList = async () => {
				setLoading(true)
				try {

					let res = await fetch(`${url}users`, {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json',
							'Authorization': 'Bearer ' + token
						}
					})

					if (!res.ok) {

						const e = {
							err: true,
							status: res.status,
							statusText: !res.statusText ? 'Ocurrio un error' : res.statusText
						}

						throw e

					}

					let data = await res.json()
					console.log( data );
					setData(data)
					setError({ err: false })
					setLoading(false)


				} catch (err) {
					setLoading(false)
					setError(err)
					console.log(err)
				}
			}

			getDataUserList()
		}
		// cancel subscription to useEffect
		return () => (isSubscribed = false)

	}, [url, token, upload]);


	return { data, error, loading };



}