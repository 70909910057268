import { useState, useEffect } from 'react'

//Material 
import { Box } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Areas = ({ data, change }) => {
	const [checked, setChecked] = useState([]);
	const [state, setState] = useState([]);
	const [countItems, setCountItems] = useState(0);

	useEffect(() => {
		// set a clean up flag
		let isSubscribed = true;

		if (isSubscribed) {
			const dataChecked = []
			if (data) {
				data.map((v) => {
					// console.log( 'vvvvvvvvvvvvvvvvvvvvvvv' );
					// console.log(v);
					// console.log( 'vvvvvvvvvvvvvvvvvvvvvvv' );
					if (v.des === '') {
						dataChecked.push({ id: v.id, name: `${v.id}`, checked: false })
					} else {
						dataChecked.push({ id: v.id, name: v.des.toLowerCase(), checked: false })
					}
					setChecked(dataChecked)

					return v
				} );
				const count = Math.round(dataChecked.length / 2)
				setCountItems(count)
			}
		}
		// cancel subscription to useEffect
		return () => (isSubscribed = false)
	}, [data]);


	const handleChange = (event) => {
		var found = checked.findIndex(e => e.name === event.target.name)
		if (found !== -1) {
			checked.map((v, i) => {
				if (i === found) {
					// console.log('cambiar el cheched en este index: ' + i)
					let temp_state = [...checked]
					let temp_element = { ...temp_state[found] }
					temp_element.checked = event.target.checked ? true : false
					temp_state[found] = temp_element
					setChecked(temp_state)


					if (event.target.checked) {
						let stateTemp = [...state]
						stateTemp.push(parseInt(v.id))
						setState(stateTemp)
						change(stateTemp)
						// state.push(v.id)
					} else {
						let stateTemp = [...state]
						stateTemp.splice(state.indexOf(v.id), 1);
						setState(stateTemp)
						change(stateTemp)
					}
				}
				return v
			})
		}
	};

	const capitalize = (str) => {
		// return str.charAt(0).toUpperCase() + str.slice(1);
		return str.toUpperCase();
	}


	return (
		<>
			<Box sx={{ mt: 4, p: 2, backgroundColor: 'white', flexGrow: 1 }} >
				<Typography className='textSubtitle' variant="h6" gutterBottom component="div">
					Selecciona las areas para producción
				</Typography>
				<FormGroup>
					{
						data !== null ?
							<Grid container >
								<Grid item md={6}>
									{
										checked.map((e, i) => {
											if (countItems >= i + 1) {
												return <div key={i}>
													<FormControlLabel key={i} control={ <Checkbox checked={e.checked} onChange={handleChange} name={e.name} /> } label={capitalize(e.name)}/>
												</div>
											} else {
												return null
											}
										})
									}
								</Grid>
								<Grid item md={6}>
									{
										checked.map((e, i) => {
											if (i >= countItems) {
												return (
													<div key={i}>
														<FormControlLabel key={i} control={ <Checkbox checked={e.checked} onChange={handleChange} name={e.name} /> } label={capitalize(e.name)} />
													</div>
												)
											} else {
												return null
											}
										})
									}
								</Grid>
							</Grid>
						: null
					}
				</FormGroup>
			</Box>
		</>
	)
}


export default Areas