

import { useState } from 'react'

import { Navigate } from 'react-router-dom';


//MUI
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';

import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';


//Hooks
import { useForm } from './../../../Hooks/useForm'


//Component
import Loading from '../../../Components/Loading'


const EditUser = ({ success, data, dataform }) => {
	// console.log(data)
	// console.log( dataform.areas );

	// dataform.areas.map( ( { key, value } ) => (
	// 	console.log( key + ' = ' + value );
	// ) );
	let idarea = 0;
	dataform.areas.forEach(
		function( d ){
			if( d.des == data.row.area )
				idarea = d.id;
		}
	)

	const initialForm = {
		'name': data.row.name,
		'email': data.row.email,
		'password': '',
		'idRole': data.row.role === 'Encargado' ? 2 : 1,
		'idArea': idarea,
	}

	const {
		form,
		loading,
		response,
		handleChange,
		handleBlur,
		handleSubmit } = useForm(initialForm)

	const [responseState, setResponseState] = useState(response);
	const [ btndisable, setBtndisable ] = useState( true );;

	const closeSuccess = () => {
		setTimeout(() => {
			setResponseState('')
			success()
		}, 3000);
	}

	const msg = () => {
		if (responseState) {
			if (response.status === 422) {
				return <Alert severity="error">
					{response.body.errors.email}
				</Alert>
			} else if (response.status === 201 || response.status === 200) {
				closeSuccess()
				return <Alert severity="success">
					El usuario se actualizó corectamente {form.email}
				</Alert>
			} else if (response.err) {
				return <Alert severity="error">
					Algo ocurrió, intentalo mas tarde --
					{response.status} {response.statusText} {response.status === 401 ? <Navigate to="/login" replace={true} /> : null}
				</Alert>
			}
		}

	}
	const fndisablebtn = ( flag ) => {
		setBtndisable( flag )
	}

	return (
		<>


			{
				loading
					? <Loading />
					: <>
						<DialogContent dividers>

							{
								msg()
							}

							<Box
								component="form"
								sx={{
									'& .MuiTextField-root': { m: 0 },
								}}
							>


								<Alert sx={{ mt: 2, mb: 2 }} severity="info">
									Estas por actualizar el siguiente usuario.

								</Alert>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>

										<FormControl fullWidth sx={{ mt: 2 }}>
											<TextField

												onChange={handleChange}
												value={form.name}
												name='name'
												type="text"
												label="Nombre de usuario"
											/>
										</FormControl>
										<FormControl fullWidth sx={{ mt: 2 }}>
											<TextField
												onChange={handleChange}
												value={form.email}
												name='email'
												type="email"
												label="Email"
											/>
										</FormControl>

										<FormControl fullWidth sx={{ mt: 2 }}>
											<TextField
												defaultValue={form.idArea ? true : false}
												id="outlined-select-currency-area"
												select
												label="Área"
												name='idArea'
												value={form.idArea}
												// onBlur={handleBlur}
												onChange={handleChange}
												// helperText={errors.idArea ? errors.idArea : 'Selecciona un Área'}
											>
												{
													dataform.areas.map( row => (
														<MenuItem value={row.id} >
															{row.des}
														</MenuItem>
													) )
												}
											</TextField>
										</FormControl>


									</Grid>
									<Grid item xs={12} md={6}>


										<FormControl fullWidth sx={{ mt: 2 }}>

											<TextField
												onChange={handleChange}
												value={form.password}
												name='password'
												type="password"
												label="Password"
											/>

										</FormControl>

										<FormControl fullWidth sx={{ mt: 2 }}>

											<TextField
												defaultValue={form.idRole}
												id="outlined-select-currency"
												select
												label="Rol"
												name='idRole'
												value={form.idRole}
												onChange={handleChange}
											// onBlur={handleBlur}
											// onChange={handleChange}
											// helperText={errors.idRole ? errors.idRole : 'Selecciona un Rol'}

											>

												<MenuItem value='1' >
													Administrador
												</MenuItem>
												<MenuItem value='2' >
													Encargado
												</MenuItem>

											</TextField>
										</FormControl>

									</Grid>
								</Grid>
							</Box>
						</DialogContent>
						<DialogActions>
							<Button onClick={function(e){handleSubmit(e, data.row.id, 'edit'); fndisablebtn( false )}} disabled={!btndisable}>
								Actualizar
							</Button>
						</DialogActions>
					</>
			}
		</>
	)
}

export default EditUser