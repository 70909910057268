import * as React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const SwitchCustom = ( { row, execFun, filtersopt } ) => {
	let valChecked = filtersopt.includes( row.id ) ? true : false;
	const [checked, setChecked] = React.useState( valChecked );
	const handleChange = ( event ) => {
		setChecked( event.target.checked );
		execFun( row );
	};

	return (
		<FormControlLabel control={ <Switch checked={checked} onChange={handleChange} name={row.des} /> } label={row.des} />
	)
}

export default SwitchCustom