
import * as React from 'react';
import { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom'


//MUI
import Grid from '@mui/material/Grid';
import MuiAlert from '@mui/material/Alert';


//Component
import SideBar from '../../Components/SideBar';
import TopBar from '../../Components/TopBar'

//Hooks
import { useAuth } from '../../Hooks/useAuth'

//Component
import DetailHistorico from '../../Components/DetailHistorico';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Historico = () => {

    const { id } = useParams()


    const { token, deleteToken, userRol } = useAuth()
    const [isToken, setIsToken] = useState(token)

    const handleLogout = () => {
        deleteToken()
        setIsToken('')
    }

    console.log(id)
    return (

        userRol() === '2'
            ? <Alert severity="error">No tienes permiso para esta secciòn</Alert>
            :
            isToken ?
                <>
                    <TopBar logout={handleLogout} />
                    <div className='container'>
                        <aside>
                            <SideBar logout={handleLogout} />
                        </aside>
                        <Grid
                            container
                            spacing={2}
                            sx={{ p: 4 }}
                        >

                            <DetailHistorico id={id} deleteToken={handleLogout} />

                        </Grid>

                    </div>


                </>

                : <Navigate to='/login' />

    )
}


export default Historico