import { useState, useEffect } from 'react';
//Material
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const ChangeStatus = ({ title, close, success, data }) => {
		// console.log(data)


		const [value, setValue] = useState(1);



		useEffect(() => {
				// set a clean up flag
				let isSubscribed = true;
				if (isSubscribed) {
						const arrayStatus = [
								{ name: 'Abierto', id: 1 },
								{ name: 'Pagado', id: 2 },
								{ name: 'Entregado', id: 3 },
								{ name: 'Cancelado', id: 4 }
						]
						const idStatus = arrayStatus.find(value => value.name === data.status)
						setValue(idStatus.id)
						// console.log(idStatus)
				}


				// cancel subscription to useEffect
				return () => (isSubscribed = false)

		}, [data.status]);



		return (
				<>
						<DialogTitle>
								{title}
								<IconButton
										aria-label="close"
										onClick={close}
										sx={{
												position: 'absolute',
												right: 8,
												top: 8,
												color: '#989ba1',
										}}
								>
										<CloseIcon />
								</IconButton>
						</DialogTitle>
						<DialogContent dividers>

								<Alert severity="warning">

										<Typography variant="body2" gutterBottom>
												Estas seguro de cambiar el status al folio: {data.id}
										</Typography>


								</Alert>

								<FormControl>
										<FormLabel id="demo-radio-buttons-group-label">Cambia el status: </FormLabel>
										<RadioGroup
												aria-labelledby="demo-radio-buttons-group-label"
												name="radio-buttons-group"
												value={value}

												onChange={(e) => setValue(e.target.value)}
										>
												<FormControlLabel value="1" control={<Radio />} label="Abierto" />
												<FormControlLabel value="2" control={<Radio />} label="Pagado" />
												<FormControlLabel value="3" control={<Radio />} label="Entregado" />
												<FormControlLabel value="4" control={<Radio />} label="Cancelado" />
										</RadioGroup>
								</FormControl>



						</DialogContent>
						<DialogActions>
								<Button
										onClick={close}
								>
										Cancelar
								</Button>
								<Button
										onClick={() => success(value, data.id)}
										variant="contained"
								>
										Cambiar status
								</Button>
						</DialogActions>
				</>
		)
}


export default ChangeStatus