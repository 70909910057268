

import { useState } from 'react'

import { Navigate } from 'react-router-dom';


//MUI
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';

import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';


//Hooks
import { useForm } from './../../../Hooks/useForm'


//Component
import Loading from '../../../Components/Loading'


const DeleteUser = ({ success, data }) => {
    console.log(data)

    const initialForm = {
        'name': '',
        'email': '',
        'password': '',
        'idRole': ''
    }


    const {
        form,
        loading,
        response,

        handleSubmit } = useForm(initialForm)

    const [responseState, setResponseState] = useState(response)


    const closeSuccess = () => {
        setTimeout(() => {
            setResponseState('')
            success()
        }, 3000);
    }

    const msg = () => {
        if (responseState) {
            if (response.status === 422) {
                return <Alert severity="error">
                    {response.body.errors.email}
                </Alert>
            } else if (response.status === 201 || response.status === 200) {
                closeSuccess()
                return <Alert severity="success">
                    El usuario se eliminó corectamente {form.email}
                </Alert>
            } else if (response.err) {
                return <Alert severity="error">
                    Algo ocurrió, intentalo mas tarde --
                    {response.status} {response.statusText} {response.status === 401 ? <Navigate to="/login" replace={true} /> : null}
                </Alert>
            }
        }

    }



    return (
        <>


            {
                loading
                    ? <Loading />
                    : <>
                        <DialogContent dividers>

                            {
                                msg()
                            }

                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 0 },
                                }}
                            >

                                <Alert severity="info">
                                    Estas por eliminar el siguiente usuario, una vez eliminado no se puede restablecer -- ¿Estas seguro de eliminar usuario?

                                </Alert>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>

                                        <FormControl fullWidth sx={{ mt: 2 }}>
                                            <TextField

                                                disabled={true}
                                                value={data.row.name}
                                                name='name'
                                                type="text"
                                                label="Nombre de usuario"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mt: 2 }}>
                                            <TextField
                                                disabled={true}
                                                value={data.row.email}
                                                name='email'
                                                type="email"
                                                label="Email"
                                            />
                                        </FormControl>


                                    </Grid>
                                    <Grid item xs={12} md={6}>

                                        <FormControl fullWidth sx={{ mt: 2 }}>

                                            <TextField
                                                disabled={true}
                                                value={data.row.role}
                                                name='role'
                                                type="text"
                                                label="role"
                                            />

                                        </FormControl>

                                    </Grid>

                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>

                            <Button

                                onClick={(e) => handleSubmit(e, data.row.id, 'delete')}

                            >
                                ELIMINAR USUARIO                            </Button>
                        </DialogActions>
                    </>


            }



        </>
    )
}

export default DeleteUser