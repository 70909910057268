import { useState } from 'react'
import { Navigate } from 'react-router-dom';
//mui
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
//Icon
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
//Hooks
import { useForm } from '../../Hooks/useForm';
import { useAuth } from '../../Hooks/useAuth';
//Component
import Loading from '../../Components/Loading'
import InfoIcon from '@mui/icons-material/Info';
import { red } from '@mui/material/colors';
import Chip from '@mui/material/Chip';

import {
	DataGrid, esES, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, GridToolbar
} from '@mui/x-data-grid';

const getColor = ( value ) => {
	if( value == '' || value == '0' ){
		const hue = ( ( value * 120 ) / 100).toString( 10 );
		return [ 'hsl( ', hue, ',100%,50% )' ].join( '' );
	}
};

const AddProductMassive = ({ title, close, data, idProduct, medidas, success }) => {
	const { token } = useAuth();

	const initialForm = {
		'idproducto': idProduct ? idProduct.id : '',
		'cantidad': '',
		'costounitario': idProduct ? idProduct.costounitario : '',
		'idProveedor': idProduct ? idProduct.idProveedor : '',
		'idMedida': idProduct ? idProduct.idMedida : ''
	}

	const validationsForm = (form) => {
		const errors = {};

		if (!form.cantidad.trim())
			errors.cantidad = 'El campo es requerido';

		return errors;
	}

	const [ file, setFile ] = useState( null );
	const [ status, setStatus ] = useState( '' );
	const [ pageSize, setPageSize ] = useState( 10 );
	const [ sortModel, setSortModel ] = useState( [
		{ field: 'name', sort: 'desc' }
	] );
	const [ dataUpdate, setDataUpdate ] = useState( [] );
	const [ btnSave, setBtnSave ] = useState( false );
	const [ showError, setshowError ] = useState( false );
	// const { userInfo } = useSelector( ( state ) => state.userLogin );

	const {
		form,
		errors,
		loading,
		response,
		handleChange,
		handleBlur,
		handleSubmit } = useForm(initialForm, validationsForm)
	const [responseState, setResponseState] = useState(response)
	const closeSuccess = () => {
		setTimeout(() => {
			setResponseState('')
			success()
		}, 3000);
	}
	const msg = () => {
		if (responseState) {
			if (response.status === 422) {
				return <Alert severity="error">
					{response.body.errors.email}
				</Alert>
			} else if (response.status === 201 || response.status === 200) {
				closeSuccess()
				return <Alert severity="success">
					Se ingreso correctamente el inventario {idProduct ? idProduct.name : null}
				</Alert>
			} else if (response.err) {
				return <Alert severity="error">
					Algo ocurrió, intentalo mas tarde --
					{response.status} {response.statusText} {response.status === 401 ? <Navigate to="/login" replace={true} /> : null}
				</Alert>
			}
		}
	}

	const handleSubmitFile = async ( event ) => {
		setStatus( '' ); // Reset status
		setBtnSave( false );
		event.preventDefault();
		// const formData = new FormData();
		// formData.append( 'data', dataUpdate );
		// formData.append( 'layoutpro' );
		// console.log( formData );
		fetch( `${process.env.REACT_APP_URI_API }template`, {
			method: 'POST',
			body: JSON.stringify( { 'data': dataUpdate } ),
			headers: {
				'content-type': 'application/json',
				'Accept': "application/json",
				'Authorization': 'Bearer ' + token,
			}
		} ).then( ( res ) => {
			setStatus( res.status === 201 ? 'Productos actualizados' : "Error." );
		} );

		// const resp = await axios.post(UPLOAD_ENDPOINT, formData, {
		// 	headers: {
		// 		"content-type": "multipart/form-data",
		// 		Authorization: `Bearer ${userInfo.token}`,
		// 	},
		// });
		// setStatus( resp.status === 200 ? "Thank you!" : "Error." );
	};

	const handleUpload = async ( event ) => {
		event.preventDefault();
		if( file ){
			const formData = new FormData();
			formData.append( 'layout', file );
			try {
				// You can write the URL of your server or any other endpoint used for file upload
				const result = await fetch( `${process.env.REACT_APP_URI_API }template`, {
					method: 'POST',
					body: formData,
					headers: {
						// 'content-type': 'multipart/form-data',
						'Authorization': 'Bearer ' + token
					}
				} ).then( ( res ) => {

				} );
				const data = await result.json();
			} catch( error ){
				console.error( error );
			}
		}
	};

	const readLayout = async ( event ) => {

		setFile( event.target.files[ 0 ] );
		setshowError( false );
		const file = event.target.files[ 0 ];
		const reader = new FileReader();
		let header = [
			'id',
			'producto',
			'sku',
			'costo_unitario',
			'cantidad',
		];
		reader.onload = res => {
			let csvdata = res.target.result;// Read file data
			let rowData = csvdata.split( '\n' );// Split by line break to gets rows Array
			let rowindex = 0;
			let datarow = rowData[ 0 ].split( ',' );
			let tmparr = [];
			let btn = true;
			for( let i = 1; i < rowData.length; i++ ){
				let datarow = rowData[ i ].split( ',' );
				if( datarow.length > 1 && ( datarow[ 3 ] != '' || datarow[ 4 ] != '' ) ){
					let obj = {};
					for( let j = 0; j <= datarow.length; j++ ){
						if( typeof datarow[ j ] != 'undefined' )
							obj[ header[ j ] ] = datarow[ j ];
					}
					if( datarow.length > 1 && ( datarow[ 3 ] == '' || datarow[ 4 ] == '' ) ){
						btn = false;
						setshowError( true );
					}
					console.log( btn );
					tmparr.push( obj );
				}
			}
			setBtnSave( btn );
			setDataUpdate( tmparr );
		};
		reader.onerror = err => console.log( err );
		reader.readAsText( file );
	};

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			width: 90,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
		},
		{
			field: 'producto',
			headerName: 'Producto',
			width: 280,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
		},
		{
			field: 'sku',
			headerName: 'SKU',
			width: 90,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
		},
		{
			field: 'costo_unitario',
			headerName: 'Costo unitario',
			width: 140,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
			renderCell: ( params ) => {
				const color = getColor( params.value );
				return (
					<Box
						sx={{
							backgroundColor: color,
							width: '100%',
							height: '100%',
						}}
					>
						{params.value}
					</Box>
				);
			}
		},
		{
			field: 'cantidad',
			headerName: 'Cantidad',
			width: 90,
			headerAlign: 'center',
			align: 'left',
			headerClassName: 'title-app-theme-header',
			renderCell: ( params ) => {
				const color = getColor( params.value );
				return (
					<Box
						sx={{
							backgroundColor: color,
							width: '100%',
							height: '100%',
						}}
					>
						{params.value}
					</Box>
				);
			}
		},
	];

	return (
		<>
			{
				loading
					? <Loading />
					: <>
						<DialogTitle>
							{title}
							<IconButton aria-label="close" onClick={close} sx={{
									position: 'absolute',
									right: 8,
									top: 8,
									color: '#989ba1',
								}}
							>
								<CloseIcon />
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<Button sx={{height:'36px', fontSize:'12px', marginTop:'10px'}} onClick={( e ) => handleSubmit( e, idProduct ? idProduct.id : '', 'DownLayer' )} variant="contained">
								Descargar Plantilla
							</Button>
							{ showError ?
								<h1 >Costo unitario y cantidad no pueden ir en blanco o con valor cero "0" favor de validar los campos con color rojo</h1>
								: ''
							}
							{ dataUpdate.length > 0 ?
								<DataGrid
									autoHeight={true}
									disableColumnMenu
									rows={dataUpdate}
									columns={columns}
									pageSize={pageSize}
									onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
									rowsPerPageOptions={[10, 20, 50, 100]}
									// onRowClick={onClickRow}
									hideFooterSelectedRowCount
									disableSelectionOnClick
									localeText={esES.components.MuiDataGrid.defaultProps.localeText}
									sortModel={sortModel}
									onSortModelChange={(model) => setSortModel(model)}
								/>
								: ''
							}

								<h1>Subir actualización productos</h1>
								<input type="file" onChange={readLayout} />


								<Button sx={{height:'36px', fontSize:'12px', marginTop:'10px'}} disabled={!( btnSave )} onClick={handleSubmitFile} variant="contained">
									Subir Archivo
								</Button>

								{status ? <h1>{status}</h1> : null}

						</DialogContent>
						<DialogActions>
						</DialogActions>
					</>
			}
		</>

	)
}

export default AddProductMassive