import {
	Routes,
	Route,
	HashRouter
} from "react-router-dom";


import Login from './Pages/Login/Login'
// import Dashboard from './Pages/Dashboard/Dashboard'
import Products from './Pages/Productos/Products'
import Users from './Pages/Usuarios/Users'
import PrivateRoute from "./Components/PrivateRoute"
import Sale from "./Pages/Sales/Sale";
import Historico from './Pages/Historico/Historico'
import ReporteEntradasSalidas from "./Pages/Historico/ReporteEntradasSalidas";
import ProyectSection from './Pages/ProyectSection/ProyectSection'
import CreateSalesProyect from './Pages/ProyectSection/CreateSalesProyect'

import WeeklySalesSection from './Pages/WeeklySales/WeeklySales'
import CreateWeeklySalesProyect from './Pages/WeeklySales/CreateWeeklySales'

import { useAuth } from './Hooks/useAuth'

function App() {
	const { userRol } = useAuth()

	return (
		<div >
			<HashRouter>
				<Routes>
					<Route
						path="/"
						element={
							<PrivateRoute redirectTo="/login">
								{userRol() !== '2' ? <ProyectSection /> : <Sale />}
							</PrivateRoute>
						}
					/>
					<Route path="/login" element={<Login />} />
					<Route
						path="/dashboard"
						element={
							<PrivateRoute redirectTo="/login">
								{/* {userRol() !== '2' ? <ProyectSection /> : <Sale />} */}
								<ProyectSection />
							</PrivateRoute>
						}
					/>
					<Route
						path="/productos"
						element={
							<PrivateRoute redirectTo="/login">
								<Products />
							</PrivateRoute>
						}
					/>

					<Route path='/productos/historico/:id'
						element={
							<Historico />
						} />
					<Route
						path="/productos/reporte-historicos"
						element={
							<PrivateRoute redirectTo="/login">
								<ReporteEntradasSalidas />
							</PrivateRoute>
						}
					/>
					<Route
						path="/venta-directa"
						element={
							<PrivateRoute redirectTo="/login">
								<Sale />
							</PrivateRoute>
						}
					/>
					<Route
						path="/venta-proyecto"
						element={
							<PrivateRoute redirectTo="/login">
								<ProyectSection />
							</PrivateRoute>
						}
					/>
					<Route
						path="/venta-proyecto/create"
						element={
							<PrivateRoute redirectTo="/login">
								<CreateSalesProyect />
							</PrivateRoute>
						}
					/>
					<Route
						path="/venta-semanal"
						element={
							<PrivateRoute redirectTo="/login">
								<WeeklySalesSection />
							</PrivateRoute>
						}
					/>
					<Route
						path="/venta-semanal/create"
						element={
							<PrivateRoute redirectTo="/login">
								<CreateWeeklySalesProyect />
							</PrivateRoute>
						}
					/>
					<Route
						path="/usuarios"
						element={
							<PrivateRoute redirectTo="/login">
								<Users />
							</PrivateRoute>
						}
					/>
				</Routes>
			</HashRouter>
		</div >
	);
}

export default App;