import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';



import SideBar from '../Components/SideBar'
import { useState } from 'react';





export default function TopBar({ logout }) {

    const [toggle, setToggle] = useState(false)


    return (
        <>

            <Box className='topBar'>
                <AppBar position="static" sx={{ background: '#303f9f' }} >
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={() => setToggle(!toggle)}

                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>

                    </Toolbar>
                </AppBar>
            </Box>

            <Drawer
                anchor={'left'}
                open={toggle}
                onClose={() => setToggle(false)}
            >
                <SideBar logout={logout} />
            </Drawer>

        </>
    );
}