



import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';


//Hooks
import { useMsgInventory } from '../Hooks/useMsgInventory'



const MessageInventory = ({ data, itemsProducts, dataForConsult }) => {

    // console.log(itemsProducts)
    // console.log(dataForConsult)
    let sumTotalP = 0
    const sumaT = () => {



        if (dataForConsult !== null) {

            itemsProducts.forEach(e => {
                e.itemMateriales.forEach(e => {
                    if (e.id === dataForConsult.id) {
                        sumTotalP += e.cantidad === '' ? 0 : parseInt(e.cantidad)
                    }
                })
            });
        }
    }

    sumaT()

    const { calculation } = useMsgInventory(data, dataForConsult, sumTotalP)
    // console.log(calculation)


    return (
        <>
            {/* {JSON.stringify(calculation)} */}
            <Stack className={`alertMsg ${calculation.productos.length > 0 ? " active" : "  out"}`} sx={{ width: '100%' }} spacing={2}>
                <Alert severity="warning">
                    <AlertTitle>Warning - No tienes suficientes piezas </AlertTitle>
                    {

                        calculation.productos.length > 0 ? calculation.productos.map((e, i) => (
                            <div key={i}>
                                Debes solicitar piezas de: <strong>{e.name}</strong> al proveedor <strong>{e.proveedor} </strong> -
                                piezas en almacen: <strong>{e.almacen} </strong> solicitar:<strong>{e.solicitar} </strong>
                            </div>
                        ))
                            : null
                    }
                </Alert>
            </Stack>
        </>

    )
}


export default MessageInventory