


import { useState, useMemo } from "react"

export const useMsgInventory = (data, dataForConsult, suma) => {


    const [productos, setProductos] = useState([])

    const calculation = useMemo(() => {
        // let productWithoutInventory = {}
        console.log(suma)
        console.log(dataForConsult)
        // console.log(productWithoutInventory)


        if (dataForConsult !== null) {


            if (data !== null) {
                // console.log('hay data')
                // console.log(data)

                if (dataForConsult.delete) {

                    if (dataForConsult.delete === true) {
                        console.log('hay piezas suficientes en almacen')
                        const array_temp = [...productos]

                        const findIndex = array_temp.findIndex(e => e.id === dataForConsult.id)
                        // console.log(findIndex)
                        if (findIndex !== -1) {
                            // console.log('modificar elemento')
                            array_temp.splice(findIndex, 1)
                            // setProductWithoutInventory(array_temp)
                            setProductos(array_temp)
                            // productWithoutInventory = array_temp
                        }
                    }

                } else {
                    const totalInvetory = data.filter(e => e.id === dataForConsult.id)

                    // console.log(totalInvetory)
                    const valueTotalInventory = totalInvetory.length !== 0 ? totalInvetory[0].cantidad : 0
                    // console.log(valueTotalInventory)

                    if (suma > valueTotalInventory) {
                        console.log('no hay piezas en almacen')
                        const array_temp = [...productos]

                        const findIndex = array_temp.findIndex(e => e.id === dataForConsult.id)
                        // console.log(findIndex)

                        if (findIndex !== -1) {
                            console.log('modificar elemento')
                            // console.log(v)
                            array_temp[findIndex].solicitar = (parseInt(suma) - parseInt(totalInvetory[0].cantidad))
                            //  setProductWithoutInventory(array_temp)
                            // setProductos(array_temp)
                            // productWithoutInventory = array_temp

                        } else {

                            console.log('add prodcut')
                            array_temp.push(

                                {
                                    'id': totalInvetory[0].id,
                                    'name': totalInvetory[0].producto,
                                    'proveedor': totalInvetory[0].proveedor,
                                    'almacen': parseInt(totalInvetory[0].cantidad),
                                    'solicitar': (parseInt(suma) - parseInt(totalInvetory[0].cantidad))
                                }
                            )

                            // setProductWithoutInventory(array_temp)
                            console.log(array_temp)
                            setProductos(array_temp)
                            // productWithoutInventory = array_temp

                        }

                    } else {
                        console.log('hay piezas suficientes en almacen')
                        const array_temp = [...productos]

                        const findIndex = array_temp.findIndex(e => e.id === dataForConsult.id)
                        // console.log(findIndex)
                        if (findIndex !== -1) {
                            // console.log('modificar elemento')
                            array_temp.splice(findIndex, 1)
                            // setProductWithoutInventory(array_temp)
                            setProductos(array_temp)
                            // productWithoutInventory = array_temp


                        }
                    }
                }


            }

        }

        return { productos, suma }

    }, [suma, data, dataForConsult, productos])



    return { calculation }

}