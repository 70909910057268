import { useState, useEffect } from 'react';
//Material
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { indigo } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const CssTextField = styled(TextField)({
		'& label.Mui-focused': {
				color: indigo[500],
		},
		'& .MuiInput-underline:after': {
				borderBottomColor: indigo[500],
		},
		'& .MuiOutlinedInput-root': {
				color: indigo[700],

				'&:hover fieldset': {
						borderColor: indigo[500],
				},
				'&.Mui-focused fieldset': {
						borderColor: indigo[500],
				},
		},
});

const ChangeWeek = ({ title, close, success, data }) => {
	// console.log(data)

	let weeks = [];
	for( let i = 1; i <= 52; i++ )
		weeks.push( i );

	const [ week, setWeek ] = useState( 1 );
	const [ value, setValue ] = useState( 1 );
	const [ pass, setPass ] = useState();
	const [ passError, setPassError ] = useState( false );

	useEffect( () => {
		if( typeof data != 'undefined' )
			setWeek( data.week );
	}, [ data ]);

	const passValidate = ( value, id, pass ) => {
		if( pass != '' && pass != 123456 )
			setPassError( true );
		else
			success( value, id, pass )
	}

	return (
		<>
			<DialogTitle>
				{title}
				<IconButton aria-label="close" onClick={close}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: '#989ba1',
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Alert severity="warning">
					<Typography variant="body2" gutterBottom>
							Estas seguro de cambiar la semana al folio: { typeof data != 'undefined' ? data.id : '' }
					</Typography>
				</Alert>

				<FormControl fullWidth sx={{ mt: 2 }}>
					<InputLabel >Semana</InputLabel>
					<Select value={week} label="Semana" onChange={e => setWeek( e.target.value )} >
						{
							weeks.map( v => (
								<MenuItem key={v} value={v}>{v}</MenuItem>

							) )
						}
					</Select>
				</FormControl>

				{ passError ?
					<Alert severity="warning">
						<Typography variant="body2" gutterBottom>
							Contraseña incorrecta
						</Typography>
					</Alert>
				: null }

				<FormControl fullWidth sx={{ mt: 2 }}>
					<CssTextField
						value={pass}
						type="number"
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						id="outlined-error"
						label="Contraseña"
						onChange={e => setPass( e.target.value )}
					/>
				</FormControl>
			</DialogContent>

			<DialogActions>
				<Button onClick={close} > Cancelar </Button>
				<Button onClick={ () => passValidate( week, data.id, pass ) } variant="contained" >
					Cambiar semana
				</Button>
			</DialogActions>
		</>
	)
}

export default ChangeWeek