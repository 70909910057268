import * as React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

//Material UI
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { indigo } from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

//Icons
// import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SendIcon from '@mui/icons-material/Send';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';

//Hooks
import { useAuth } from '../../Hooks/useAuth'
import { useGetProducts } from '../../Hooks/useGetProducts'
//  import { useMsgInventory } from '../../Hooks/useMsgInventory';

//Components
import SideBar from '../../Components/SideBar';
import Loading from '../../Components/Loading'
import TopBar from '../../Components/TopBar'
import Areas from '../../Components/Areas'
import MessageInventory from '../../Components/MessageInventory';
import Modal from '../../Components/Modal'

//Helper
import { helpHttp } from '../../Helpers/helpHttp'

//Styles
import './styles.css'
import { TryOutlined } from '@mui/icons-material';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

// const ColorButton = styled(Button)(() => ({
//     color: '#303f9f',
//     border: '1px solid #303f9f',

//     '&:hover': {
//         backgroundColor: '#303f9f',
//         color: '#fff',
//         border: '1px solid #303f9f'
//     },
// }));

const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: indigo[500],
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: indigo[500],
	},
	'& .MuiOutlinedInput-root': {
		color: indigo[700],
		'&:hover fieldset': {
			borderColor: indigo[500],
		},
		'&.Mui-focused fieldset': {
			borderColor: indigo[500],
		},
	},
} );

const CreateSalesProyect = () => {
	const { state } = useLocation()
	const navigate = useNavigate();

	const [productsSinInventario, setProductsSinInventario] = useState([])
	const [subProductsSinInventario, setSubProductsSinInventario] = useState([{ item: [{ item: '', subitem: '' }] }])

	const { token, deleteToken, userRol } = useAuth()
	const uriApi = process.env.REACT_APP_URI_API
	const { data, loading } = useGetProducts( uriApi, token, 1 )
		// useEffect(() => {
		//     // set a clean up flag
		//     let isSubscribed = true;

		//     if (isSubscribed) {
		//         if (data) {
		//             if (data.status) {
		//                 if (data.status === 'Token is Expired' || data.status === 'Token is Invalid') {
		//                     console.log('expiro')
		//                     deleteToken()
		//                 }
		//             }
		//         }
		//     }
		//     // cancel subscription to useEffect
		//     return () => (isSubscribed = false)

		// }, [data, deleteToken]);

	const [autocomplete, setAutocomplete] = useState([{ name: '' }])
	const [totalPrice, setTotalPrice] = useState(0.00)
	const [cliente, setCliente] = useState('')
	const [consumo, setConsumo] = useState(0.00)
	// const [balance, setBalance] = useState(0.00)
	const [totalProyecto, setTotalProyecto] = useState(0.00)
	const [anticipo, setAnticipo] = useState('0')
	const [areas, setAreas] = useState(null)
	const [factura, setFactura] = useState(false)
	const [saleLoading, setSaleLoading] = useState(false)
	const [typeEdit, setTypeEdit] = useState(false)
	const [dataProductoP, setDataProductoP] = useState([])
	const [dataAnticipoP, setdataAnticipoP] = useState([])
	const [dataAnticipoValueP, setdataAnticipoValueP] = useState(0)
	const [error, setError] = useState(false)
	const [dataE, setDataE] = useState(false)
	const [openModalRemove, setOpenModalRemove] = useState(false)
	const [itemRemove, setItemRemove] = useState()
	const [updloadData, setUploadData] = useState(0)
	const [ project, setProject ] = useState( 1 );

	const [totalInicial, setTotalInicial] = useState()
	//
	const vtasto = localStorage.getItem( 'vtapro' ) !== null ? JSON.parse( localStorage.getItem( 'vtapro' ) ) : {};
	// console.log( '*******************' );
	// console.log( vtasto );
	// console.log( '*******************' );
	const [dataForConsult, setDataForConsult] = useState(null)
	// const [deleteItemForCount, setDeleteItemForCount] = useState(null)
	const [itemsProducts, setItemProducts] = useState([
		{ uuid: uuidv4(), type: '', itemMateriales: [{ uuid: uuidv4(), itemProducto: '', cantidadP: '', medida: '', descripcion: '', cantidad: '', precio: '' }] }
	])

	useEffect(() => {
		// set a clean up flag
		let isSubscribed = true;
		if (isSubscribed) {
			if (state !== null && state.edit ) {
				// if (state.edit) {
					setTypeEdit(true)
					const { id } = state
					// console.log('hay edit')
					const endPoint = `${uriApi}ventas/${id}`
					helpHttp().get(endPoint, {
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							Authorization: 'Bearer ' + token
						}
					}).then((res) => {
						// console.log(res.body.status)
						if (res.body.status) {
							if (res.body.status === 'Token is Expired') {
								// console.log(res)
								setError(true)
								setDataE(res.body.status)
							}
						} else {
							const change = (x) => {
								var number = Number(x.replace(/[^0-9.-]+/g, ""));
								return number
							}
							// console.log( '*********************' );
							// console.log( res.body );
							// console.log( '*********************' );
							if (res.body[0]['total'] === undefined) {
								// console.log('no hay total')
								const listProduct = res.body[0]['allitem']
								setDataProductoP(listProduct)
							} else {
								setCliente(res.body[0]['cliente'])
								setTotalProyecto(change(res.body[0]['total']))
								setTotalInicial(change(res.body[0]['total']))
								setConsumo(change(res.body[0]['consumo']))
								setdataAnticipoValueP(change(res.body[0]['anticipo']))
								setTotalPrice(consumo)
								const listProduct = res.body[0]['allitem']
								setDataProductoP(listProduct)
							}
						}
					})
				}
			// }
		}
		// cancel subscription to useEffect
		return () => (isSubscribed = false)
	}, [state, token, uriApi, consumo, updloadData]);

	useEffect(() => {
		// set a clean up flag
		let isSubscribed = true;
		if( isSubscribed ){
			if (state !== null) {
				if (state.edit) {
					setTypeEdit(true)
					const { id } = state
					const endPoinA = `${uriApi}anticipos?idventa[]=${id}`
					helpHttp().get(endPoinA, {
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							Authorization: 'Bearer ' + token,
						}
					}).then((res) => {
						if (res.body.status) {
							if (res.body.status === 'Token is Expired') {
								setError(true)
								setDataE(res.body.status)
							}
						} else {
							setdataAnticipoP(res.body.anticipos)
						}
					})
				}
			}
		}
		if( 'cliente' in vtasto )
			setCliente( vtasto.cliente );
		if( 'totpro' in vtasto )
			setTotalProyecto( vtasto.totpro );
		if( 'ant' in vtasto )
			setAnticipo( vtasto.ant );
		if( 'item' in vtasto )
			setItemProducts( vtasto.item );
		if( 'bal' in vtasto )
			setTotalPrice( vtasto.bal );
		// cancel subscription to useEffect
		if( 'factura' in vtasto )
			setFactura( vtasto.factura );
		if( 'areas' in vtasto )
			setAreas( vtasto.areas );

		return () => (isSubscribed = false)
	}, [state, token, uriApi, consumo]);

	const handleLogout = () => {
		deleteToken()
	}

	const handleAddItemProduct = () => {
		const newA = [...autocomplete]
		newA.push({ name: '' })
		setAutocomplete(newA)

		const itemTemp = [...itemsProducts]
		itemTemp.push({ uuid: uuidv4(), type: '', itemMateriales: [{ uuid: uuidv4(), itemProducto: '', cantidadP: '', medida: '', descripcion: '', cantidad: '', precio: '' }] })
		setItemProducts(itemTemp)
	}

	const handleDeleteItem = (i, v) => {
		const idItems = i
		if (v.itemMateriales.length > 1) {
			v.itemMateriales.forEach((e, i) => {
				const array_temp = [...itemsProducts]
				const id = array_temp[idItems].itemMateriales[i].id
				array_temp[idItems].itemMateriales[i].cantidadP = ''
				const cnt = array_temp[idItems].itemMateriales[i].cantidad
				setTimeout(() => {
					setDataForConsult({ id, cnt })
				}, 100);
				// setItemProducts(array_temp)
				// setDataForConsult({ id, cnt })
			})
		} else {
			const array_temp = [...itemsProducts]
			const id = array_temp[i].itemMateriales[0].id
			const cnt = array_temp[i].itemMateriales[0].cantidad = 0
			setItemProducts(array_temp)
			setDataForConsult({ id, cnt })
		}

		setTimeout(() => {
			const itemsRowTemp = [...itemsProducts]
			itemsRowTemp.splice(i, 1)
			setItemProducts(itemsRowTemp)

			const newA = [...autocomplete]
			newA.splice(i, 1)
			setAutocomplete(newA)

			const itemsRowTemp2 = [...subProductsSinInventario]
			itemsRowTemp2.splice(i, 1)
			setSubProductsSinInventario(itemsRowTemp2)

			let sum = 0
			itemsRowTemp.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {
					sum += (parseInt(elemento.cantidad) * parseInt(elemento.precio))
				})
			})

			setTotalPrice(sum + consumo)
			const array_temp = [...productsSinInventario]
			array_temp.splice(i, 1)
			setProductsSinInventario(array_temp)
		}, 200);
	}

	const handleChange = (i, e) => {
		const { name, value } = e.target
		// console.log(name)
		let newFormValues = [...itemsProducts]
		// newFormValues[i].itemMateriales[0][name] = value
		setItemProducts(newFormValues)
		if (name === 'precio') {
			newFormValues[i].itemMateriales[0][name] = value
			let sum = 0
			newFormValues.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {
					sum += ((elemento.cantidad) * (elemento.precio))
				})
			})
			// console.log(sum)
			setTotalPrice(sum + consumo)
		} else if (name === 'cantidad') {
			newFormValues[i].itemMateriales[0][name] = value === '' ? 1 : value === '0' ? 1 : value
			let sum = 0
			newFormValues.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {
					sum += ((elemento.cantidad !== '' ? elemento.cantidad : 0) * (elemento.precio))
				})
			})
			// console.log(sum)
			setTotalPrice(sum + consumo)
		} else if (name === 'cantidadP') {
			newFormValues[i].itemMateriales[0][name] = value
			// console.log('consultar si hay cantidad suficiente en el index: ' + i + ' id: ' + newFormValues[i].itemMateriales[0].id)

			let indexToSearch = newFormValues[i].itemMateriales[0].id
			setDataForConsult({ id: indexToSearch, cnt: value })

			let arrayFind = data.productos.filter(e => e.id === indexToSearch)
			// const nameProductSearch = arrayFind[0].producto
			let sum = 0
			newFormValues.forEach(ele => {
				ele.itemMateriales.forEach((elemento, index) => {
					if (elemento.id === indexToSearch) {
						sum += elemento.cantidadP === '' ? 0 : parseInt(elemento.cantidadP)
						if (parseInt(sum) > parseInt(arrayFind[0].cantidad)) {
							const array_temp = [...productsSinInventario]
							const findIndex = array_temp.findIndex(e => e.id === elemento.id)
							if (findIndex !== -1) {
								array_temp[findIndex] = { id: arrayFind[0].id, 'name': arrayFind[0].producto, 'proveedor': arrayFind[0].proveedor, 'almacen': parseInt(arrayFind[0].cantidad), 'solicitar': (parseInt(sum) - parseInt(arrayFind[0].cantidad)) }
								setProductsSinInventario(array_temp)
							} else {
								array_temp.push({ id: arrayFind[0].id, 'name': arrayFind[0].producto, 'proveedor': arrayFind[0].proveedor, 'almacen': parseInt(arrayFind[0].cantidad), 'solicitar': (parseInt(sum) - parseInt(arrayFind[0].cantidad)) })
								setProductsSinInventario(array_temp)
							}
						} else {
							const array_temp = [...productsSinInventario]
							const findIndex = array_temp.findIndex(e => e.id === indexToSearch)
							if (findIndex !== -1) {
								console.log(findIndex)
								console.log(productsSinInventario.splice(findIndex, 1))
							}
						}
					}
				})
			})
		} else {
			newFormValues[i].itemMateriales[0][name] = value
		}
	}

	const handleChangeAutoComplete = (e, v, i) => {
		// console.log(e)
		// console.log(v)
		// console.log(i)
		// console.log(v !== null ? `${v.producto}  ${v.tipo} ` : '')
		if (v !== null) {
			if (v.tipo === 'Servicio') {
				const newA = [...itemsProducts]
				// newA[i].itemMateriales = [{ uuid: uuidv4(), itemProducto: '', cantidadP: '', medida: '', descripcion: '', cantidad: '', precio: '' }]
				newA[i].type = 1
				newA[i].name = v.producto

				setItemProducts(newA)

				const newA2 = [...autocomplete]
				newA2[i].name = v.producto
				setAutocomplete(newA2)
			} else {
				const newA1 = [...itemsProducts]
				newA1[i].itemMateriales = [{ id: parseInt(v.id), itemProducto: v.producto, cantidadP: '', medida: v.unidadmedida, descripcion: '', cantidad: '', precio: v.costounitario }]
				newA1[i].type = 2
				setItemProducts(newA1)
				const newA = [...autocomplete]
				newA[i].name = v.producto
				setAutocomplete(newA)
			}
		} else {
			const newA1 = [...itemsProducts]
			newA1[i].type = ''
			newA1[i].name = ''
			newA1[i].itemMateriales = [{ itemProducto: '', cantidadP: '', medida: '', descripcion: '', cantidad: '', precio: '' }]
			setItemProducts(newA1)

			const newA = [...autocomplete]
			newA[i].name = ''
			setAutocomplete(newA)
		}
	}

	const handleChangeAutoCompleteSubProduct = (e, v, i, index) => {
		// console.log(e)
		// console.log(v)
		// console.log("item:" + i + " subItem:" + index)
		if (v !== null) {
			const newA = [...itemsProducts]
			newA[i].itemMateriales[index].id = v.id
			newA[i].itemMateriales[index].itemProducto = v.producto
			newA[i].itemMateriales[index].medida = v.unidadmedida
			newA[i].itemMateriales[index].precio = v.costounitario
			setItemProducts(newA)
		} else {
			// console.log("limpiaste input")

			const newA = [...itemsProducts]
			const id = newA[i].itemMateriales[index].id

			setTimeout(() => {
				setDataForConsult({ id, delete: true })
			}, 100);

			newA[i].itemMateriales[index].itemProducto = ''
			newA[i].itemMateriales[index].cantidadP = ''
			newA[i].itemMateriales[index].medida = ''
			newA[i].itemMateriales[index].descripcion = ''
			newA[i].itemMateriales[index].cantidad = ''
			newA[i].itemMateriales[index].precio = ''
			setItemProducts(newA)

			let sum = 0
			newA.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {
					if (elemento.precio) {
						sum += (parseInt(elemento.cantidad) * parseInt(elemento.precio))
						// console.log(sum)
						// setTotalPrice(totalPrice + sum)
					}
					if (elemento.cant) {
						sum += (parseInt(elemento.cantidad) * parseInt(elemento.precio))
						// console.log(sum)
						// setTotalPrice(totalPrice + sum)
					}
				})
			})
			setTotalPrice(sum + consumo)
		}
	}

	const handleChangeSubProduct = (e, i, index) => {
		const { name, value } = e.target
		let newFormValues = [...itemsProducts]
		newFormValues[i].itemMateriales[index][name] = value
		setItemProducts(newFormValues)

		if (name === 'precio') {
			let sum = 0
			newFormValues.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {
					sum += ((elemento.cantidad) * (elemento.precio))
					// console.log(sum)
					// setTotalPrice(totalPrice + sum)
				})
			})
			setTotalPrice(sum + consumo)
		}
		if (name === 'cantidad') {
			let sum = 0
			newFormValues.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {
					sum += ((elemento.cantidad) * (elemento.precio))
				})
			})
			setTotalPrice(sum + consumo)
			newFormValues[i].itemMateriales[index][name] = value
			// / console.log('consultar si hay cantidad suficiente en el index: ' + index + ' id: ' + newFormValues[i].itemMateriales[index].id)
			let indexToSearch = newFormValues[i].itemMateriales[index].id
			// let arrayFind = data.productos.filter(e => e.id === indexToSearch)
			setDataForConsult({ id: indexToSearch, cnt: value })
		}
		if (name === 'cantidadP') {
			newFormValues[i].itemMateriales[index][name] = value
			// / console.log('consultar si hay cantidad suficiente en el index: ' + index + ' id: ' + newFormValues[i].itemMateriales[index].id)
			let indexToSearch = newFormValues[i].itemMateriales[index].id
			// let arrayFind = data.productos.filter(e => e.id === indexToSearch)

			setDataForConsult({ id: indexToSearch, cnt: value })
			// let sum = 0
			// newFormValues.forEach(ele => {
			//     ele.itemMateriales.forEach((elemento, index) => {
			//         if (elemento.id === indexToSearch) {
			//             sum += elemento.cantidadP === '' ? 0 : parseInt(elemento.cantidadP)
			//             console.log('se encontro id')

			//             if (parseInt(sum) > parseInt(arrayFind[0].cantidad)) {

			//                 console.log('es mayor la cantidad a inventario')

			//                 const array_temp = [...productsSinInventario]
			//                 const findIndex = array_temp.findIndex(e => e.id === elemento.id)
			//                 console.log(findIndex)

			//                 if (findIndex !== -1) {
			//                     console.log('modificar elemento')
			//                     array_temp[findIndex] = { id: arrayFind[0].id, 'name': arrayFind[0].producto, 'proveedor': arrayFind[0].proveedor, 'almacen': parseInt(arrayFind[0].cantidad), 'solicitar': (parseInt(sum) - parseInt(arrayFind[0].cantidad)) }

			//                     setProductsSinInventario(array_temp)

			//                 } else {
			//                     console.log('agregar elemento')
			//                     array_temp.push({ id: arrayFind[0].id, 'name': arrayFind[0].producto, 'proveedor': arrayFind[0].proveedor, 'almacen': parseInt(arrayFind[0].cantidad), 'solicitar': (parseInt(sum) - parseInt(arrayFind[0].cantidad)) })

			//                     setProductsSinInventario(array_temp)
			//                 }

			//             } else {
			//                 console.log('no es mayor a inventario')
			//                 const array_temp = [...productsSinInventario]
			//                 const findIndex = array_temp.findIndex(e => e.id === elemento.id)
			//                 console.log(findIndex)

			//                 array_temp.splice(findIndex, 1)

			//                 setProductsSinInventario(array_temp)

			//             }
			//         }
			//     })
			// })
		}
		let jsonstorage = {
			cliente,
			'con': totalPrice,
			'totpro': totalProyecto,
			'bal': totalPrice,
			'ant': anticipo,
			'item': itemsProducts,
			areas,
			factura,
		}
		localStorage.setItem( 'vtapro', JSON.stringify( jsonstorage ) );
	}

	const hanldeAddSubProduct = (index) => {
		const row = [...itemsProducts]
		row[index].itemMateriales.push( {
			'uuid': uuidv4(),
			'itemProducto': '',
			'cantidadP': '',
			'medida': '',
			'descripcion': '',
			'cantidad': '',
			'precio': ''
		} );
		setItemProducts(row)
	}

	const handleRemoveSubProduct = (i, index, v) => {
		const idItems = i
		const array_temp = [...itemsProducts]
		if (array_temp[i].itemMateriales[index].itemProducto === '') {
			array_temp[i].itemMateriales.splice(index, 1)
			setItemProducts(array_temp)
		} else {
			const id = array_temp[idItems].itemMateriales[index].id
			array_temp[idItems].itemMateriales[index].cantidad = ''
			const cnt = array_temp[idItems].itemMateriales[index].cantidad
			setTimeout(() => {
				setDataForConsult({ id, cnt, delete: true })
			}, 100);

			const itemsRowTemp = [...itemsProducts]

			// const itemsRowTemp = [...itemsProducts]
			itemsRowTemp[i].itemMateriales.splice(index, 1)
			let sum = 0
			itemsRowTemp.forEach(ele => {
				ele.itemMateriales.forEach(elemento => {
					sum += (parseInt(elemento.cantidad) * parseInt(elemento.precio))
				})
			})
			setTotalPrice(sum + consumo)
		}
	}

	const filterOptions = createFilterOptions({
		matchFrom: 'any',
		stringify: (option) => option.sku + option.producto,
	});

	const respuest = () => {
		if (data !== null) {
			if (data.hasOwnProperty('status')) {
				deleteToken()
			} else {
				const type = typeEdit ? 'Almacenable' : 'Servicio'
				const arrPro = data.productos.filter(name => name.tipo === type)
				const itemsRows = itemsProducts.map((v, i) => (
					<Box key={v.uuid} sx={{ mb: 4, pb: 3, backgroundColor: 'white' }} className='boxItem'>
						<Grid item sx={{ p: 2 }}  >
							{
								typeEdit ? null : <Grid container spacing={1} >
									{/* <Grid item xs={12} className='btnDelete' >
										<DeleteIcon
											onClick={() => handleDeleteItem(i, v)}
										/>
									</Grid> */}
									<Grid item xs={12} md={typeEdit ? 2.5 : 12}>
										<Autocomplete
											noOptionsText={'Por el momento no hay servicios'}
											disablePortal
											id="combo-box-demo"
											// options={resultProduct}
											options={arrPro}
											value={autocomplete[i].itemProducto}
											onChange={(e, v) => handleChangeAutoComplete(e, v, i)}
											isOptionEqualToValue={(option, value) => {
													if (value.id) return option.id === value.id
													return true
											}}
											getOptionLabel={(option) => option.producto ? option.producto : autocomplete[i].name}
											renderOption={(props, option) => (
												<Box component="li" {...props} key={option.id}>
													{option.producto}
												</Box>
											)}
											renderInput={(params) =>
												<TextField
													key={i}
													{...params}
													label={typeEdit ? 'Producto' : 'Servicio'}
													variant="outlined"
												/>
											}
										/>
									</Grid>
								</Grid>
							}
							{ itemsProducts[i].type === 1 || typeEdit ?
								<>{
									itemsProducts[i].itemMateriales.map( (value, index) => (
										// <p key={index}>hola</p>
										<Grid container className='textInputSale' spacing={1} key={index}>
											<Grid item xs={12} md={userRol() !== 2 ? 3 : 4} sx={{ mt: 2 }}>
												<Autocomplete
													disablePortal
													id="combo-box-demo"
													options={data ? data.productos.filter(name => name.tipo !== 'Servicio') : ''}
													value={itemsProducts[i].itemMateriales[index].itemProducto}
													onChange={(e, v) => handleChangeAutoCompleteSubProduct(e, v, i, index)}
													isOptionEqualToValue={(option, value) => {
														if (value.id) return option.id === value.id
															return true
														}}
													getOptionLabel={(option) => option.producto ? option.producto : itemsProducts[i].itemMateriales[index].itemProducto}
													filterOptions={filterOptions}
													renderOption={(props, option) => (
														<Box component="li" {...props} key={option.id}>
															{option.sku} - {option.producto}
														</Box>
													)}
													renderInput={(params) =>
														<TextField
															{...params}
															label="Producto"
															// variant="outlined"
														/>
													}
												/>
											</Grid>
													{/* <Grid item xs={12} md={2} sx={{ mt: 2 }}>
															<CssTextField
																	id="outlined-error"
																	label="Cnt Producción"
																	name='cantidadP'
																	className='itemsInput'
																	type='number'
																	fullWidth
																	value={itemsProducts[i].itemMateriales[index].cantidadP}
																	onChange={e => handleChangeSubProduct(e, i, index)}
																	disabled={itemsProducts[i].itemMateriales[index].itemProducto !== '' ? false : true}
															/>
													</Grid> */}
											<Grid item xs={12} md={1} sx={{ mt: 2 }} >
												<CssTextField
													id="outlined-error"
													label="Cantidad"
													name='cantidad'
													className='itemsInput'
													type='number'
													fullWidth
													value={itemsProducts[i].itemMateriales[index].cantidad}
													onChange={e => handleChangeSubProduct(e, i, index)}
													disabled={itemsProducts[i].itemMateriales[index].itemProducto !== '' ? false : true}
												/>
											</Grid>
											<Grid item xs={12} md={3.5} sx={{ mt: 2 }}>
												<CssTextField
													id="outlined-error"
													label="Descripción"
													name='descripcion'
													className='itemsInput'
													fullWidth
													value={itemsProducts[i].itemMateriales[index].descripcion}
													onChange={e => handleChangeSubProduct(e, i, index)}
													disabled={itemsProducts[i].itemMateriales[index].itemProducto !== '' ? false : true}
												/>
											</Grid>
											<Grid item xs={12} md={2} sx={{ mt: 2 }}>
												<CssTextField
													id="outlined-error"
													label="Medida"
													name='medida'
													className='itemsInput'
													fullWidth
													value={itemsProducts[i].itemMateriales[index].medida || ''}
													onChange={e => handleChangeSubProduct(e, i, index)}
													disabled={true}
												/>
											</Grid>
											{
												userRol() !== '2' ? <Grid item xs={12} md={1.5} sx={{ mt: 2 }} >
													<CssTextField
														id="outlined-error"
														label="Precio"
														name='precio'
														className='itemsInput'
														fullWidth
														value={itemsProducts[i].itemMateriales[index].precio}
														// value={itemsProducts[i].itemMateriales[0].precio}
														onChange={e => handleChangeSubProduct(e, i, index)}
														disabled={true}
													/>
												</Grid>
												: null
											}

											<Grid item xs={12} md={1} sx={{ mt: 2 }} >
												<FormControl fullWidth>
													<InputLabel >Proyecto</InputLabel>
													<Select value={project} label="Proyecto" onChange={e => setProject( e.target.value )} >
														{
															data.projects.map( v => (
																<MenuItem key={v.id} value={v.id}>{v.des}</MenuItem>
															) )
														}
													</Select>
												</FormControl>
											</Grid>

											<Grid item xs={12}  >
												<div className='deleRowSubProduct'>
													<span onClick={() => handleRemoveSubProduct(i, index, v)} >
														eliminar
													</span>
												</div>
											</Grid>
											<Divider />
										</Grid>
									))
								}
								<div className='bntAddSubProduct'>
									<div className='textAddSubProduct'>
										<span onClick={() => hanldeAddSubProduct(i)} >
											<AddCircleOutlineIcon /> Agregar producto
										</span>
									</div>
								</div>
																		</>

																		: null

														}

												</Grid>

										</Box >


								))

								return itemsRows
						}

				}


		}

	const handleSendData = () => {
		setSaleLoading(true)

		const uriApi = process.env.REACT_APP_URI_API

		const dataForSendSale = {
			"prod": 1,
			"pdf": 1,
			"folioCreado": itemsProducts,
			cliente,
			areas,
			factura,
			"tipoventa": 5,
			"costo": totalProyecto,
			"pagado": parseInt( anticipo ),
			'isweeklysale': 1,
			'idpro': project,
		}

		// console.log(dataForSendSale)

		// if (cliente === '') {
		// 	console.log('no hay cliente')
		// } else {
		// 	console.log(dataForSendSale.folioCreado)
		// }
		// const showFile = (blob) => {

		//     var newBlob = new Blob([blob], { type: "application/pdf" })
		//     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		//         window.navigator.msSaveOrOpenBlob(newBlob);
		//         return;
		//     }
		//     const data = window.URL.createObjectURL(newBlob);
		//     var link = document.createElement('a');
		//     link.target = '_blank'
		//     link.href = data;
		//     // link.download = "venta.pdf";
		//     link.click();

		//     setTimeout(function () {

		//         window.URL.revokeObjectURL(data);
		//     }, 100);
		// }
		console.log( dataForSendSale );
		console.log( `${uriApi}ventas` );
		fetch(`${uriApi}ventas`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				// 'Accept': 'application/json',
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify(dataForSendSale)
		}).then(r => r.blob()).then((blob) => {
			// console.log(blob.headers)
			localStorage.removeItem( 'vtapro' );
			setSaleLoading(false)
			//showFile(blob)
			navigate('/venta-semanal')
		}).catch(error => console.log(error));
	}

	const handleSendDataU = () => {
		setSaleLoading(true)
		// console.log(state.id)
		const uriApi = process.env.REACT_APP_URI_API
		// const endPoint = `${uriApi}ventas/${state.id}`

		const dataWithTotal = {
			"folioCreado": itemsProducts,
			"pagado": anticipo,
			"costo": totalProyecto,
			'idpro': project,
		}

		const dataWithOutTotal = {
			"folioCreado": itemsProducts,
			"pagado": anticipo,
			'idpro': project,
		}
		const dataForSendSale = totalInicial !== totalProyecto ? dataWithTotal : dataWithOutTotal
		// console.log(data)

		if (dataForSendSale.folioCreado.length > 0) {
			// console.log(dataForSendSale.folioCreado[0].itemMateriales)
			dataForSendSale.folioCreado[ 0 ].itemMateriales.map( ( v, i ) => {
				if (v.itemProducto === '') {
					// console.log('no hay nombre de producto')
					dataForSendSale.folioCreado.splice(i, 1)
				} else {
					// console.log('si hay nombre de producto')
					if (v.cantidad === "") {
						v.cantidad = 1
					}
				}
				return v
			} );
		}

		if (dataForSendSale.pagado === '' || dataForSendSale.pagado === '0') {
			delete dataForSendSale.pagado
		}

		if (dataForSendSale.folioCreado.length === 0) {
			delete dataForSendSale.folioCreado
		}

		// console.log(JSON.stringify(dataForSendSale))

		const showFile = (blob) => {
			// It is necessary to create a new blob object with mime-type explicitly set
			// otherwise only Chrome works like it should
			var newBlob = new Blob([blob], { type: "application/pdf" })

			// IE doesn't allow using a blob object directly as link href
			// instead it is necessary to use msSaveOrOpenBlob
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}

			// For other browsers:
			// Create a link pointing to the ObjectURL containing the blob.
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement('a');
			link.href = data;
			link.download = "venta.pdf";
			link.click();
			setTimeout( function () {
				// For Firefox it is necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL( data );
			}, 100 );
		}

		fetch(`${uriApi}ventas/${state.id}`, {
			method: 'PUT',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + token
			},
			body: JSON.stringify( dataForSendSale ),
		} ).then(response => {
			if( response.ok ){
				return response.blob().then( ( blob ) => {
					localStorage.removeItem( 'vtapro' );
					setSaleLoading( false );
					navigate( '/venta-semanal' );
					// showFile(blob)
				} );
			}/* else {
			}*/
		} ).catch( ( err ) => console.log( err ) ).finally( () => console.log( 'finally' ) );
	}

		const handleChangeArea = (v) => {
				setAreas(v)
		}

		const hanleDeleteSale = (value) => {
				// console.log(value)

				if (value) {
						setItemRemove(value)
						setOpenModalRemove(!openModalRemove)
				} else {
						setOpenModalRemove(!openModalRemove)
				}
		}

		const dataSuccessRemoved = (v) => {
				// console.log(state.id)
				// console.log(v)
				const dataForRemove = {
						"idpro": [v],
				}


				setOpenModalRemove(false)

				fetch(`${uriApi}ventas/${state.id}`, {
						method: 'PUT',
						headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json',
								'Authorization': 'Bearer ' + token
						},
						body: JSON.stringify(dataForRemove)
				})
						.then(response => response.json())
						.then((response) => {
								// console.log(response)
								setUploadData(updloadData + 1)

						})
						.catch((err) => console.log(err))
						.finally(() => console.log('finally'));
		}



		// const currencyMask = e => {
		//     console.log(e)

		//     let value = e
		//     value = value.replace(/\D/g, "")
		//     value = value.replace(/(\d)(\d{2})$/, "$1.$2")
		//     value = value.replace(/(?=(\d{3})+(\D))\B/g, ",")
		//     e = value

		//     return e
		// }


		// const sumaV = () => {
		//     if (anticipo === '') {
		//         return null
		//     } else {
		//         const v = parseFloat(consumo) - (parseFloat(anticipo) + parseFloat(dataAnticipoValueP))
		//         return v
		//     }

		// }


		const handleChangeTotal = (e) => {

		}



		return (

				token ?

						<>

								< TopBar logout={handleLogout} />
								<div className='container'>
										<aside>
												<SideBar logout={handleLogout} />
										</aside>
										<Grid
												container
												spacing={2}
												sx={{ p: 4 }}
										>
												<Grid
														item
														xs={12}
														sx={{ marginLeft: '0px' }}
												>
														{/* {JSON.stringify(itemsProducts.type)} */}
														{saleLoading ?
																<Loading />
																:
																error
																		? <Stack sx={{ width: '100%' }} spacing={2}>
																				<Alert severity="error">
																						<AlertTitle>Error</AlertTitle>
																						<strong>{JSON.stringify(dataE)}</strong>
																						{dataE === 'Token is Expired' ? setTimeout(() => {
																								deleteToken()
																						}, 3000) : null}
																						{dataE === 401 ? setTimeout(() => {
																								deleteToken()
																						}, 3000) : null}
																				</Alert>

																		</Stack>
																		:
																		<>
																				<Modal open={openModalRemove} close={hanleDeleteSale} type='remove' data={itemRemove} success={dataSuccessRemoved} />
																				<Typography className='titleColor' variant="h4" gutterBottom component="div">
																						Venta Semana
																				</Typography>
																				<Typography className='textColor' variant="subtitle1" gutterBottom component="div">
																						{typeEdit ? 'Detalle del proyecto' : 'Aqui puedes realizar una venta para una semana'}
																				</Typography>

																				{userRol() !== '2'
																						? <Box sx={{ mt: 4, p: 2, backgroundColor: 'white' }} >
																								<Typography className='textColorTotal' variant="h5" gutterBottom component="div">
																										TOTAL: {isNaN(totalPrice) ? 0 : new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice)}
																								</Typography>
																								{/* {totalInicial} - {totalProyecto} */}



																						</Box>
																						: null
																				}


																				{
																						userRol() !== '2'
																								?
																								<>
																										<Box sx={{ mt: 4, p: 2, backgroundColor: 'white' }} >
																												<Grid container spacing={1} >
																														<Grid item xs={12} md={4} sx={{ mt: 2 }}>
																																<CssTextField
																																		id="outlined-error"
																																		label="Semana"
																																		name='cliente'
																																		className='itemsInput'
																																		fullWidth
																																		value={cliente}
																																		onChange={e => setCliente(e.target.value)}
																																		autoComplete='off'
																																		disabled={typeEdit ? true : false}
																																/>
																														</Grid>

																														<Grid item xs={12} md={2} sx={{ mt: 2 }}>
																																<CssTextField
																																		id="outlined-error"
																																		label="Consumo"
																																		name='consumo'
																																		className='itemsInput'
																																		fullWidth
																																		// value={totalPrice.toFixed(2)}
																																		value={isNaN(totalPrice) ? 0 : new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice)}
																																		onChange={e => setConsumo(e.target.value)}
																																		autoComplete='off'
																																		disabled={true}
																																/>

																														</Grid>
																														<Grid item xs={12} md={2} sx={{ mt: 2 }}>
																																<CssTextField
																																		id="outlined-error"
																																		label="Total Proyecto"
																																		name='total'
																																		className='itemsInput'
																																		fullWidth
																																		type={typeEdit ? "text" : "number"}
																																		//value={typeEdit ? new Intl.NumberFormat('es-MX').format(totalProyecto) : totalProyecto}
																																		value={totalProyecto}
																																		// onChange={e => setTotalProyecto(currencyMask(e.target.value))}
																																		onChange={e => setTotalProyecto(e.target.value)}
																																		autoComplete='off'
																																		InputProps={{
																																				startAdornment: <InputAdornment position="start">$</InputAdornment>,
																																		}}
																																		onBlur={(e) => (e.target.value === '' ? setTotalProyecto(totalInicial) : null)}
																																//disabled={typeEdit ? true : false}
																																/>


																														</Grid>
																														<Grid item xs={12} md={2} sx={{ mt: 2 }}>

																																<CssTextField
																																		id="outlined-error"
																																		label="Balance"
																																		name='balance'
																																		className='itemsInput'
																																		fullWidth
																																		// value={totalPrice - anticipo}
																																		value={isNaN(totalPrice) ? 0 : new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(typeEdit ? parseFloat(anticipo) - parseFloat(totalPrice) + parseFloat(dataAnticipoValueP) : anticipo - totalPrice)}
																																		// value={getBalance(anticipo)}
																																		// onChange={e => setBalance(e.target.value)}
																																		autoComplete='off'

																																		disabled={true}
																																/>

																														</Grid>
																														<Grid item xs={12} md={2} sx={{ mt: 2 }}>
																																<CssTextField
																																		id="outlined-error"
																																		label="Anticipo"
																																		name='anticipo'
																																		className='itemsInput'
																																		type="number"
																																		fullWidth
																																		value={anticipo}
																																		//onChange={e => updateValue(e)}
																																		onChange={e => setAnticipo(e.target.value)}
																																		autoComplete='off'
																																		disabled={totalProyecto !== 0 ? false : true}
																																		InputProps={{
																																				startAdornment: <InputAdornment position="start">$</InputAdornment>,
																																		}}
																																/>
																														</Grid>
																												</Grid>
																										</Box>
																								</>
																								: null
																				}





																				{
																						loading ? <Loading /> :
																								<>
																										<Grid container spacing={1} >
																												{
																														dataProductoP.length === 0
																																? null
																																:

																																<Grid item xs={12} md={userRol() === '2' ? 12 : 6} sx={{ mt: 2 }}>
																																		<Box sx={{ mt: 4, p: 2, backgroundColor: 'white' }} >

																																				<Typography className='textSubtitle' variant="h6" gutterBottom component="div">

																																						Listado de productos del proyecto

																																				</Typography>


																																				<TableContainer component={Paper}>
																																						<Table aria-label="simple table">
																																								<TableHead x={{ backgroundColor: 'red' }}>
																																										<TableRow>
																																												<TableCell align="center">SKU</TableCell>
																																												<TableCell align="center">Nombre</TableCell>
																																												{userRol() === '2' ? null : <TableCell align="center">Precio</TableCell>}
																																												<TableCell align="center">Cantidad</TableCell>
																																												{userRol() === '2' ? null : <TableCell align="center">Acciones</TableCell>}
																																										</TableRow>
																																								</TableHead>
																																								<TableBody>
																																										{

																																												dataProductoP.map((row, i) => (

																																														<TableRow
																																																key={i}
																																																sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
																																														>

																																																<TableCell align="center">{row.product.length > 0 ? row.product[0].sku : ''}</TableCell>
																																																<TableCell align="center">{row.product.length > 0 ? row.product[0].producto : row.nombre}</TableCell>
																																																{userRol() === '2' ? null : <TableCell align="center"> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(row.precio)}</TableCell>}
																																																<TableCell align="center"> {row.cantidad}</TableCell>
																																																{userRol() === '2' ? null : <TableCell align="center">
																																																		<DeleteIcon
																																																				className='iconActions iconDelete'
																																																				onClick={() => hanleDeleteSale({ name: row.product.length > 0 ? row.product[0].producto : '', id: row.iditem })}
																																																		/>
																																																</TableCell>}
																																														</TableRow>


																																												))
																																										}

																																								</TableBody>
																																						</Table>
																																				</TableContainer>
																																		</Box>
																																</Grid>

																												}

																												{

																														typeEdit
																																?
																																userRol() === '2'
																																		? null
																																		:
																																		<Grid item xs={12} md={6} sx={{ mt: 2 }}>
																																				<Box sx={{ mt: 4, p: 2, backgroundColor: 'white' }} >
																																						<Typography className='textSubtitle' variant="h6" gutterBottom component="div">
																																								Listado de anticipos del proyecto
																																						</Typography>
																																						<TableContainer component={Paper}>
																																								<Table aria-label="simple table">
																																										<TableHead x={{ backgroundColor: 'red' }}>
																																												<TableRow>
																																														<TableCell align="center">Fecha</TableCell>
																																														<TableCell align="center">Hora</TableCell>
																																														<TableCell align="center">Monto</TableCell>


																																												</TableRow>
																																										</TableHead>
																																										<TableBody>

																																												{
																																														dataAnticipoP.map((v, i) => (
																																																<TableRow
																																																		key={i}
																																																		sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
																																																>

																																																		<TableCell align="center">{v.fecha_anticipo}</TableCell>
																																																		<TableCell align="center">{v.hora_anticipo}</TableCell>
																																																		<TableCell align="center">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(v.anticipo)}</TableCell>

																																																</TableRow>
																																														))
																																												}





																																										</TableBody>
																																								</Table>
																																						</TableContainer>
																																				</Box>
																																		</Grid>

																																: null
																												}

																										</Grid>


																										{/* <Box sx={{ mt: 4 }} >
																												<div className='btnAdd'>
																														<ColorButton
																																startIcon={<AddIcon />}
																																variant="outlined"
																																onClick={handleAddItemProduct}
																														>
																																AGREGAR ITEM
																														</ColorButton>
																												</div>
																										</Box> */}



																										<MessageInventory
																												data={data ? data.productos : null}
																												itemsProducts={itemsProducts}
																												dataForConsult={dataForConsult}

																										/>


																										<Box sx={{ mt: 4, }}>

																												{respuest()}

																										</Box>
																										{typeEdit
																												? null
																												:
																												<>
																														<Areas data={data ? data.areas : ''} change={handleChangeArea} />

																														<Box sx={{ mt: 4, p: 2, backgroundColor: 'white', flexGrow: 1 }}>

																																<FormGroup>
																																		<FormControlLabel control={<Checkbox checked={factura} onChange={() => setFactura(!factura)} />} label="Requiere factura" />

																																</FormGroup>

																														</Box>
																												</>

																										}


																										{
																												itemsProducts.length > 0
																														?
																														typeEdit ?
																																<Button
																																		disabled={

																																				// itemsProducts[0].type !== ''
																																				//     ? anticipo !== ''
																																				//         ? false
																																				//         : true
																																				//     : true

																																				// anticipo !== "0"
																																				//     ? false
																																				//     :

																																				anticipo !== ''
																																						? false
																																						: TryOutlined

																																		}

																																		sx={{ mt: 4 }} size="large" onClick={handleSendDataU} variant="contained" fullWidth={true} endIcon={<ChangeCircleIcon />}>
																																		UPDATE venta

																																</Button>
																																: <Button disabled={

																																		itemsProducts[0].type !== ''
																																				? cliente !== ''
																																						? false
																																						: true
																																				: true

																																} sx={{ mt: 4 }} size="large" onClick={handleSendData} variant="contained" fullWidth={true} endIcon={<SendIcon />}>
																																		Generar venta
																																</Button>
																														: null
																										}
																								</>
																				}
																		</>
														}

												</Grid>
										</Grid>
								</div>

						</>

						: <Navigate to='/login' />

		)
}

export default CreateSalesProyect